import { DatabaseDAO } from "@/services/base/DatabaseDAO";

/**
 * @description Data Access Object for Podcasts
 * @class
 * @public
 */

class PodcastDao extends DatabaseDAO {}

export default new PodcastDao();
