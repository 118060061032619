<template>
  <div class="mb-3">
    <label class="form-label">{{ label }}</label>
    <div>
      <div class="input-group">
        <input class="form-control" v-model="item" :type="type" :placeholder="placeholder">
        <span class="input-group-text text-success" @click="addItem" @keydown.enter="addItem"><i class="bi bi-plus-square-fill"></i></span>
      </div>
    </div>
    <div  class="list-group">
      <draggable v-model="itemsList"  draggable=".list-group-item">
        <div v-for="element in itemsList" :key="element" class="list-group-item list-group-item-info d-flex justify-content-between mt-1 rounded">{{ element }}
          <div class="text-danger" @click="removeItem(element)"><i class="bi bi-dash-circle-fill"></i></div>
        </div>
      </draggable>
    </div>
  </div>

</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: "BaseInputList",
  components: {
    draggable,
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String,
      default: "placeholder"
    },
    itemsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      item: "",
      listedItems:[]
    }
  },
  watch: {
    itemsList: {
      handler(newValue) {
        this.$emit("handleInput", newValue);
        this.$emit('input', newValue);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    addItem() {
      this.itemsList.push(this.item);
      this.item = ""
    },
    removeItem(index) {
      this.itemsList.splice(index, 1);
      this.updateValue()
    },

  }

}
</script>

<style scoped>

</style>
