<template>
  <div>
    <div>{{ label }}</div>
    <quill-editor :content="content"
                  :options="editorOption"
                  @change="onEditorChange($event)">
    </quill-editor>
  </div>
</template>

<script>

export default {
  name: "HTMLTextEditor",
  props: {
    id: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: "Description"
    },
  },
  data() {
    return {
      content: "",
      editorOption: { /* quill options */}
    }
  },
  methods: {
    onEditorChange({quill, html, text}) {
      this.$emit("input", html)
      this.$emit("unused", text, quill)
    },
  }
}
</script>

<style scoped>
#text-editor {
  height: 100px;
  border: 1px solid #ccc;
}

</style>