<template>
  <layout-admin-add-edit>
    <template #header>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">Recipes</li>
          <li class="breadcrumb-item active" aria-current="page">Edit Recipe</li>
        </ol>
      </nav>
    </template>
    <template #body>
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <form class="text-start col-lg-6 col-md-8 col-sm-12 m-auto border rounded p-4" onsubmit="return false">
            <edit-recipe-form :props-recipe="recipe" v-model="editedRecipe" mode="edit"
                              v-on="$listeners"></edit-recipe-form>
            <div class="mb-3">
              <label class="form-label">Images</label>
              <multi-image-upload
                  data-set="images"
                  :uploaded-images="recipe.images"
                  :storage-path="storagePath"
                  :key="reloadKey"
                  @handleUpload="handleUpload"
              />
            </div>
            <div class="d-flex justify-content-between mt-2">
              <button @click="handleUpdate" class="mb-3 btn btn-success">Update</button>
              <button @click="handleDelete" class="mb-3 btn btn-danger">Delete</button>
            </div>
          </form>
        </div>
      </div>
    </template>
  </layout-admin-add-edit>
</template>
<script>
import RecipeService from "@/services/recipe/RecipeService";
import {categories, storagePath} from "@/services/recipe/RecipeConfig";
import MultiImageUpload from "@/components/MultiImageUpload";
import EditRecipeForm from "@/components/recipes/EditRecipeForm";
import LayoutAdminAddEdit from "@/views/layouts/LayoutAdminAddEdit";

export default {
  name: "EditRecipe",
  components: {
    LayoutAdminAddEdit,
    EditRecipeForm,
    MultiImageUpload
  },

  data() {
    return {
      editedRecipe: {},
      recipe: {},
      multiSelectOptions: {
        category: {
          value: [],
          options: this.getRecipeCategoryOptions()
        }
      },
      storagePath: storagePath,
      reloadKey: 0
    }
  },
  async mounted() {
    this.editedRecipe = await this.getRecipe()
  },
  methods: {
    async getRecipe() {
      let recipeId = this.$route.params.id;
      let recipe = await RecipeService.getRecipe(recipeId);
      recipe.images = this.getRecipeImages(recipe);
      this.recipe = recipe;
      return recipe;
    },
    getRecipeCategory(recipe) {
      return (typeof (recipe.category) === "string" ? recipe.category.split(",") : recipe.category);
    },
    getRecipeImages(recipe) {
      return (recipe.images[0].imagePath === null ? [] : recipe.images);
    },
    getRecipeCategoryOptions() {
      return [...categories.main, ...categories.general, ...categories.timeBased];
    },
    handleUpdate(event) {
      event.preventDefault();
      const hasEmptyField = (this.recipe.title === "" || this.recipe.category.length === 0
          || this.recipe.description === "" || this.recipe.ingredients === "" || this.recipe.steps === "");
      if (hasEmptyField) {
        this.$toast.error("Add title, category, description, ingredients and steps for recipe");
      }
      if (!hasEmptyField) {
        this.updateRecipe(this.recipe.id, this.recipe);
        this.$router.push({name: "MyRecipes"});
        this.$toast.success("Recipe updated successfully!");
      }
    },
    updateRecipe(recipeId, recipe) {
      RecipeService.updateRecipe(recipeId, recipe);
    },
    handleDelete(event) {
      event.preventDefault();
      if (window.confirm("Are you sure you wan to delete this recipe?")) {
        this.deleteRecipe(this.recipe.id);
        this.$toast.error("Recipe has been deleted!");
      }
    },
    deleteRecipe(recipeId) {
      RecipeService.deleteRecipe(recipeId);
    },
    handleInput(input) {
      this.recipe[input.key] = input.values;
    },
    handleUpload(upload) {
      this.recipe[upload.key] = upload.values;
      this.updateRecipe(this.recipe.id, this.recipe);
      this.reloadKey++;
    }
  }
}
</script>
<style scoped>
label {
  font-weight: bold;
}
</style>
