<template>
  <div class="container">
    <div class="row mx-5 d-flex justify-content-center text-start align-items-center">
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-8 col-xs-12">
        <div class="form mb-5">
          <div class="d-flex justify-content-center mt-5 mb-5">
            <img src="../assets/logo-extended.png" alt="Extended image" height="100vh">
          </div>
          <div>
            <label class="form-label">Email address</label>
            <input type="email" class="form-control" placeholder="Email address"
                   aria-describedby="emailHelp" v-model="email" @blur="v$.email.$touch" required>
            <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>

            <div class="input-errors" v-for="error in v$.email.$errors" :key="error.$uid">
              <div class="fst-italic form-text text-red">{{ error.$message }}</div>
            </div>
          </div>
          <div v-if="pageMode==='signin' || pageMode==='signup' ">
            <label class="form-label">Password</label>
            <input type="password" class="form-control" placeholder="Password"
                   aria-describedby="emailHelp" v-model="password" @blur="v$.password.$touch" required>

            <div class="input-errors" v-for="error in v$.password.$errors" :key="error.$uid">
              <div class="fst-italic form-text text-red">{{ error.$message }}</div>
            </div>

          </div>
          <div v-if="pageMode==='signup'">
            <label class="form-label">Confirm Password</label>
            <input type="password" class="form-control" placeholder="Confirm Password"
                   aria-describedby="emailHelp" v-model="confirmPassword" @blur="v$.confirmPassword.$touch" required>
            <div class="input-errors" v-for="error in v$.confirmPassword.$errors" :key="error.$uid">
              <div class="fst-italic form-text text-red">{{ error.$message }}</div>
            </div>
          </div>
          <div v-if="pageMode==='signup'">
            <div class="col-12 mt-3">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="terms" required v-model="terms"
                       @blur="v$.terms.$touch">
                <label class="form-check-label" for="terms">
                  Yes, I understand and agree to the <a href="/legal/terms-of-use.pdf" target="_blank">Terms of Use</a>,
                  <a href="/legal/privacy-policy.pdf" target="_blank">Privacy Policy</a> and
                  <a href="/legal/content-provider-agreement.pdf" target="_blank">Content Provider Agreement</a>
                </label>
              </div>
              <div class="input-errors" v-for="error in v$.terms.$errors" :key="error.$uid">
                <div class="fst-italic form-text text-red">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div>
            <div class="mt-3 d-flex flex-column" v-if="pageMode==='signin'">
              <button class="btn btn-primary rounded col-6" @click="signWithEmailAndPassword">Sign in</button>
              <button class="btn btn-outline-primary rounded mt-2 col-6" @click="changePageMode('signup')">Sign up
              </button>
              <button type="button" class="btn btn-light rounded mt-2 col-6" @click="changePageMode('resetPassword')">
                Reset password
              </button>
            </div>
            <div class="mt-3 d-flex flex-column" v-if="pageMode==='signup'">
              <button class="btn btn-primary rounded col-6" @click="createAccountEmailAndPassword">Sign up
              </button>
              <button class="btn btn-outline-primary rounded mt-2 col-6" @click="changePageMode('signin')">Sign in
              </button>
              <button type="button" class="btn btn-light rounded mt-2 col-6" @click="changePageMode('resetPassword')">
                Reset password
              </button>
            </div>
            <div class="mt-3 d-flex flex-column" v-if="pageMode==='resetPassword'">
              <button class="btn btn-primary rounded col-6" @click="resetPassword">Reset Password
              </button>
              <button class="btn btn-outline-primary rounded mt-2 col-6" @click="changePageMode('signin')">Sign in
              </button>
              <button class="btn btn-light rounded mt-2 col-6" @click="changePageMode('signup')">Sign up
              </button>
            </div>
          </div>


        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {reactive} from '@vue/composition-api'
import useVuelidate from '@vuelidate/core'
import {email, required, sameAs} from '@vuelidate/validators'
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword
} from "firebase/auth";
import {auth} from "../../firebase.config";
import {saveToSessionStorage} from "@/assets/js/common";


export default {
  name: "Administration",
  setup() {
    const state = reactive({
      email: '',
      password: '',
      confirmPassword: '',
      terms: false
    })
    const rules = {
      email: {email},
      password: {required},
      confirmPassword: {required},
      terms: {
        required,
        sameAs: sameAs(() => true)
      }
    }

    const v$ = useVuelidate(rules, state)

    return {state, v$}
  },
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      terms: false,
      pageMode: "signin"
    }
  },
  methods: {
    changePageMode(mode) {
      this.pageMode = mode
    }
    ,
    confirmPasswordViability() {
      if (this.password.length < 8 || this.password !== this.confirmPassword) {
        this.$toast.error("The password must be more than 8 characters and same as the confirm password")
        return false
      }
      return true
    },
    confirmAcceptedTermsAndConditions() {
      if (this.terms === false) {
        this.$toast.error("You must agree before submitting!");
        return false;
      }
      return true;
    },
    resetPassword() {
      sendPasswordResetEmail(auth, this.email)
          .then(() => {
            this.$toast.success('A reset password mail has been send to your email')
            this.$router.push({name: "Recipes"})
          })
          .catch(() => {
          });

    },
    sendUserVerificationEmail() {
      sendEmailVerification(auth.currentUser)
          .then(() => {
          });
    },
    createAccountEmailAndPassword() {
      if (this.confirmPasswordViability() && this.confirmAcceptedTermsAndConditions()) {
        createUserWithEmailAndPassword(auth, this.email, this.password)
            .then(() => {
              this.$toast.success('You have been registered successfully');
              this.$router.push({name: "Recipes"});
              this.sendUserVerificationEmail();
            })
            .catch((error) => {
              this.$toast.success(error.message);
            });
      }
    },
    signWithEmailAndPassword() {
      signInWithEmailAndPassword(auth, this.email, this.password)
          .then((userCredential) => {
            this.$store.state.loggedIn = true
            this.$toast.success('Welcome back, you have been signed in successfully');
            const user = userCredential.user;
            saveToSessionStorage("user_details", user)
            this.$emit("signedIn")
            this.$router.push({name: "Home"})
          })
          .catch((error) => {
            this.$toast.warning(error.message);
          });
    }
  }
}
</script>

<style scoped>

</style>