import {child, get, onValue, ref, set, update} from "firebase/database";
import {database} from "../../../firebase.config";
import {v4 as uuidv4} from 'uuid';

export class DatabaseDAO {
    /**
     *
     * @param record
     * @param recordUrl
     * @param model
     * @returns {Promise<void>}
     */
    addRecord(record, recordUrl, model=null) {
        const newModel = model ? new model(...record) : record;
        //if no id is provided, generate one
        if (!newModel.id) {
            newModel.id = uuidv4();
        }
        const saveRecordUrl = `${recordUrl}/${newModel.id}`;
        const mergedModel = {...newModel, ...record};
        return set(ref(database, saveRecordUrl), mergedModel);
    }

    /**
     *
     * @param recordId
     * @param recordUrl
     * @param record
     * @returns {Promise<void>}
     */
    updateRecord(recordId, recordUrl, record) {
        const saveRecordUrl = `${recordUrl}/${recordId}`;
        const updates = {};
        updates[saveRecordUrl] = {...record};
        updates['updated_at'] = new Date().toISOString();
        return update(ref(database), updates);
    }

    /**
     *
     * @param recordId
     * @param recordUrl
     * @returns {Promise<void>}
     */
    deleteRecord(recordId, recordUrl) {
        return this.updateRecord(recordId, recordUrl, {});
    }

    /**
     *
     * @param recordId
     * @param recordUrl
     * @returns {Promise<DataSnapshot>}
     */
    getRecord(recordId, recordUrl) {
        const dbRef = ref(database);
        const saveRecordUrl = `${recordUrl}/${recordId}`;

        return get(child(dbRef, saveRecordUrl)).then((snapshot) => {
            if (snapshot.exists()) {
                return snapshot.val();
            } else {
                return {};
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    /**
     *
     * @param recordUrl
     * @returns {Promise<DataSnapshot>}
     */

    getRecords(recordUrl) {
        const dbRef = ref(database);
        return get(child(dbRef, recordUrl)).then((snapshot) => {
            if (snapshot.exists()) {
                return snapshot.val();
            } else {
                return [];
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    listenRecordChanges(recordUrl, callback) {
        const recordRef = ref(database, recordUrl);
        onValue(recordRef, (snapshot) => {
            return callback(snapshot.val())
        });
    }

}

export default new DatabaseDAO
