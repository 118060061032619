<template>
  <div class="container-fluid">
    <div class="row d-flex justify-content-center">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="m-3 rounded">
          <images-slider
              :images="galleryItems"
              :image-start-index="galleryItemIndex"
              :key="renderKey.slider"
              @setActiveImage="setActiveImage"
              class="p-5 bg-dark bg-gradient text-white rounded"
          />
          <div class="mt-3">
            <div class="d-flex float-start">
              <social-sharing :share-url="shareUrl" :share-title="shareTitle" :key="renderKey.sharing"/>
            </div>
            <div class="d-flex float-end">
              <i class="bi bi-hand-thumbs-up-fill icon" @click="reactGalleryItem"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImagesSlider from "@/components/ImagesSlider";
import SocialSharing from "@/components/SocialSharing";
import GalleryService from "@/services/gallery/GalleryService";
import {mapState} from 'vuex';

export default {
  name: "GallerySlider",
  components: {
    ImagesSlider,
    SocialSharing
  },
  data() {
    return {
      renderKey: {
        slider: 0,
        sharing: 0
      },
      galleryItemIndex: parseInt(this.$route.params.galleryItemIndex) || 0,
      item: null,
      shareUrl: "",
      shareTitle: ""
    }
  },
  computed: {
    ...mapState(['galleryItems']),
    galleryItems() {
      return (Object.values(this.$store.getters.getGalleryItems));
    }
  },
  watch: {
    galleryItems() {
      this.renderKey.slider++;
      this.setActiveImage(this.galleryItemIndex);
    }
  },
  methods: {
    reactGalleryItem() {
      this.item.likes++;
      GalleryService.updateItem(this.item.id, this.item);
      this.$toast.success("You have liked");
    },
    setActiveImage(itemIndex) {
      if (this.galleryItems.length > 0) {
        this.item = this.galleryItems[itemIndex];
        this.shareUrl = `${window.location.origin}/gallery/image/${itemIndex}`;
        this.shareTitle = this.item.title;
        this.renderKey.sharing++;
      }
    }
  }
}
</script>

<style scoped>

.icon {
  font-size: 1.6rem;
  color: green;
}

</style>
