import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import VueCompositionAPI from '@vue/composition-api'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueCarousel from 'vue-carousel';
import VueHtmlToPaper from 'vue-html-to-paper';
import VueSocialSharing from 'vue-social-sharing';
import Ads from 'vue-google-adsense'
import AOS from 'aos';
import 'aos/dist/aos.css';
import plugin from '@serializedowen/vue-img-watermark'

import VueMq from 'vue-mq'

Vue.use(VueMq, {
    breakpoints: {
        sm: 575,
        md: 768,
        lg: 992,
        xl: Infinity,
    }
})

import VueQuillEditor from 'vue-quill-editor'

import VueLazyBackgroundImage from 'vue-lazy-background-images/VueLazyBackgroundImage';
Vue.component('lazy-background', VueLazyBackgroundImage);

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor, /* { default global options } */)

Vue.use(BootstrapVue, {});
Vue.use(IconsPlugin, {});
Vue.use(VueCarousel);
Vue.use(VueCompositionAPI);
Vue.use(VueHtmlToPaper);
Vue.use(VueSocialSharing);

Vue.use(require('vue-script2'))

Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)


Vue.use(plugin)

const toastOptions = {
    timeout: 2000,
    closeOnClick: true
};


Vue.prototype.$getMachineId = function getMachineId() {

    let machineId = localStorage.getItem('mac-id');

    if (!machineId) {
        machineId = crypto.randomUUID();
        localStorage.setItem('mac-id', machineId);
    }

    return machineId;
};

Vue.use(Toast, toastOptions);

Vue.config.productionTip = false

Vue.filter('timeFilter', function (num) {
    const hours = Math.floor(num / 60);
    const minutes = num % 60;
    return `${hours > 0 ? hours + ' hr' : ''} ${minutes > 0 ? minutes + ' min' : ''}`;
});

Vue.filter('trimString', function (string, num) {
    const length = num || 140;
    return string.length > length ? string.substring(0, length) + "..." : string;
})

new Vue({
    router,
    store,
    render: h => h(App),
    mounted() {
        AOS.init()
    },
}).$mount('#app')
