<template>
  <div>
    <form>
      <base-input label="Title" type="text" id="title" placeholder="Title" v-model="recipe.title"
                  :model-value="recipe.title"></base-input>
      <base-text-area label="Description" placeholder="Description"
                      v-model="recipe.description" :model-value="recipe.description"></base-text-area>
      <multi-select label="Category" data-set="category" @handleInput="handleInput" v-model="recipe.category"/>
      <multi-select label="Sub Category" data-set="subcategories" @handleInput="handleInput"
                    v-model="recipe.subcategories"/>
      <multi-select label="Cuisine" data-set="cuisines" @handleInput="handleInput" v-model="recipe.cuisines"/>
      <multi-select label="Tags" data-set="tags" @handleInput="handleInput"
                    v-model="recipe.tags"/>
      <base-input-list label="Ingredients" placeholder="Ingredients"
                       v-model="recipe.ingredients" :items-list="recipe.ingredients"></base-input-list>
      <base-input-list label="Steps" placeholder="Steps" v-model="recipe.steps"
                       :items-list="recipe.steps"></base-input-list>
      <base-text-area placeholder="Notes" label="Notes" v-model="recipe.notes"
                      :model-value="recipe.notes"></base-text-area>
      <base-input placeholder="Cooking Time (in minutes)" type="number" label="Cooking Time (in minutes)"
                  v-model="recipe.cookingTime" :model-value="recipe.cookingTime"></base-input>
      <base-input placeholder="Servings" type="number" label="Servings" v-model="recipe.servings"
                  :model-value="recipe.servings"></base-input>
      <multi-select label="Cooking Devices" data-set="cookingDevices" @handleInput="handleInput"
                    v-model="recipe.cookingDevices"/>
      <base-input placeholder="Video Link" type="url" label="Video Link" v-model="recipe.video"
                  :model-value="recipe.video"></base-input>
    </form>
  </div>
</template>

<script>
import BaseInput from "@/components/bases/BaseInput";
import BaseTextArea from "@/components/bases/BaseTextArea";
import BaseInputList from "@/components/bases/BaseInputList";
import MultiSelect from "@/components/MultiSelect";
import {getFromSessionStorage} from "@/assets/js/common";

export default {
  name: "AddRecipeForm",
  components: {BaseTextArea, BaseInput, MultiSelect, BaseInputList},
  props: {
    mode: {
      type: String,
      default: "add"
    },
  },
  data() {
    return {
      recipe: {
        title: "",
        description: "",
        ingredients: [],
        steps: [],
        notes: "",
        cookingTime: 0,
        cookingDevices: [],
        category: "",
        subcategories: [],
        cuisines: [],
        tags: [],
        servings: 2,
        creatorEmail: "",
        video: ""
      },
    }
  },
  watch: {
    recipe: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.setCreatorEmail()
  },
  methods: {
    handleInput(input, values) {
      this.recipe[input] = values;
    }
    ,
    setCreatorEmail() {
      this.recipe.creatorEmail = getFromSessionStorage("user_details").email
      return getFromSessionStorage("user_details").email;
    }
    ,


  }

}
</script>

<style scoped>

</style>
