<template>
  <b-container v-if="paginatedItems">
    <div class="row ">
      <div class="col-12 d-flex justify-content-end">
        <a class="btn btn-block btn-outline-primary btn-md float-right m-3" @click="navigateToPage('AddRecipe')">
          <i class="bi bi-plus"></i> Add Recipe
        </a>
      </div>
    </div>
    <b-container fluid="xl" class="recipe-preview">
      <base-section-loader v-if="loading"/>
      <div v-if="!loading">
        <div class="search-heading">

          <div v-if="categoryQuery && !searchQuery && !ingredientQuery">
            Showing all <span v-if="categoryQuery" class="tag">{{ categoryQuery }}</span> results
          </div>
          <div v-if="searchQuery && ingredientQuery !=null">
            Showing <span v-if="categoryQuery" class="tag">{{ categoryQuery }}</span> results for <span
              class="tag">{{ searchQuery }}</span>
            recipes that contain <span class="tag">{{ ingredientQuery }}</span>
          </div>
          <div v-else-if="searchQuery && !ingredientQuery">
            Showing <span v-if="categoryQuery" class="tag">{{ categoryQuery }}</span> results for <span
              class="tag">{{ searchQuery }}</span>
          </div>
          <div v-else-if="!searchQuery && ingredientQuery">
            Showing <span v-if="categoryQuery" class="tag">{{ categoryQuery }}</span> results for recipes that contain
            <span class="tag">{{ getIngredientQuery }}</span>
          </div>

          <a
              v-if="searchQuery || ingredientQuery"
              @click="resetSearch"
              href="#"
              class="reset"
          >
            Reset search
          </a>

        </div>
      </div>
      <b-row class="grid d-flex">
        <div
            v-if="searchQuery && !paginatedItems.length || ingredientQuery && !paginatedItems.length"
            class="empty-results"
        >
          <span>Nothing found, try searching again.</span>
        </div>
        <paginated-recipes
            :prop-paginated-items="paginatedItems"
            :key="renderKey" v-if="render"/>
      </b-row>
<!--      <pagination-->
<!--          :number-of-items="computedItems.length"-->
<!--          @handlePagination="handlePagination"-->
<!--      />-->
    </b-container>
  </b-container>
</template>
<script>
// import Pagination from "@/components/Pagination";
import BaseSectionLoader from "@/components/bases/BaseSectionLoader";
import {mapGetters} from "vuex";
import PaginatedRecipes from "@/components/PaginatedRecipes.vue";

const JsonSearch = require('search-array').default


export default {
  name: "AdminRecipesView",
  components: {
    PaginatedRecipes,
    BaseSectionLoader,
    // Pagination,
  },
  computed: {
    ...mapGetters([
      'getCategories',
      'getSubcategories',
      'getCuisines'
    ]),
  },
  props: {
    propSearchQuery: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      loading: false,
      isFixed: false,
      scrollPosition: null,
      routeName: this.$route.name,
      searchQuery: null,
      ingredientQuery: null,
      categoryQuery: null,
      subcategoryQuery: null,
      cuisineQuery: null,
      itemStartIndex: 0,
      itemEndIndex: 0,
      items: [],
      computedItems: [],
      paginatedItems: [],
      recipes: [],
      renderKey: 0,
      render: true,
    }
  },
  watch: {
    '$route.query.search': {
      handler(term) {
        this.searchQuery = term;
        this.watchHandler()
      },
      deep: true,
      immediate: true
    },
    recipes: {
      handler() {
        this.watchHandler()
      },
      deep: true,
      immediate: true,
    },
    paginatedItems: {
      handler() {
        this.render = false;
        this.renderKey++;
        this.render = true;
      },
      deep: true,
      immediate: true,
    },
    async propSearchQuery(oldVal, newVal) {
      await this.getRecipes()
      switch (newVal.type) {
        case 'category':
          await this.$router.push({query: {category: newVal.query}});
          this.recipes = this.filterRecipesByCategory(newVal.query)
          break;
        case 'subcategory':
          await this.$router.push({query: {subcategory: newVal.query}});
          this.recipes = this.filterRecipesBySubcategory(newVal.query)
          break;
        case 'cuisines':
          await this.$router.push({query: {cuisine: newVal.query}});
          this.recipes = this.filterRecipesByCuisine(newVal.query)
          break;
        default:
          this.handleTitleSearch(newVal.searchTerm);
          break;
      }
    },
  },
  mounted() {
    this.getRecipes();
  },
  methods: {
    navigateToPage(page) {
      this.$router.push({name: page})
    },
    async getRecipes() {
      this.loading = true;
      this.paginatedItems = await this.$store.dispatch('recipes/getRecipes', {limit: 1000000, onlyPublished: false})
      console.log(this.paginatedItems)
      this.loading = false;
    },
    handleSearch(term) {
      const searcher = new JsonSearch(this.recipes)
      let foundObjects = searcher.query(term)
      this.searchQuery = term;
      this.$router.push({query: {search: term, searchBy: 'title'}});
      this.paginatedItems = foundObjects
    },
    filterRecipesByCategory(category) {
      return this.recipes.filter(item => item.category && item.category
          .some(i => i.toLowerCase().trim()
              .includes(category)));
    },
    filterRecipesBySubcategory(subcategory) {
      return this.recipes.filter(item => item.subcategories && item.subcategories
          .some(i => i.toLowerCase().trim()
              .includes(subcategory)));
    },
    filterRecipesByCuisine(cuisine) {
      return this.recipes.filter(item => item.cuisines && item.cuisines
          .some(i => i.toLowerCase().trim()
              .includes(cuisine)));
    },
    watchHandler() {
      this.itemStartIndex = 2;
      this.itemEndIndex = 26;
      this.items = this.recipes;
      this.paginatedItems = this.items.slice(this.itemStartIndex, this.itemEndIndex);
      this.renderKey++;
    },
    getItemIndex(itemId) {
      return this.items.findIndex(item => item.id === itemId);
    },
    handlePagination(pagination) {
      this.itemStartIndex = pagination.itemStartIndex;
      this.itemEndIndex = pagination.itemEndIndex;
      this.paginatedItems = this.getPaginatedItems(this.itemStartIndex, this.itemEndIndex);
    },

    // TODO: bind these to the form, allow resetting
    handleFilterByCategory(selectedCategory) {
      this.categoryQuery = selectedCategory;
    },
    handleFilterBySubcategory(selectedSubcategory) {
      this.subcategoryQuery = selectedSubcategory;
    },
    handleFilterByCuisine(selectedCuisine) {
      this.cuisineQuery = selectedCuisine;
    },
    resetSearch() {
      this.handleFilterByCategory(null)
      this.$router.push({
        path: '/recipes',
        query: null
      });
      this.$refs.search.resetAll();
    },
    appendQuery(queryName, type) {
      this.$router.push({
        query: {
          ...this.$route.query,
          [queryName]: [type]
        }
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.recipe-preview {
  padding-top: 1.5rem;

  @media (min-width: 992px) {
    padding-top: 2rem;
  }
}

.grid {
  width: 100%;

  @media (min-width: 768px) {
    margin: 0 -15px;
  }
}

.search-heading {
  padding: 0 1rem 1rem;
  font-size: 1rem;
  color: $darkviolet;

  .tag {
    display: inline-block;
    padding: 0.125rem 1rem;
    font-size: 1rem;
    background-color: #ffe7ef;
    border-radius: 50rem;
    text-transform: lowercase;
  }
}

.empty-results {
  position: relative;
  margin: 1rem .5rem;
  width: 100%;
  color: $midviolet;
  background-color: $mediumviolet;
  border-radius: .35rem;

  span {
    display: block;
    width: 100%;
    padding: 100px 2rem;
    font-size: 1.5rem;
    text-align: center;
  }
}

.reset {
  display: inline-block;
  position: relative;
  width: auto;
  margin: .5rem 0;
  font-size: .875em;

  @media (min-width: 768px) {
    float: right;
    margin: 0;
    top: -1.5rem;
  }
}

.searchbar {
  position: relative;

  &.fixed {
    position: -webkit-sticky;
    position: sticky;
    overflow-anchor: none;
    top: 0;
    height: 155px;
    padding: .75rem 0 1rem;
    font-size: .875rem;
    z-index: 4343;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15),
    0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15),
    0 8px 8px rgba(0, 0, 0, 0.15);

    ::v-deep {
      .input-group {
        min-height: 45px;
      }

      .row-search {
        margin: 0 0 .5rem;
      }
    }
  }
}
</style>
