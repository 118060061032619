import {deleteObject, getDownloadURL, listAll, ref, uploadBytes, getMetadata} from "firebase/storage";
import {storage} from "../../../firebase.config";


export class BucketDAO {
    /**
     *
     * @param file
     * @param filePath
     * @returns {Promise<*>}
     */
    async addFile(file, filePath) {
        let uploadedFile
        let fileRef = ref(storage, filePath);
        await uploadBytes(fileRef, file).then((snapshot) => {
            uploadedFile = snapshot
        });
        return uploadedFile

    }

    /**
     *
     * @param file
     * @param filePath
     * @returns {Promise<void>}
     */

    async deleteFile(filePath) {
        let fileRef = ref(storage, filePath);
        await deleteObject(fileRef).then(() => {
            return {"status": "success", "message": "File has been deleted"}
        }).catch((error) => {
            return error
        });
    }

    /**
     *
     * @param fileRef
     * @returns {*}
     */

    async getAuthorisedDownloadURL(fileRef) {
        return await getDownloadURL(fileRef);
    }


    async getDownloadURL(filePath) {
        let fileRef = ref(storage, filePath);
        return await getDownloadURL(fileRef);
    }

    /**
     *
     * @param folderPath
     * @returns
     */
    async listFileRefs(folderPath) {
        //let fileRefs = [];
        let folderRef = ref(storage, folderPath);
        return await listAll(folderRef);
    }

    /**
     *
     * @param folderPath
     * @returns
     */
    async listFileURLs(folderPath) {
        let fileURLs = [];
        let filesRefs = await this.listFileRefs(folderPath);
        let files = filesRefs.items;

        for (const file of files){
            const fileUrl = await this.getAuthorisedDownloadURL(file);
            const fileMetadata = await getMetadata(file);
            fileURLs.push({
                url: fileUrl,
                tags: (fileMetadata.customMetadata === undefined ? ['No Tags'] : JSON.parse(fileMetadata.customMetadata.tags))
            });
        }
        return fileURLs;
    }

}
