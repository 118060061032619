import {DatabaseDAO} from "@/services/base/DatabaseDAO";


class SaleLeadsService extends DatabaseDAO{
    constructor() {
        super();
        this.recordUrl = `salesLeads`
    }
    async  createNewSalesLead(salesLeadData) {
        return await this.addRecord(salesLeadData, this.recordUrl)
    }

}

export default new SaleLeadsService();
