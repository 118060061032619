<template>
  <div class="mt-2 p-4">
    <comment-input :reaction-id="reactionId" @comment-posted="reloadCommentList"></comment-input>
    <comments-list :comments="comments"></comments-list>

  </div>
</template>

<script>
import CommentsList from "@/components/CommentsList";
import CommentInput from "@/components/CommentInput";
import RecipeService from "@/services/recipe/RecipeService";

export default {
  name: "CommentsBox",
  props: {
    reactionId: {
      type: String
    }
  },
  data() {
    return {
      reloadKey: 0,
      comments: []
    }
  },
  mounted() {
    this.getRecipeComments();

  },
  components: {CommentInput, CommentsList},
  methods: {
    reloadCommentList() {
      this.reloadKey = this.reloadKey + 1
    },
    async getRecipeComments() {
      this.comments = await RecipeService.getRecipeComments(this.reactionId)
      this.listenNewComments();
    },
    updateComments(comments) {
      if(comments !== null){
        this.comments = comments.reverse();
      }
    },
    listenNewComments() {
      RecipeService.listenRecipeCommentChanges(this.reactionId, this.updateComments)
    }
  }
}
</script>

<style scoped>

</style>
