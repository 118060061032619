<template>
  <div>
    <ShareNetwork
        class="btn btn-sm mx-1"
        v-for="network in networks"
        :network="network.network"
        :popup="{width: 400, height: 200}"
        :key="network.network"
        :style="{backgroundColor: network.color}"
        :url="sharing.url"
        title="Mpishi | meals done right"
    >
                <span class="text-white">
                  <i :class="network.icon"></i> <strong>{{ network.name }}</strong>
                </span>
    </ShareNetwork>
  </div>
</template>
<script>

export default {
  name: "SocialSharing",
  props: {
    propShareUrl: {
      type: String,
      default: window.location.href,
      required: false
    },
    showNames: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.sharing.url = window.location.href
  },
  watch: {
    propShareUrl: function (newVal) {
      this.sharing.url = newVal
    }
  },
  data() {
    return {
      sharing: {
        url: window.location.href,
      },
      networks: [
        {network: "facebook", name: (this.showNames ? "Facebook" : ""), icon: "bi bi-facebook", color: "#1877f2"},
        {network: "pinterest", name: (this.showNames ? "Pinterest" : ""), icon: "bi bi-pinterest", color: "#bd081c"},
        {network: "twitter", name: (this.showNames ? "Twitter" : ""), icon: "bi bi-twitter", color: "#1da1f2"},
        {network: "whatsapp", name: (this.showNames ? "Whatsapp" : ""), icon: "bi bi-whatsapp", color: "#25d366"},
      ]
    }
  }
}
</script>
<style scoped>
</style>
