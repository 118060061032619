import { render, staticRenderFns } from "./CommentsBox.vue?vue&type=template&id=0567f2d4&scoped=true&"
import script from "./CommentsBox.vue?vue&type=script&lang=js&"
export * from "./CommentsBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0567f2d4",
  null
  
)

export default component.exports