<template>
  <b-row class="pagination-navigation">
    <b-col sm="12" md="auto">
      <nav aria-label="Page navigation">
        <ul class="pagination align-items-center">
          <li class="page-item" :class="{ disabled: this.isPreviousButtonDisabled}">
            <a class="page-link p-2" @click="handleButtonClick(-1)">
              <em class="bi bi-chevron-left"></em>
              <span class="pagination-label">
                PREVIOUS
              </span>
            </a>
          </li>

          <li class="page-item page-selector">
            <select
              v-model="currentPage"
              @change="handleButtonClick(0)"
              class="form-select"
            >
              <option v-for="page in pageList" :key="page" :class="(page === currentPage) ? 'selected' : '' ">
                {{ page }}
              </option>
            </select>
            <span>
              of {{ pageList.length }}
            </span>
          </li>

          <li class="page-item" :class="{ disabled: this.isNextButtonDisabled}">
            <a class="page-link p-2" @click="handleButtonClick(1)">
              <span class="pagination-label">
                NEXT
              </span>
              <em class="bi bi-chevron-right"></em>
            </a>
          </li>
        </ul>
      </nav>
    </b-col>

    <b-col sm="12" md="auto">
      <div class="pagination-items">
        <label for="itemPerPageSelect" class="col-form-label">Items per page</label>
        <select
            v-model="numberPerPage"
            id="itemPerPageSelect"
            class="form-select"
            @change="handlePagination(startPage)"
        >
          <option
              v-for="pageLimit in pageLimits"
              :key="pageLimit"
              :value="pageLimit"
          >
            {{ pageLimit }}
          </option>
        </select>
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: 'Pagination',
  props: {
    startPage: {
      type: Number,
      default: 1
    },
    numberOfItems: {
      type: Number
    },
    ItemsPerPage: {
      type: Number,
      default: 24
    },
    pageLimits: {
      type: Array,
      default: () => ([24, 48, 96])
    },
  },
  data() {
    return {
      numberPerPage: this.ItemsPerPage,
      currentPage: this.startPage,
      pageShift: 1
    }
  },
  computed: {
    maxPages: function () {
      return this.getMaxPages(this.numberOfItems, this.numberPerPage);
    },
    pageList: function () {
      return this.getPageList(this.maxPages, this.pageShift)
    },
    isPreviousButtonDisabled: function () {
      return this.currentPage === 1;
    },
    isNextButtonDisabled: function () {
      return this.currentPage === this.maxPages || this.maxPages === 0;
    }
  },
  methods: {
    getMaxPages(numberOfItems, numberPerPage) {
      numberOfItems = (numberOfItems < 0 ? 0 : numberOfItems);
      return Math.ceil(numberOfItems / numberPerPage);
    },
    getPageList(maxPages, pageShift) {
      maxPages = (maxPages < 0 ? 0 : maxPages);
      return Array(maxPages).fill(0).map((_, i) => i + pageShift);
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },
    getItemStartIndex(page, numberPerPage, pageShift) {
      const itemStartIndex = (page - pageShift) * numberPerPage;
      return (itemStartIndex < 0 ? 0 : itemStartIndex);
    },
    getItemEndIndex(page, numberPerPage, numberOfItems, pageShift) {
      const itemEndIndex = this.getItemStartIndex(page, numberPerPage, pageShift) + numberPerPage;
      return (itemEndIndex >= numberOfItems ? numberOfItems : itemEndIndex);
    },
    handleButtonClick(shiftIndex) {
      const newCurrentPage = parseInt(this.currentPage) + parseInt(shiftIndex);
      this.handlePagination(newCurrentPage);
    },
    handlePagination(newCurrentPage) {
      if (newCurrentPage >= this.startPage && newCurrentPage <= this.maxPages) {
        this.setCurrentPage(newCurrentPage);
        this.$emit("handlePagination", {
          "itemStartIndex": this.getItemStartIndex(newCurrentPage, this.numberPerPage, this.pageShift),
          "itemEndIndex": this.getItemEndIndex(newCurrentPage, this.numberPerPage, this.numberOfItems, this.pageShift)
        });
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.pagination-navigation {
  display: flex;
  justify-content: space-between;
  background: $atmosphere;
  padding: 1rem;
  margin: 1rem;
  border-radius: 17px;
  align-items: center;
  font-size: .75rem;

  a,
  .form-select {
    font-size: .75rem;
  }

  .pagination {
    margin-bottom: 0;
  }

  .pagination-items {
    display: flex;

    label {
      display: inline-block;
    }
    select {
      display: inline-block;
      width: auto;
      margin-left: .5rem;
    }
  }

  .page-selector {
    padding: 1rem;
    border-radius: 17px;

    select {
      display: inline-block;
      width: auto;
      margin-right: .5rem;
    }
  }

  @media (max-width: 374px) {
    .page-selector {
      padding: .5rem;

      select {
        margin-right: .15rem;
      }
    }
  }

  @media (max-width: 767px) {
    text-align: center;
    padding: 1rem .5rem;

    .pagination {
      justify-content: space-between;
    }

    .pagination-items {
      display: block;
      margin: 1.5rem auto 0;
      text-align: center;
    }

    .page-selector {
      background: rgba(0,0,0,.05);
    }

    .pagination-label {
      position:absolute;
      left:-10000px;
      top:auto;
      width:1px;
      height:1px;
      overflow:hidden;
    }
  }

}
</style>
