<template>
  <div class="meal-plan-list">
    <div class="d-flex justify-content-end">
      <b-button
          variant="primary"
          @click="showAddMealPlanModal"
          v-if="role==='admin'"
          id="add-meal-plan-btn"
          class="mb-5"
      >
        Add meal plan
      </b-button>
    </div>

    <base-section-loader v-if="loading" />

    <b-row>
      <b-col
        v-for="mealplan in mealPlans"
        :key="mealplan.id"
        sm="12"
        md="6"
        lg="4"
      >
        <base-card>
          <template #header>
            <h4 class="mb-0">{{ mealplan.title }}</h4>
          </template>

          <div class="card-img" v-if="mealplan.image">
            <img :src="mealplan.image" @click="viewMealPlan(mealplan)" alt="mealplan image">
          </div>
          <div class="card-img" v-else>
            <img :src="defaultImage" @click="viewMealPlan(mealplan)" alt="mealplan image">
          </div>

          <b-card-body>
            <b-card-sub-title class="mb-2">{{ mealplan.category }}</b-card-sub-title>
            <b-card-text>{{ mealplan.description }}</b-card-text>
          </b-card-body>

          <b-card-footer>
            <b-button variant="outline-light" @click="viewMealPlan(mealplan)" v-if="role==='public'">View</b-button>
            <b-button variant="outline-light" @click="navigateToMealPlan(mealplan)" v-if="role==='admin'">Add Recipes
            </b-button>
            <b-button variant="secondary" @click="editMealPlan(mealplan)" v-if="role==='admin'">Edit</b-button>
            <b-button variant="danger" @click="deleteMealPlan(mealplan)" v-if="role==='admin'">Delete</b-button>
          </b-card-footer>
        </base-card>
      </b-col>
    </b-row>



  </div>

</template>

<script>

import PlansService from "@/services/plan/PlansService";
import cardBackground from "@/assets/img/wood-bg.jpg";
import defaultImage from "@/assets/img/syd-wachs-epqNIYI6S7E-unsplash.jpeg";
import BaseCard from "@/components/bases/BaseCard";
import BaseSectionLoader from "@/components/bases/BaseSectionLoader";

export default {
  name: "ListMealPlans",
  components: {BaseSectionLoader, BaseCard},
  props: {
    role: {
      type: String,
      required: true,
      default: 'public',
    },
    propMealPlans: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      loading: null,
      mealPlans: [],
      defaultImage: defaultImage,
      cardBackground: cardBackground,
    };
  },
  mounted() {
    this.getMealPlans();

  },
  watch: {
    propMealPlans: function (newVal) {
      this.mealPlans = newVal;
    }
  },
  methods: {
    async getMealPlans() {
      this.loading = true;
      this.mealPlans = await PlansService.getPlans();
      this.loading = false;
    },
    viewMealPlan(mealPlan) {
      // this.$router.push({name: 'PublicMealPlan', params: {id: mealPlan.id}});
      this.$emit('navigate-to-meal-plan', mealPlan);
    },
    deleteMealPlan(item) {
      let deleteMealPlan = confirm("Are you sure you want to delete this meal plan?");
      if (deleteMealPlan) {
        this.$emit("delete-meal-plan", item.id);
      }
    },
    navigateToMealPlan(plan) {
      this.$emit('navigate-to-meal-plan', plan);
    },
    editMealPlan(plan) {
      this.$emit('edit-meal-plan', plan);
    },
    addMealPlan(plan) {
      this.$emit("add-meal-plan", plan);
    },
    showAddMealPlanModal() {
      this.$emit('show-add-meal-plan-modal');
    }
  }
}
</script>

<style lang="scss" scoped>

@media (max-width: 575px) {
  .card-deck {
    display: block !important;

    .card-header {
      h4 {
        font-size: 2rem;
      }
    }
  }
}

.card-header {
  h4 {
    font-size: 2.6rem;
  }
}

.card-img {
  cursor: pointer;
}
</style>
