import RecipeDao from "./RecipeDao";
import {defaultImage} from "./RecipeConfig";
import {getImageKitUrl} from "@/utils/ImageKitUtil";

/**
 * @name RecipeService
 * @description Service to manage Recipes
 * @class
 * @public
 */

class RecipeService {

    /**
     * Add Recipe.
     *
     * @param {object} recipe  recipe to add
     * @return {object} added recipe
     */
    addRecipe(recipe) {
        return RecipeDao.addRecipe(recipe);
    }

    /**
     * Update recipe
     *
     * @param {string} recipeId The recipeId to update.
     * @param {object} recipe The recipe data to update.
     * @return {object} updated recipe.
     */
    async updateRecipe(recipeId, recipe) {
        const dbRecipe = await this.getRecipe(recipeId);
        const updatedRecipe = {...dbRecipe, ...recipe};
        return RecipeDao.updateRecipe(recipeId, updatedRecipe);
    }

    /**
     * Delete recipe
     *
     * @param {string} recipeId The recipeId to delete.
     * @return {object} deleted recipe.
     */
    deleteRecipe(recipeId) {
        return RecipeDao.deleteRecipe(recipeId);
    }

    /**
     * Get recipe
     *
     * @param {string} recipeId The recipeId to fetch.
     * @return {Object} selected recipe.
     */
    async getRecipe(recipeId) {
        let recipe = await RecipeDao.getRecipe(recipeId);
        recipe.images = this.getDefaultRecipeImage(recipe);
        return recipe;

    }

    /**
     * Get all recipes
     *
     * @return {promise} all recipes.
     */
    async getRecipes(onlyPublished = true) {
        const allRecipes = Object.values(await RecipeDao.getRecipes());
        let recipes = [];
        for (let recipe of allRecipes) {
            if (onlyPublished && recipe.isPublished) {
                recipe.images = this.getDefaultRecipeImage(recipe);
                recipes.push(recipe);
            } else if (!onlyPublished){
                recipe.images = this.getDefaultRecipeImage(recipe);
                recipes.push(recipe);
            }
        }
        return recipes;
    }

    /**
     * Get default recipe image
     *
     * @param {Object} recipe The recipe to analyze.
     * @return {Object} recipe images.
     */
    getDefaultRecipeImage(recipe) {
        const hasImage = "images" in recipe;
        if (!hasImage) {
            return [defaultImage];
        }
        return recipe.images;
    }

    /**
     * Add Recipe Like.
     *
     * @param {String} recipeId  recipeId to search
     * @return {object} Liked recipe
     */
    async addRecipeLike(recipeId) {
        const dbRecipe = await this.getRecipe(recipeId);
        if (dbRecipe.likes === undefined) {
            dbRecipe.likes = 0;
        }
        dbRecipe.likes++;
        const updatedRecipe = {...dbRecipe};

        return RecipeDao.updateRecipe(recipeId, updatedRecipe);
    }

    /**
     * Add Recipe View.
     *
     * @param {String} recipeId  recipeId to search
     * @return {object} Viewed recipe
     */
    async addRecipeView(recipeId) {
        const dbRecipe = await this.getRecipe(recipeId);
        if (dbRecipe.views === undefined) {
            dbRecipe.views = 0;
        }
        dbRecipe.views++;
        const updatedRecipe = {...dbRecipe};
        return RecipeDao.updateRecipe(recipeId, updatedRecipe);
    }

    /**
     * Add Recipe Comment.
     *
     * @param {String} recipeId  recipeId to search
     * @param {String} comment  comment to add
     * @return {object} Commented recipe
     */
    async addRecipeComment(recipeId, comment) {
        const dbRecipe = await this.getRecipe(recipeId);
        if (dbRecipe.comments === undefined) {
            dbRecipe.comments = [];
        }
        dbRecipe.comments.push(comment);
        const updatedRecipe = {...dbRecipe};
        return RecipeDao.updateRecipe(recipeId, updatedRecipe);
    }

    /**
     * Get recipe comments
     *
     * @param {string} recipeId The recipeId to search.
     * @return {Array} selected recipe comments.
     */
    async getRecipeComments(recipeId) {
        const dbRecipe = await this.getRecipe(recipeId);
        if ("comments" in dbRecipe) {
            return dbRecipe.comments.reverse();
        }
        return [];
    }

    /**
     * Listen recipe comment changes
     *
     * @param {string} recipeId The recipeId to listen to.
     * @param {method} callback The callback function to emit listen event.
     * @return {void} .
     */
    listenRecipeCommentChanges(recipeId, callback) {
        const commentPath = `recipes/${recipeId}/comments`;
        RecipeDao.listenRecordChanges(commentPath, callback);
    }

    /**
     * Get ImageKit Url
     *
     * @param {string} path The image path
     * @param {object} dimensions The image width and height dimensions
     * @return {string} imageKitUrl.
     */
    getImageKitUrl(path, dimensions) {
        return getImageKitUrl(path, dimensions);
    }


    async getLatestRecipes(recipeLimit) {
        let recipes = await this.getRecipes(true);
        recipes.sort(function (a, b) {
            return b.createdAt - a.createdAt
        });
        return recipes.slice(0,recipeLimit);

    }

    async getQuickestRecipes(recipeLimit) {
        let recipes = await this.getRecipes(true);
        recipes.sort(function (a, b) {
            return a.cookingTime - b.cookingTime
        });
        return recipes.slice(0, recipeLimit)
    }
}

export default new RecipeService();
