<template>
  <button
      @click="handleClick"
      class="btn-anchor"
  >
    <div class="btn-text">
      <slot />
    </div>
    <b-icon-chevron-compact-down />
  </button>
</template>

<script>
export default {
  name: 'ButtonAnchor',
  methods: {
    handleClick() {
      this.$emit("anchorClick");
    }
  },
}
</script>

<style lang="scss" scoped>
.btn-anchor {
  display: block;
  position: relative;
  top: 1rem;
  margin: 0 auto;
  color: rgba(255,255,255,1);
  border: 0;
  background-color: transparent;
  animation: shimmy 1.5s infinite;
  animation-direction: alternate;
  transition: color 250ms ease-out;

  @media (min-width: 768px) {
    top: 75px;
  }

  &:hover {
    color: rgba(255,255,255,.75);
  }

  .btn-text {
    position: relative;
    top: 1rem;
    font-size: .75em;
  }

  .bi {
    margin: 0 auto;
    font-size: 65px;
  }
}

@keyframes shimmy {
  0% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(10px);
  }
}
</style>