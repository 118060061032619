<template>
  <div>
    <b-carousel
        id="carousel-fade"
        fade
        img-width="1024"
        img-height="480"
        controls
        no-animation
        v-model="slide"
    >
      <b-carousel-slide v-for="image in images" :key="image.id" img-blank>
        <template #img>
          <div>
            <h1 class="text-center">{{ image.title }}</h1>
          </div>
          <div class="d-flex justify-content-center">
            <b-img-lazy :src="image.url" fluid-glow :alt="image.title" class="d-block rounded-2 w-100"
                        :style="imageStyle"></b-img-lazy>
          </div>

        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "PhotoViewImageCarousel",
  props: {
    propCarouselImages: {
      type: Array,
      required: true,
    },
    propSlide: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  watch: {
    propCarouselImages: {
      handler: function (val) {
        this.images = val;
      },
      deep: true,
    },
    propIndex: {
      handler: function (val) {
        this.slide = val;
      },
      deep: true,
    },
  },
  data() {
    return {
      slide: this.propSlide,
      sliding: null,
      interval: 0,
      images: this.propCarouselImages,
      imageStyle: {
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        'background-position': 'center center'
      }
    }
  },
}
</script>

<style scoped>

.carousel-control-next-icon {
  color: black !important
}

.carousel-control-prev-icon {
  color: black !important
}

</style>