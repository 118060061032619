import { v4 as uuidV4 } from "uuid";

/**
 * PodcastChapterModel is the podcast chapter model class.
 * @class
 * @constructor
 * @public
 * @property {uuid} id - podcast chapter id
 * @property {string} title - podcast chapter title
 * @property {string} note - podcast chapter note
 * @property {boolean} published - podcast chapter  published
 * @property {string} audioPath - podcast audio path
 * @property {string} audio - podcast chapter audio
 * @property {boolean} isFree - podcast chapter is free
 * @property {Date} createdAt - podcast chapter createdAt
 * @property {Date} updatedAt - podcast chapter updatedAt
 */

class PodcastChapterModel {
    constructor(title, note, category, feedPath, feed, isFree) {
        this.id = uuidV4();
        this.title = title;
        this.note = note;
        this.category = category;
        this.published = false;
        this.feedPath = feedPath;
        this.feed = feed;
        this.isFree = isFree;
        this.createdAt = new Date();
        this.updatedAt = null;
    }
}

export default PodcastChapterModel;
