<template>
  <div class="logo-wrapper" @click="animateLetters">

    <svg version="1.1" id="logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 259.1 107.5" style="enable-background:new 0 0 259.1 107.5;" xml:space="preserve" transform-origin="center">

      <g id="carrot-i">
        <path class="st0" d="M249.2,62.3c7.8,5.2-1.7,16.6-4.9,22.2c-3,4.6-21.9,30.6-22.2,13.6c1.1-8.2,10.7-46.2,22.6-38.5h0.1
          C246.1,60.6,247.7,61.3,249.2,62.3z"/>
              <path class="st1" d="M244.7,52.4c0.2-0.5,0-1.9,0.7-1.9c0.6,0,1.3-0.2,1.8,0.4c0,0.8-0.4,1.5-0.4,2.4c1-1,1.4-2.4,1.9-3.7
          c0.5-1.2,1-0.6,1.9-0.4c1.6,0.3-2.6,6.2-2.5,7.4c0.7-0.4,0.7-1.3,1.2-1.7c0.7-1.5,1.8-2.8,2.6-4.3c0.4-0.8,0.8-1.1,1.7-0.5
          c0.6,0.4,0.8,0.7,0.3,1.4c-1.4,2.6-3.4,4.8-4.7,7.5c2-2.3,4.1-4.3,6.3-6.4c3.2,2,0.1,2,0.6,3c1.6,1.4,1.1,1.6-0.3,2.9
          c-1.7,1.2-3.2,2.8-4.9,4C243.8,57.2,243.2,61.4,244.7,52.4z"/>
              <path class="st2" d="M249.2,54.9c0,0,0-0.1,0.1-0.1C249.2,54.8,249.2,54.9,249.2,54.9L249.2,54.9z"/>
      </g>
      <g id="asparagus-h">
      <path class="st1" d="M195.5,23.4c-0.5-0.4-0.6-0.9-0.5-1.6c0.7-3.2,1-6.4,0.5-9.7c-0.2-1,0.1-2.2,0.2-3.2c1-0.2,1.1-1.3,1.8-1.8
        c0.3-0.2,0.6-0.6,1.1-0.4c0.4,0.2,0.4,0.7,0.4,1.1c-0.1,1.2-0.6,2.3-1.2,3.4c-0.3,0.5-0.5,0.8,0.2,1.2c0.8,0.6,0.3,1.5,0.1,2.3
        s-0.7,1.6,0.4,2.1c0.2,0.1,0.3,0.6,0.2,0.9C198.5,20.1,197.8,22.2,195.5,23.4z"/>
            <path class="st1" d="M190.6,13.3c-0.1,2.4-0.7,4.8-0.3,7.2c0.1,0.8-0.4,1.5-0.6,2.3c-1.7-1.4-2.3-3.3-2.4-5.4c0-0.8-0.4-2,1.1-2.1
        c0.4,0,0.2-0.5,0.1-0.8c-0.1-0.8-0.3-1.6-0.1-2.3C189.9,11,190,12.8,190.6,13.3z"/>
            <path class="st3" d="M194.9,33.6c-1.1-1.8,0.2-3.4,0.3-5.1c0.8-0.1,1-1,1.6-1.4c0.3-0.2,0.6-0.4,1-0.3c0.4,0.2,0.4,0.6,0.4,0.9
        C198.3,29.6,196.5,32.7,194.9,33.6z"/>
            <path class="st1" d="M189.4,27.1c0.1,1.2,0.1,2.5,0.2,3.7c0.1,0.7-0.1,1.3-0.7,1.8c-1.9-1.5-2.2-3.7-2.3-6c0-0.5,0-1,0.5-1.2
        c0.6-0.2,0.9,0.4,1.2,0.7C188.7,26.4,188.7,27.2,189.4,27.1z"/>
            <path class="st1" d="M191.1,8.3c0.6,1.2,0.1,2.3-0.4,3.4c-1.4,0.7-1.2-0.9-1.7-1.3c-0.5-1.2-0.8-2.5-0.7-3.8c0-0.5,0.1-1,0.7-1.1
        c0.5-0.1,0.8,0.4,1,0.7C190.5,6.8,190.4,7.7,191.1,8.3z"/>
            <path class="st3" d="M195.5,5c-0.6,0.3-0.5,0.9-0.7,1.5c-0.1,0.6-0.3,1.3-1,1.3c-0.9,0-0.8-0.9-1-1.5c-0.1-0.4,0.4-1.1-0.5-1.2
        c0.1-0.9,0.6-1.7,0.5-2.7c0.3-0.4,0.6-0.9,0.9-1.3c1.1,0.1,0.9,1.4,1.6,1.8c0,0.2,0,0.3,0,0.5C194.5,4.1,195,4.6,195.5,5L195.5,5z"
            />
            <path class="st4" d="M195.5,5.1c-0.5-0.5-1-0.9-0.3-1.6C195.4,4,195.6,4.5,195.5,5.1z"/>
            <path class="st3" d="M193.1,27.5c-0.7,0.8-1.2,0.5-1.4-0.3c-0.5-1.7-0.7-3.4-0.1-5.1c0.2-0.7,0.6-1.5,1.5-0.6S194,26.5,193.1,27.5z
        "/>
            <path class="st3" d="M193.8,11.3c0.1,0.2,0.2,0.3,0.2,0.5c0.3,1.9,0.5,3.7-0.1,5.6c-0.1,0.4-0.3,0.7-0.7,0.7
        c-0.5,0.1-0.6-0.3-0.8-0.6c-0.9-1.8-0.7-3.8-0.2-5.6C192.5,11.2,193,10.9,193.8,11.3z"/>
            <path class="st4" d="M194.8,6.5c-0.1,0.6-0.3,1.3-1,1.3c-0.9,0-0.8-0.9-1-1.5c-0.1-0.4,0.4-1.1-0.5-1.2c-0.2,0.4-0.3,0.8-0.5,1.2
        c0.1,0.8-0.3,1.4-0.7,2c0.6,1.2,0.1,2.3-0.4,3.4c0.4,0.6,0.1,1.1-0.1,1.7c-0.1,2.4-0.7,4.8-0.3,7.2c0.1,0.8-0.4,1.5-0.6,2.3
        c-0.1,1.4-0.2,2.8-0.3,4.3c0.1,1.2,0.1,2.5,0.2,3.7c0.1,0.7-0.1,1.3-0.7,1.8c-1.1,16.9-2.6,33.7-4.6,50.5c-0.1,1.1-0.3,2.3-0.2,3.5
        c0.1,2.2,1.7,4,3.7,4.4c2.1,0.4,3.8-0.6,4.7-2.8l0,0c0.7-0.8,0.5-1.8,0.5-2.7c0-4.2,0-8.3,0-12.5c0-3.8,0-7.7,0.1-11.5l0,0
        c0.3-1.9,0.3-3.7,0.2-5.6c0.1-0.5,0.2-1,0.2-1.5c0.4-6.9,0.9-13.9,1.3-20.8c-1.1-1.8,0.2-3.4,0.3-5.1s0.2-3.4,0.3-5.1
        c-0.5-0.4-0.6-0.9-0.5-1.6c0.7-3.2,1-6.4,0.5-9.7c-0.2-1,0.1-2.2,0.2-3.2c0-1.3,0-2.6-0.3-3.8C194.9,5.4,195,6,194.8,6.5z
         M193.1,27.5c-0.7,0.8-1.2,0.5-1.4-0.3c-0.5-1.7-0.7-3.4-0.1-5.1c0.2-0.7,0.6-1.5,1.5-0.6S194,26.5,193.1,27.5z M194,11.9
        c0.3,1.9,0.5,3.7-0.1,5.6c-0.1,0.4-0.3,0.7-0.7,0.7c-0.5,0.1-0.6-0.3-0.8-0.6c-0.9-1.8-0.7-3.8-0.2-5.6c0.2-0.7,0.7-1,1.5-0.6
        C193.9,11.5,194,11.7,194,11.9z"/>
    </g>
      <g id="eggplant-h">
        <path class="st5" d="M200.8,94c-2.9-1.3-4.7-4.2-5-8.2c-0.2-2.6,0.4-5.1,1-7.5c0.8-3.2,0.5-6.3-0.8-9.3c-0.8-1.8-1.6-3.5-2.4-5.3
          c-0.2-0.5-0.3-0.8,0.1-1.3c0.5-0.5,0.9-1.2,1.2-1.9c0.2-0.4,0.4-0.5,0.8-0.4c0.8,0.3,1.7,0.5,2.5,0.5c0.5,0,0.7-0.2,0.7-0.7
          c-0.1-0.9-0.2-1.8-0.5-2.7c-0.2-0.4-0.1-0.6,0.3-0.8c0.5-0.2,1.1-0.4,1.5-0.8c0.9-0.9,1.7-0.6,2.6-0.1c2.8,1.6,5.1,3.9,6.9,6.5
          c4.1,5.7,6.7,12,7.1,19.2c0.2,3.2-0.3,6.3-2.2,9C211.8,94.4,206.1,96.1,200.8,94z M198.5,84c-0.8,2.2,0.3,5.4,2.1,6.5
          c0.3,0.2,0.5,0.4,0.8,0.2c0.2-0.2,0.1-0.5,0-0.8c-0.7-1.9-1.4-3.9-1.9-5.9c-0.1-0.3-0.1-0.7-0.5-0.7
          C198.7,83.3,198.6,83.8,198.5,84z"/>
              <path class="st3" d="M194.7,54.8c-0.8,0.7-0.8,0.7-1.3-0.4c-0.2-0.3-0.3-0.6-0.3-0.9c-0.5-0.6-0.6,0.3-1,0.2c0,0.1-0.1,0.3-0.1,0.4
          l0,0c-0.6,0.6-0.3,0.8,0.4,0.8l0,0c0.3,0.2,0.5,0.5,0.8,0.7c0.2,0.2,0.3,0.4,0.1,0.6c-1.5,1.7-1.3,3.7-0.9,5.8
          c0.1,0.6,0.3,0.6,0.7,0.2c0.7-0.8,1.3-1.6,1.5-2.6c0.2-0.6,0.4-0.8,0.9-0.4c0.6,0.4,1.4,0.5,2.1,0.6c0.6,0.1,0.8-0.1,0.7-0.7
          c-0.1-0.8-0.2-1.6-0.6-2.3c-0.3-0.5-0.3-0.8,0.4-1c1-0.2,1.8-0.9,2.6-1.5c0.5-0.4,0.4-0.6-0.1-0.8
          C198.6,52.8,196.5,53.1,194.7,54.8z"/>
              <path class="st6" d="M198.5,84c0.2-0.2,0.2-0.7,0.6-0.7c0.4,0,0.4,0.4,0.5,0.7c0.5,2,1.2,4,1.9,5.9c0.1,0.3,0.3,0.6,0,0.8
          c-0.3,0.3-0.6,0-0.8-0.2C198.7,89.4,197.7,86.2,198.5,84z"/>
      </g>
      <g id="chili-s">
        <path class="st7" d="M171.7,34.5c4.7,0.2,7.9,5.5,6,9.9c-0.4,0.9-0.9,1.9-1.4,2.8c-1.9,2.9-2.1,6.1-1.8,9.4
          c0.3,3.4,0.5,6.9,0.9,10.3c0.3,3.2,0.4,6.5,0.4,9.7c-0.1,2.8-0.5,5.5-1.5,8.1c-2,5-5.8,7.5-10.9,8.4c-7.9,1.4-14.5-1.3-20.4-6.5
          c-1.4-1.3-2.6-2.7-3.4-4.4c-1.6-3.4-1.2-6.6,1.4-9.3c1.1-1.1,2.3-2,3.6-2.9c0.5-0.3,1-0.6,1.5-0.1s0.3,1,0.1,1.5
          c-0.6,1.4-1.4,2.7-1.6,4.2c-0.1,0.8-0.1,1.6,0.3,2.2c2.9,4.4,9.3,6.1,13.5,2.7c2.3-1.9,2.5-4.5,2.2-7.2c-0.5-4.6-2.6-8.7-4.4-12.8
          c-1.5-3.4-2.9-6.7-3.6-10.3c-0.8-4.3-0.4-8.3,1.9-12.1c0.9-1.5,2.2-2.5,3.9-2.8c2.2-0.3,4.1,0.3,6,1.4c1.2,0.7,2.2,0.6,3.4-0.1
          C168.9,35.7,170.2,34.8,171.7,34.5z"/>
              <path class="st8" d="M171.7,34.5c-1.5,0.4-2.8,1.2-4.1,2.1c-1.1,0.7-2.2,0.8-3.4,0.1c-1.8-1.1-3.8-1.7-6-1.4
          c0.6-1.3,1.2-2.5,2.7-2.8c0.4-0.1,0.7,0,1,0c1.3,0.2,1.9-0.3,1.8-1.7c-0.1-1.4-0.3-2.8-0.6-4.2c-0.7-3.2-1.5-6.3-2.4-9.4
          c-0.2-0.6-0.1-0.8,0.5-0.9s1.1-0.3,1.6-0.5c0.9-0.3,1.4,0.1,1.8,0.9c0.4,1.1,0.5,2.3,0.6,3.5c0.1,2.8-0.2,5.6-0.2,8.5
          c0,0.9,0.1,1.8,0.2,2.6c0.1,0.7,0.3,0.9,1.1,0.9c1-0.1,2-0.4,3.1,0C170.6,32.5,171.4,33.2,171.7,34.5z"/>
      </g>
      <g id="bread-i">
        <path class="st9" d="M122.3,53.7c-0.3-1.6-0.4-3.2-0.1-4.8c0.8-1,0.7-2.2,1.1-3.4c1.1-0.3,1.3-1.3,1.7-2.2c1.8-3.5,3.5-7,5.2-10.5
          c1,0.6,1.3,1.7,1.4,2.7c0.7,5.3-0.1,10.6-1.1,15.8c-1.2,0.1-1,1.9-2.3,2.1c-2.4,1-4.5,5.3-4.3,8.8c0.5,0.7-0.6,1.7,0.6,2.4
          c1.9-3.9,3.8-7.7,5.7-11.5c0.8,0.5,1.2,1.3,1.2,2.2c0.2,5.8,0.4,11.7,0.4,17.5c0,4.8-4.5,8.4-8.7,7.3c-0.8-0.8-1-1.9-1.1-2.9
          c-0.6-6.1-0.7-12.1,0.1-18.2c0.9-1.5,1.1-3.2,1.3-4.9c2,0.4,2.1-1.5,2.4-2.6c0.7-2.7,2-5,3.2-7.4c0.4-0.8,1-1.5,0.6-2.4
          c-0.3,0-0.7-0.2-0.8-0.1c-2,2.2-4.4,4-4.9,7.3C123.6,50.6,123.3,52.3,122.3,53.7z M125.5,75.8c2.1-3.9,2.3-8.2,3.4-12.4
          C126.1,67,125.7,71.4,125.5,75.8z M130.4,40.6c-0.1,0.1-0.1,0.2-0.2,0.4c0.1,0,0.2,0,0.2-0.1C130.5,40.8,130.4,40.7,130.4,40.6z"/>
              <path class="st10" d="M130.5,51.4c1-5.2,1.8-10.5,1.1-15.8c-0.1-1-0.4-2.1-1.4-2.7c1.2-2-0.5-1.2-1.2-1.3c2.6-3.1,2.7-6,0.5-8.9
          c0.5-1.1,1.2-2.3,0.5-3.6c0.4-0.7,0.6-1.5,0.6-2.3c0-0.9,0.4-1.1,1.2-0.8c1.2,0.9,1.2,2.4,1.3,3.7c1.3,18.7,1,37.4,1.4,56.1
          c0.1,3-0.8,5.7-3.8,7.2s-5.7,0.5-7.4-2.7c4.2,1.1,8.7-2.5,8.7-7.3c0-5.8-0.2-11.7-0.4-17.5c0-0.9-0.4-1.7-1.2-2.2
          C130.4,52.6,130.9,52.1,130.5,51.4z"/>
              <path class="st9" d="M131.7,16c-0.8-0.3-1.2-0.1-1.2,0.8c0,0.8-0.1,1.6-0.6,2.3c-4.4,2.9-5.7,7.4-6.5,12.7c2-3.4,4.8-5.7,6-9.1
          c2.2,2.9,2,5.8-0.5,8.9l0,0c-0.9,0.1-1.6,0.7-2,1.5l0,0c-2,2-3.2,4.4-3.6,7.2l0,0c-0.3,0.6-0.7,1.1-0.3,1.8l0,0
          c-0.3,0.6-0.6,1.2-1.3,1.3c-0.2-7.5-0.3-15,0.8-22.5c0.2-1.7,0.9-3.2,1.8-4.5C126,13.8,129.3,13.7,131.7,16z"/>
              <path class="st10" d="M122.3,53.7c1.1-1.4,1.3-3.2,1.6-4.8c0.5-3.3,2.9-5.1,4.9-7.3c0.1-0.1,0.5,0.1,0.8,0.1
          c-3.8,3.2-5.1,7.7-6.1,12.4c-0.2,1.7-0.4,3.4-1.3,4.9C121.5,57.3,122.1,55.5,122.3,53.7z"/>
              <path class="st10" d="M121.7,43.5c0.8-0.1,1-0.8,1.3-1.3c0.7,1.1-0.2,2.3,0.2,3.4c-0.4,1.1-0.3,2.4-1.1,3.4
          C121.6,47.2,121.5,45.4,121.7,43.5z"/>
              <path class="st11" d="M123.2,45.6c-0.4-1.1,0.5-2.3-0.2-3.4l0,0c0.3-0.6,0.7-1.1,0.3-1.8l0,0c1.2-2.4,2.4-4.8,3.6-7.2l0,0
          c1.1,0,1.7-0.6,2-1.5l0,0c0.8,0,2.4-0.8,1.2,1.3c-1.7,3.5-3.5,7-5.2,10.5C124.5,44.2,124.3,45.3,123.2,45.6z"/>
              <path class="st11" d="M130.5,51.4c0.4,0.7,0,1.2-0.3,1.8c-1.9,3.8-3.8,7.6-5.7,11.5c-1.1-0.8-0.1-1.7-0.6-2.4
          c1.2-3,1.8-6.4,4.3-8.8C129.4,53.3,129.2,51.5,130.5,51.4z"/>
              <path class="st11" d="M123.4,54.1c1-4.7,2.4-9.2,6.1-12.4c0.4,1-0.2,1.7-0.6,2.4c-1.1,2.4-2.5,4.7-3.2,7.4
          C125.5,52.6,125.4,54.5,123.4,54.1z"/>
              <path class="st11" d="M125.5,75.8c0.2-4.4,0.7-8.7,3.4-12.4C127.8,67.5,127.6,71.9,125.5,75.8z"/>
              <path class="st10" d="M128.2,53.4c-2.5,2.4-3.1,5.8-4.3,8.8C123.7,58.8,125.8,54.5,128.2,53.4z"/>
              <path class="st10" d="M130.4,40.6c0,0.1,0,0.2,0,0.3s-0.1,0.1-0.2,0.1C130.3,40.9,130.3,40.8,130.4,40.6z"/>
              <path class="st11" d="M129.4,22.7c-1.2,3.4-3.9,5.7-6,9.1c0.7-5.4,2.1-9.9,6.5-12.7C130.6,20.5,130,21.6,129.4,22.7z"/>
              <path class="st10" d="M126.9,33.1c-1.2,2.4-2.4,4.8-3.6,7.2C123.6,37.5,124.8,35.1,126.9,33.1z"/>
              <path class="st10" d="M128.9,31.6c-0.4,0.9-1,1.6-2,1.5C127.3,32.3,127.9,31.7,128.9,31.6z"/>
              <path class="st10" d="M123.3,40.4c0.4,0.7,0,1.2-0.3,1.8C122.6,41.5,123,40.9,123.3,40.4z"/>
      </g>
      <g id="cucumber-p">
        <path class="st12" d="M98.2,104c2.9-1.5,5.5-3.4,5.9-7.1c0.2-2.2-0.4-4.4-1.1-6.5c-1.7-5.1-3.5-10.1-4.9-15.3
          c-1.9-7.1-2.9-14.4-3.5-21.8c-0.4-4.3-0.6-8.7-1.4-12.9c-0.4-1.9-0.9-3.8-2-5.5s-2.6-2.8-4.6-2.9c0,0.2,0,0.5,0.1,0.7
          c0.5,1.9,1,3.8,1,5.9c0,2.1-0.1,4.2,0.5,6.2c0.7,2.3,0.3,4.5-0.2,6.8c-0.5,1.9-1.1,3.8,0.3,5.6c0.2,0.3,0.3,0.8,0.5,1.2
          c0.8,2.8,0.7,5.7,0.8,8.6c0,1.2,0.3,2.1,1.2,3c0.9,0.8,1.6,1.7,2.3,2.7c1,1.4,0.9,3.1,0.9,4.8c0,2.1-0.2,4.1-0.1,6.2
          c0.1,1.2,0,2.6,0.9,3.7c1.9,2.2,2.6,4.8,2.9,7.6C97.7,97.9,97.4,101,98.2,104z"/>
              <path class="st3" d="M98.2,104c-0.9-3-0.5-6.1-0.8-9.1c-0.3-2.8-1-5.4-2.9-7.6c-0.9-1.1-0.9-2.4-0.9-3.7c-0.1-2,0.1-4.1,0.1-6.2
          c0-1.6,0.1-3.3-0.9-4.8c-0.7-1-1.4-1.9-2.3-2.7c-0.9-0.8-1.2-1.8-1.2-3c0-2.9,0-5.8-0.8-8.6c-0.1-0.4-0.2-0.9-0.5-1.2
          c-1.4-1.8-0.8-3.7-0.3-5.6c0.6-2.3,0.9-4.5,0.2-6.8c-0.6-2.1-0.4-4.1-0.5-6.2c0-2-0.4-3.9-1-5.9c-0.1-0.2-0.1-0.5-0.1-0.7
          c-0.3-0.1-0.5-0.2-0.8-0.1c0.9,2.4,1.5,4.9,1.3,7.5c-0.1,1.7-0.1,3.4,0.4,5c0.8,2.8,0.4,5.6-0.4,8.3c-0.4,1.5-0.6,2.9,0.4,4.4
          c0.9,1.3,1.1,3.1,1.2,4.7c0.1,2.2,0.1,4.3,0.2,6.5c0,1,0.7,1.6,1.3,2.2c0.9,0.9,1.6,1.9,2.3,2.9c0.8,1.2,0.5,2.6,0.6,4
          c0.1,2.6-0.3,5.3,0.1,7.9c0.1,0.8,0.3,1.7,0.9,2.3c1.9,2.1,2.6,4.8,2.9,7.6c0.3,2.9,0,5.9,0.7,8.7c0,0.1,0,0.3,0,0.4
          C97.9,104.3,98.1,104.2,98.2,104z"/>
              <path class="st3" d="M96.7,104.6c-1.2-1.2-2.4-2.4-3.5-3.6c-1.4-1.6-2.4-3.5-3.4-5.3c-0.9-1.6-1.9-3.2-3-4.7
          c-1-1.2-1.1-2.8-1.3-4.4c-0.2-1.6-0.1-3.1-0.1-4.7c0-2.3-1.1-4.2-1.9-6.2c-1.1-2.6-2.4-5.1-3-7.9c-0.4-1.7-0.2-3.4,0.1-5.1
          c0.3-1.6,0.7-3.3,0.8-4.9c0.1-1.2-0.6-2.1-0.9-3.2c-0.7-2.1-1.3-4.1-1-6.3c0.1-0.9,0.4-1.7,0.7-2.5c0.5-1.2,0.8-2.5,0.5-3.9
          c0-0.2,0-0.5-0.3-0.4c-0.3,0-0.2,0.3-0.2,0.5c0.1,1.9-0.3,3.5-1.1,5.2c-0.6,1.4-0.3,3,0,4.5c0.3,1.4,0.6,2.8,1.2,4
          c0.8,1.6,0.6,3.4,0.2,4.9c-1.3,4.3-0.7,8.4,1.2,12.4c1.1,2.4,2.2,4.8,3,7.3c0.4,1.4,0.2,2.8,0.2,4.3c0,1.9,0.2,3.7,0.7,5.5
          c0.2,0.5,0.4,1,0.7,1.4c1.4,1.6,2.5,3.5,3.5,5.4c1.5,2.9,3.4,5.3,5.7,7.4c0.2,0.2,0.4,0.4,0.4,0.7C96.2,105,96.5,104.9,96.7,104.6z
          "/>
              <path class="st12" d="M83.7,31.9c-2.6,0.1-4.7,1.3-6.4,3.2c-0.2,0.3-0.5,0.6-0.7,1c-0.2,0.5-0.5,1-0.8,1.4c0,0.2-0.1,0.5-0.3,0.6
          c-0.1,0.3-0.1,0.7-0.2,1c0.8-0.1,1.2-0.8,1.7-1.2c1.2-0.9,2.2-0.7,2.7,0.7c0.1,0.3,0.2,0.5,0.6,0.6c0.1-0.5,0.1-1,0.3-1.4
          C81.3,35.7,82.6,33.9,83.7,31.9z"/>
              <path class="st3" d="M83.7,31.9c-1.1,1.9-2.4,3.8-3.1,5.9c-0.2,0.5-0.2,1-0.3,1.4c-0.1,0.3-0.2,0.7-0.3,1c0,0.2-0.1,0.5,0.2,0.6
          c0.3,0.1,0.4-0.2,0.4-0.5c0.3-2.9,1.8-5.2,3.3-7.6c0.2-0.3,0.4-0.6,0.4-0.9C84.1,31.8,83.9,31.8,83.7,31.9z"/>
              <path class="st12" d="M74.9,39.8c-0.1-0.3,0.2-0.4,0.3-0.6c0.8-0.1,1.2-0.8,1.7-1.2c1.2-0.9,2.2-0.7,2.7,0.7
          c0.1,0.3,0.2,0.5,0.6,0.6c-0.1,0.3-0.2,0.7-0.3,1c0,0.2-0.1,0.5,0.2,0.6c0.3,0.1,0.4-0.2,0.4-0.5c0.3-2.9,1.8-5.2,3.3-7.6
          c0.2-0.3,0.4-0.6,0.4-0.9c0.5-0.1,0.9-0.1,1.4,0.1c0.9,2.4,1.5,4.9,1.3,7.5c-0.1,1.7-0.1,3.4,0.4,5c0.8,2.8,0.4,5.6-0.4,8.3
          c-0.4,1.5-0.6,2.9,0.4,4.4c0.9,1.3,1.1,3.1,1.2,4.7c0.1,2.2,0.1,4.3,0.2,6.5c0,1,0.7,1.6,1.3,2.2c0.9,0.9,1.6,1.9,2.3,2.9
          c0.8,1.2,0.5,2.6,0.6,4c0.1,2.6-0.3,5.3,0.1,7.9c0.1,0.8,0.3,1.7,0.9,2.3c1.9,2.1,2.6,4.8,2.9,7.6c0.3,2.9,0,5.9,0.7,8.7
          c0,0.1,0,0.3,0,0.4c-0.2,0.2-0.5,0.3-0.9,0.3c-1.2-1.2-2.4-2.4-3.5-3.6c-1.4-1.6-2.4-3.5-3.4-5.3c-0.9-1.6-1.9-3.2-3-4.7
          c-1-1.2-1.1-2.8-1.3-4.4c-0.2-1.6-0.1-3.1-0.1-4.7c0-2.3-1.1-4.2-1.9-6.2c-1.1-2.6-2.4-5.1-3-7.9c-0.4-1.7-0.2-3.4,0.1-5.1
          c0.3-1.6,0.7-3.3,0.8-4.9c0.1-1.2-0.6-2.1-0.9-3.2c-0.7-2.1-1.3-4.1-1-6.3c0.1-0.9,0.4-1.7,0.7-2.5c0.5-1.2,0.8-2.5,0.5-3.9
          c0-0.2,0-0.5-0.3-0.4c-0.3,0-0.2,0.3-0.2,0.5c0.1,1.9-0.3,3.5-1.1,5.2c-0.6,1.4-0.3,3,0,4.5c0.3,1.4,0.6,2.8,1.2,4
          c0.8,1.6,0.6,3.4,0.2,4.9c-1.3,4.3-0.7,8.4,1.2,12.4c1.1,2.4,2.2,4.8,3,7.3c0.4,1.4,0.2,2.8,0.2,4.3c0,1.9,0.2,3.7,0.7,5.5
          c0.2,0.5,0.4,1,0.7,1.4c1.4,1.6,2.5,3.5,3.5,5.4c1.5,2.9,3.4,5.3,5.7,7.4c0.2,0.2,0.4,0.4,0.4,0.7c-0.2,0.1-0.3,0.1-0.5,0.2
          c-2.9,0.5-5.3-0.5-7.4-2.5c-2.5-2.3-4.2-5.3-5.7-8.4c-2.9-6.3-4.7-12.9-6.1-19.7c-1.8-8.8-3.3-17.7-2.7-26.8
          C73.6,45,73.9,42.3,74.9,39.8z"/>
      </g>
      <g id="pepper-p">
	<path class="st4" d="M95.9,35.7c0.1-0.1,0.2-0.1,0.3,0c3.3-0.1,6.5,0.1,9.6,1.4c1.8,0.8,3.2,2.1,5,2.7
		c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.2,0.3,0.3,0.4,0.5l0,0c-0.1,1,0.7,1.4,1.3,2c3.7,4.3,2.8,12-2.1,15.8c-3.1,2.4-5.4,5.1-7.1,8.6
		c-2,4.1-5.4,5.3-9.6,4.7c-1.7-1.2-3.7-1.6-5.6-2.2l0,0c-0.3-1-1-1.4-1.9-1.5l0,0c-0.1-0.1-0.3-0.3-0.4-0.4l0,0
		c-1.5-2.4-2.1-5.1-2.8-7.8c-0.4-1.5-0.8-3-2.4-3.7l0,0c-0.4-2.1-1.5-4-1.8-6.2c0-0.4-0.2-0.7-0.7-0.8c0-0.1,0-0.3,0-0.4
		c0.5-1.3,0.5-2.6,0-3.9c0-0.1,0-0.2,0.1-0.4c0.9-1,1.6-2.1,1.7-3.5l0,0c1-0.1,1.1-0.8,1.1-1.6l0,0c0.1,0,0.1,0,0.2,0
		c3.9-3.4,8-5.5,13.1-3.1C95.2,36.6,95.8,36.5,95.9,35.7z M90.3,38.3c-2.1,0.3-3.9,0-5.1,1.1c-2.1,1.9-3.7,4.2-3.8,7.4
		c-0.1,3.6,1.4,6.5,3.4,9.3c0.5,0.6,1.2,1.1,1.2,2.1c0.2,5.8,3.2,9.3,8.5,10.9c0.7,0.2,1.2,0.9,2,0.5c2.2-1,4-2.4,5.2-4.6
		c1.1-2,1.2-4.7,2.6-6.2c1.5-1.6,3.9-2.4,5.8-3.8c1.7-1.3,2.6-3,2.6-5.2c0.1-6.2-6.3-12.7-12.2-11.6C96.8,38.9,93.4,40.1,90.3,38.3z
		"/>
</g>
      <g id="chili-m">
	<path class="st13" d="M13.9,87.6c-0.5-1.6-0.8-3.3-0.7-4.9c-0.2-0.8-0.2-1.5-0.1-2.3c-0.1-0.3-0.1-0.7,0-1.1c-0.2-1-0.1-2,0.1-2.9
		c-0.1-1.5,0.1-3,0.4-4.4c0-0.1,0-0.2,0.1-0.3c-0.1-0.7,0.1-1.3,0.3-2c0-0.8,0.1-1.6,0.4-2.3c-0.1-0.5,0.1-1,0.3-1.5
		c0-0.5,0.1-1,0.3-1.5c0.8-4.3,2.5-8.3,3.8-12.4c0.8-2.1-1.7-2-2.7-3.2c-2.2-1.4-4.7,6.6-5.8-2.3c-1.6,0.9-4,0-4.6,2.5
		c-0.9,2.3-1.5,4.7-2.2,7.1c0,0.6-0.2,1.2-0.4,1.8c-0.1,1-0.3,2-0.6,3c0.1,0.5-0.1,1-0.3,1.5c0.1,0.3,0,0.6-0.1,0.8
		c0,0.1,0,0.2-0.1,0.3c0,0.7-0.1,1.3-0.3,2c0,0.1,0,0.2-0.1,0.3c-0.2,2.6-0.1,5.1,0,7.6c1.8,11,7,23.7,17.8,27.9
		c0.1,0,0.2,0.1,0.3,0.1c0.6-0.6-1.4-2.4-1.6-3.3C16.1,94.9,14.6,91.3,13.9,87.6z"/>
        <path class="st3" d="M11.1,42.2c-4.9,1.8-6.7,6.5,0.1,2.6c-0.3,9.6,2.9,1.4,4.5,2.4c0.8,1.1,4.6,4.1,3.8,1.1c-0.4-2-1.2-4.1-3-5.2
		c-0.5-0.1-1.3-0.8-0.7-1.2c0-0.3,0.2-0.5,0.4-0.8c0.1-0.5,0.5-1,0.9-1.3c-1.3-2.9-2.7,2.5-3.8,2.2C12.6,41.9,11.8,42.2,11.1,42.2z"
        />
</g>
      <g id="jalepeno-m">
	<path class="st14" d="M22,52.9c1.4,0,2.7,0.4,4.1,0.8c4.2,1.3,7.8,4.8,10.7,8.9c0.7,1.1,1.6,2,2.2,3.4c0,0.7-0.2,2.4-1.1,1.7
		c-1.6-1.2-1.3,0.1-1.2,1.6c-1.1,1.7-2.4,0.2-3.3-1.7c-0.9-1.4-1.9-3-3-4.3c-3.5-4.5-8-8.2-12.8-8.5C16.3,53.5,20.9,52.8,22,52.9z"
  />
        <path class="st3" d="M39.8,68.3c0.2,1.1-0.3,2-0.5,2.8c0.1,0.6,0.9,2,0.1,2c-0.7-2.5-1.1-1-2.5-1.3l0,0c-2.6-1.2-1.2-1.4,0.2-1.9
		c-0.2-2.4-0.3-2.7,1.7-1c0.1-0.8,0-1.8,0.7-2.1C39.5,67.3,39.7,67.8,39.8,68.3z"/>
</g>
      <g id="pepper-m">
	<path class="st15" d="M55.3,52.1c0.1-1,0.2-1.9,0.6-2.9c0.7-2.1-0.2-4.4-1.7-5.2c-1.5-0.7-2.7-0.4-3.9,1.1
		c-0.9,1.1-1.3,2.3-1.6,3.6c-0.1,0.5-0.1,0.9,0.5,0.9c1,0.1,0.8,0.8,0.6,1.5c-2.2,7.1-5.7,13.7-11.2,19.6c-0.8,0.9-1.9,1.5-3,2
		c-1.7,0.8-2.8,2.3-4.4,3.5c4.2,1.3,7.9,0.1,11.5-2.8c0-0.4,0.2-0.7,0.5-1c1.7-1.3,2.8-3.1,4.1-4.7c0.8-0.9,1-2.1,2.2-2.7l0,0
		c0.1-1.1,0.5-2.1,1.6-2.8l0,0c-0.1-0.5,0.1-0.8,0.6-1.1l0,0c-0.3-0.7,0.2-1.1,0.8-1.5l0,0C53,57.2,54,54.6,55.3,52.1z"/>
</g>
      <g id="onion-m">
	<path class="st16" d="M66.1,67.3c-0.2-0.6-0.2-1.2-0.3-1.8c-0.2-1.3-0.3-2.5-1.3-3.4c-0.2-0.2-0.4-0.6-0.4-0.9
		c-0.1-1-0.6-1.9-1.1-2.7c-0.3-0.5-0.4-1.1-0.7-1.6c-1.5-2.6-3.4-4.8-5.1-7.2c-0.2-0.2-0.4-0.4-0.4-0.7c-0.1-0.3-0.2-0.6-0.4-0.6
		c-0.8-0.1-1.2-0.8-1.7-1.3c-0.4-0.3-0.3-0.9-0.8-1.1c-0.7-0.3-0.7-0.7-0.3-1.2c0.2-0.3-0.1-0.6,0.1-0.8h-0.1
		c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.1-0.1-0.2,0-0.3l0,0c-0.4,0.3-1.2,0.2-1.1,1.1c0,0.1-0.3,0.1-0.4,0c-0.6-0.1-1.1-0.3-1.7-0.4
		c-0.4-0.1-0.9-0.3-0.8-0.9c0.1-0.5,0.5-0.9,1-0.8c0.5,0,0.9-0.1,1.4-0.2c-0.8-0.6-2-0.3-2.6,0.6c-0.2,0.3-0.3,0.6,0,0.9
		c0.6,0.6,1.3,1.1,1.9,1.6c0.1,0.1,0.3,0.2,0.3,0.3c0.3,0.2,0.6,0.5,0.9,0.9c0,0,0,0.1,0.1,0.1l0.1,0.1c0.9,1,2,1.8,2.9,2.9
		c1.3,1.5,2.7,2.9,3.8,4.5c0.9,1.3,1.7,2.8,2.5,4.2c1.1,1.9,1.6,4.1,2.2,6.2c0.6,2.1,0.5,4.3,0.4,6.5c-0.1,2.5-0.3,5-0.9,7.4
		c-0.7,3.1-1.6,6.2-2.8,9.2c-0.6,1.4-1.4,2.6-1.9,4.1c-0.4,1.1-1.1,2.1-1.5,3.2l0,0c0.5,0.4,0.6,0.8,0.1,1.5l0,0
		c0,0.1,0.1,0.3,0,0.4c-0.2,0.7,0.1,1,0.8,1.1c0,0,0,0,0-0.1c-0.5-1,0.4-1.7,0.4-2.6c0-0.2,0.3-0.1,0.4-0.1c0.3,0.1,0.4-0.2,0.6-0.3
		c0.2-0.2,0.1-0.4-0.1-0.5c-0.1,0-0.2-0.2,0-0.2c0.7-0.5,1-1.4,1.7-1.8c0,0,0.1,0,0.2,0.1c0,0,0,0,0-0.1c-0.1-0.1-0.2-0.3-0.3-0.4
		c-0.2-0.2-0.5-0.3-0.3-0.7c0.2-0.3,0.4-0.3,0.7-0.2c0.2,0.1,0.3-0.1,0.4-0.3c0.1-0.2,0.1-0.4-0.1-0.5c-0.2-0.1-0.3-0.2-0.4-0.4
		c0,0-0.1-0.1-0.1-0.2l0,0c0,0.1,0.1,0.1,0.1,0.2c0.5,0.2,0.5-0.6,1-0.5c0-0.2-0.1-0.3,0-0.5c0,0.2,0.1,0.4,0.1,0.5
		c0.6-0.3,0.4-0.9,0.3-1.3c-0.1-0.5,0.2-0.6,0.4-0.6c0.7,0,0.5-0.5,0.4-0.7c-0.6-0.6,0-1,0.2-1.5c0.2-0.3,0.9-0.6,0.4-1
		c-0.4-0.3-0.6,0.3-0.9,0.6c-0.1,0.1-0.1,0.2-0.3,0.1c0-0.4,0.3-0.6,0.4-0.8c0.7-1.1,0.5-2.3,0.9-3.4c0.6-2.1,0.6-4.3,1.6-6.3
		c0.3-0.5,0.1-1,0.1-1.5c0-0.7,0-1.3,0.3-1.9C66.9,69.9,66.5,68.6,66.1,67.3z"/>
        <path class="st17" d="M57.4,97c0-0.1,0-0.2,0-0.4c-0.3,0-0.5,0.2-0.4,0.5c0,0.1,0.2,0.4,0.2,0.4c-0.4,0.5-0.3,0.8,0,1.3
		c0.1,0.2-0.2,0.4-0.5,0.3c-0.4-0.1-0.7-0.2-0.9-0.6c-0.1,0.1-0.1,0.2-0.1,0.3c0.3,0.2,0.3,0.5,0.1,0.8c-0.1,0.2-0.3,0.4-0.6,0.2
		c-0.2,0.5-0.4,1-0.6,1.6c-0.1,0.4-0.1,0.7-0.2,1.1c0,0.2-0.2,0.6,0.1,0.7s0.6,0,0.8-0.3s0.6-0.5,0.7-0.9c0.1-0.4,0.8-0.3,0.6-0.9
		c-0.4,0.1-0.2-0.2-0.1-0.3c0.3-0.6,0.7-1.2,1.1-1.8c0.1-0.3,0.1-0.5,0.4-0.7c0-0.1,0.1-0.2,0.1-0.3C57.5,98,57.1,97.7,57.4,97z"/>
        <path class="st17" d="M53.2,43.7c-0.4,0.3-1.2,0.2-1.1,1.1c0,0.1-0.3,0.1-0.4,0c-0.6-0.1-1.1-0.3-1.7-0.4c-0.4-0.1-0.9-0.3-0.8-0.9
		c0.1-0.5,0.5-0.9,1-0.8c0.5,0,0.9-0.1,1.4-0.2c0.1,0,0.2,0,0.3,0.1C52.3,43,52.8,43.3,53.2,43.7z"/>
        <path class="st16" d="M57.2,95.1L57.2,95.1c-0.1,0-0.2,0.1-0.2,0.1c-0.3,0.6-0.5,1.3-0.7,1.9c0,0.1,0,0.3,0,0.4
		c0,0.2,0,0.3-0.2,0.3C56,98,56,98.2,55.9,98.5c0.2,0.4,0.5,0.5,0.9,0.6c0.3,0.1,0.6-0.1,0.5-0.3c-0.3-0.5-0.4-0.9,0-1.3
		c0,0-0.1-0.3-0.2-0.4c-0.1-0.3,0.1-0.5,0.4-0.5l0,0C57.1,96.1,57.6,95.6,57.2,95.1z"/>
        <path class="st18" d="M60.7,96c-0.1,0.2-0.2,0.4-0.4,0.4c-0.4-0.4-0.6,0-0.9,0.3c-0.3,0.3-0.3,1-1,0.9c0.8-1.5,1.7-3.1,2.7-4.5
		c0.4,0.4,0.5,0.8,0,1.2c-0.2,0.2-0.3,0.4-0.5,0.6C60.4,95.2,60.4,95.6,60.7,96z"/>
        <path class="st18" d="M64.5,90.8c-0.3,0.4-0.5,0.7-0.8,1.1c-0.5-0.3-1.1-0.6-1.1-1.3l0,0c0.2,0,0.2-0.1,0.2-0.2l0,0
		C63.5,90.1,64.2,90.3,64.5,90.8z"/>
        <path class="st18" d="M63.8,88.2c0.6,0.1,1,0.9,1.7,0.8c-0.2,0.3-0.2,0.8-0.6,1c-0.5-0.4-1-0.6-1.7-0.6
		C63.4,88.9,63.6,88.5,63.8,88.2z"/>
        <path class="st18" d="M61.9,91.7c0.3,0.4,0.9,0.6,1.2,1c-0.2,0.3-0.4,0.6-0.6,0.8c0-0.7-0.5-1-0.9-1.4C61.7,92,61.8,91.9,61.9,91.7
		z"/>
        <path class="st16" d="M55.5,99.1c-0.1,0.3-0.2,0.6-0.3,0.9c0.3,0.2,0.4,0,0.6-0.2c0.2-0.3,0.2-0.6-0.1-0.8
		C55.6,99,55.6,99,55.5,99.1z"/>
        <path class="st17" d="M61.9,91.7c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1-0.1-0.2-0.3-0.3-0.4
		c-0.2-0.2-0.5-0.3-0.3-0.7c0.2-0.3,0.4-0.3,0.7-0.2c0.2,0.1,0.3-0.1,0.4-0.3c0.1-0.2,0.1-0.4-0.1-0.5c-0.2-0.1-0.3-0.2-0.4-0.4l0,0
		c0.5,0.2,0.5-0.6,1-0.5l0,0c0.1,0.1,0.1,0.1,0.1,0l0,0c0.6-0.3,0.4-0.9,0.3-1.3c-0.1-0.5,0.2-0.6,0.4-0.6c0.7,0,0.5-0.5,0.4-0.7
		c-0.6-0.6,0-1,0.2-1.5c0.2-0.3,0.9-0.6,0.4-1c-0.4-0.3-0.6,0.3-0.9,0.6c-0.1,0.1-0.1,0.2-0.3,0.1c0-0.4,0.3-0.6,0.4-0.8
		c0.7-1.1,0.5-2.3,0.9-3.4c0.6-2.1,0.6-4.3,1.6-6.3c0.3-0.5,0.1-1,0.1-1.5c0-0.7,0-1.3,0.3-1.9c0.7-1.4,0.3-2.7-0.1-4
		C66,66.6,66,66,65.9,65.4c-0.2-1.3-0.3-2.5-1.3-3.4c-0.2-0.2-0.4-0.6-0.4-0.9c-0.1-1-0.6-1.9-1.1-2.7c-0.3-0.5-0.4-1.1-0.7-1.6
		c-1.5-2.6-3.4-4.8-5.1-7.2c-0.2-0.2-0.4-0.4-0.4-0.7c-0.1-0.3-0.2-0.6-0.4-0.6c-0.8-0.1-1.2-0.8-1.7-1.3c-0.4-0.3-0.3-0.9-0.8-1.1
		c-0.7-0.3-0.7-0.7-0.3-1.2c0.2-0.3-0.1-0.6,0.1-0.8c0.2,0,0.3,0.3,0.5,0.3c3.6,2.8,6.9,6,9.6,9.7c2.8,3.9,4.8,8.1,5.2,13
		c0.3,3.3-0.1,6.5-0.8,9.8c-0.6,3-1.7,5.8-2.9,8.5c-0.4,0.9-0.9,1.9-1.3,2.8c-0.3,0.3-0.5,0.7-0.5,1.2c-0.3,0.3-0.5,0.6-0.6,1l0,0
		c-0.1,0.1-0.1,0.2-0.2,0.2l0,0C62.3,91,62.1,91.4,61.9,91.7z M61.1,91.6L61.1,91.6C61.1,91.7,61.1,91.7,61.1,91.6
		c0.1,0.1,0.2,0.1,0.2,0C61.2,91.6,61.2,91.6,61.1,91.6z"/>
        <path class="st17" d="M58.5,97.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.5-1,0.4-1.7,0.4-2.6c0-0.2,0.3-0.1,0.4-0.1c0.3,0.1,0.4-0.2,0.6-0.3
		c0.2-0.2,0.1-0.4-0.1-0.5c-0.1,0-0.2-0.2,0-0.2c0.7-0.5,1-1.4,1.7-1.8c0,0,0.1,0,0.2,0.1c-0.1,0.2-0.1,0.3-0.2,0.5
		C60.2,94.5,59.3,96,58.5,97.6z"/>
        <path class="st17" d="M57.3,96.6c-0.2-0.5,0.3-1-0.1-1.5C57.8,95.5,57.8,95.9,57.3,96.6z"/>
        <path class="st17" d="M62.3,89.6c0-0.2-0.1-0.3,0-0.5C62.3,89.3,62.4,89.5,62.3,89.6L62.3,89.6C62.4,89.6,62.4,89.6,62.3,89.6
		L62.3,89.6z"/>
        <path class="st17" d="M53.2,43.7c0.1,0,0.2,0.1,0.3,0.2c-0.1,0-0.2,0.1-0.2,0.1C53.1,44,53.2,43.8,53.2,43.7z"/>
        <path class="st17" d="M61.3,90.1c0,0-0.1-0.1-0.1-0.2l0,0C61.3,90,61.3,90,61.3,90.1C61.4,90.1,61.3,90.1,61.3,90.1z"/>
        <path class="st18" d="M58.4,98.9c-0.2,0.2-0.4,0.3-0.7,0.1c0.1-0.3,0.1-0.5,0.4-0.7C58.2,98.6,58.3,98.8,58.4,98.9z"/>
        <g>
		<path class="st18" d="M70,61.3c-1.1-2.5-2.5-4.8-4.1-7c-3.2-4.2-7-7.7-11.5-10.4l0,0c-0.9-0.5-1.7-1-2.6-1.3
			c0.4,0.4,0.9,0.7,1.3,1.1l0,0c0.1,0,0.2,0.1,0.3,0.2h0.1c0.2,0,0.3,0.3,0.5,0.3l0,0c3.6,2.8,6.9,6,9.6,9.7c2.8,3.9,4.8,8.1,5.2,13
			c0.3,3.3-0.1,6.5-0.8,9.8c-0.6,3-1.7,5.8-2.9,8.5c0.4,0.2,0.6,0.5,0.5,0.9c0.1-0.4-0.1-0.6-0.5-0.9c-0.4,0.9-0.9,1.9-1.3,2.8
			c0.6,0.1,1,0.9,1.7,0.8c0.1,0,0.2-0.1,0.2-0.2s0.1-0.1,0.1-0.2h0.1v-0.1c0-0.1,0.1-0.1,0.1-0.2c3.1-5.2,5.1-10.7,5.6-16.8
			C71.9,68,71.4,64.6,70,61.3z"/>
          <path class="st18" d="M61.4,92.5C61.4,92.5,61.4,92.6,61.4,92.5c-0.1,0.2-0.2,0.4-0.2,0.6c0.4,0.4,0.5,0.8,0,1.2
			c-0.2,0.2-0.3,0.4-0.5,0.6c-0.4,0.4-0.3,0.7,0,1.1c0.6-0.8,1.2-1.6,1.8-2.4c0-0.7-0.5-1-0.9-1.4C61.5,92.3,61.5,92.4,61.4,92.5z"
          />
          <path class="st18" d="M59.5,96.7c-0.3,0.3-0.3,1-1,0.9c-0.1,0.2-0.2,0.3-0.3,0.5v0.1c0,0.1-0.1,0.2-0.1,0.3
			c0.1,0.2,0.2,0.4,0.3,0.5c-0.2,0.2-0.4,0.3-0.7,0.1c-0.4,0.6-0.7,1.2-1.1,1.8c-0.1,0.1-0.2,0.4,0.1,0.3c0.5-0.8,1.1-1.5,1.6-2.3
			c0.6-0.9,1.3-1.7,1.9-2.6C59.9,96,59.7,96.4,59.5,96.7z"/>
          <path class="st18" d="M63.3,89.3c-0.3,0.3-0.5,0.6-0.6,1c0.7-0.2,1.4-0.1,1.7,0.4c0.2-0.3,0.3-0.6,0.5-0.9
			C64.5,89.5,64,89.3,63.3,89.3z"/>
          <path class="st18" d="M62.5,90.6c-0.2,0.4-0.4,0.7-0.6,1.1c0.3,0.4,0.9,0.6,1.2,1c0.2-0.3,0.4-0.6,0.6-0.8
			C63.2,91.6,62.6,91.4,62.5,90.6z"/>
          <path class="st18" d="M62.7,90.3c-0.1,0.1-0.1,0.2-0.2,0.2C62.8,90.6,62.8,90.5,62.7,90.3z"/>
	</g>
        <path class="st16" d="M61.1,91.6C61.1,91.6,61.2,91.6,61.1,91.6v0.2H61C61.1,91.7,61.1,91.6,61.1,91.6z"/>
        <path class="st16" d="M62.4,89.6L62.4,89.6C62.5,89.7,62.4,89.7,62.4,89.6z"/>
</g>
</svg>


  </div>
</template>

<script>
import { gsap } from 'gsap';

export default {

  name: 'Logo',
  props: {
    animated: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    animateLetters() {

      const tl = gsap.timeline();
      const duration = 0.3;
      const gap = `>-${duration-0.1}`;
      const options = {
        x: 0,
        y: -300,
        // z: -1000,
        scale: 3,
        opacity: 0,
        duration: duration,
        ease: "back.out(1.3)",
        // rotation: gsap.utils.random(8 * -360, 8 * 360, true),
      }

      tl.from("#chili-m", {
        ...options
      });
      tl.from("#jalepeno-m", {
        ...options
      }, gap);
      tl.from("#pepper-m", {
        ...options
      }, gap);
      tl.from("#onion-m", {
        ...options
      }, gap);
      tl.from("#cucumber-p", {
        ...options
      }, gap);
      tl.from("#pepper-p", {
        ...options
      }, gap);
      tl.from("#bread-i", {
        ...options
      }, gap);
      tl.from("#chili-s", {
        ...options
      }, gap);
      tl.from("#asparagus-h", {
        ...options
      }, gap);
      tl.from("#eggplant-h", {
        ...options
      }, gap);
      tl.from("#carrot-i", {
        ...options
      }, gap);


      // const letters = gsap.utils.toArray('#logo > g');
      // const randomDistanceX = gsap.utils.random([-1000, 1000], true);
      // const randomDistanceY = gsap.utils.random([-1000], true);
      //
      // this.ctx = gsap.context(() => {
      //   gsap.from(letters, {
      //     x: randomDistanceX,
      //     y: randomDistanceY,
      //     rotation: gsap.utils.random(8 * -360, 8 * 360, true),
      //     duration: 1,
      //     ease: "power1",
      //     opacity: 0,
      //     scale: 6,
      //   });
      // });

    },
  },
  mounted() {
    if (this.animated) {
      this.animateLetters()
    }
  },
  unmounted() {
    this.ctx.revert();
  }

}
</script>

<style lang="scss" scoped>
.st0{fill:#F27E23;}
.st1{fill:#84C447;}
.st2{fill:#F8ED9E;}
.st3{fill:#56B949;}
.st4{fill:#009B4C;}
.st5{fill:#9B4198;}
.st6{fill:#F4F5F5;}
.st7{fill:#ED1F25;}
.st8{fill:#114220;}
.st9{fill:#F38C22;}
.st10{fill:#E17B29;}
.st11{fill:#FAAE2B;}
.st12{fill:#1DB14D;}
.st13{fill:#EB2026;}
.st14{fill:#00AB4E;}
.st15{fill:#E52327;}
.st16{fill:#EBE1E4;}
.st17{fill:#EDCBD9;}
.st18{fill:#891B3E;}

svg {
  position: relative;
  display: block;
  height: 60px;
  //width: 290px;
  text-align: center;
  overflow: visible;
  transform-origin: center !important;

  * {
    transform-box: fill-box;
  }

  @media (min-width: 992px) {
    height: 120px;
  }

  path {
    transform-origin: center !important;
  }

  g {
    transform-origin: center !important;
  }
}

</style>