<template>
  <b-modal class="modal" id="add-meal-plan-modal" centered tabindex="-1" @ok="saveMealPlan" @cancel="cancel"
           title="Add a meal plan">
    <form class="col-12">
      <div class="form-group m-2">
        <label for="name">Title</label>
        <b-form-input v-model="plan.title" placeholder="Enter title"></b-form-input>
      </div>
      <!--      <div class="form-group m-2">-->
      <!--        <label for="name">Image</label>-->
      <!--        <b-form-file-->
      <!--            accept="image/*"-->
      <!--            v-model="plan.image"-->
      <!--            :state="Boolean(plan.image)"-->
      <!--            placeholder="Choose a file"-->
      <!--        ></b-form-file>-->
      <!--      </div>-->
      <div class="form-group m-2">
        <label for="name">Description</label>
        <b-form-textarea v-model="plan.description" placeholder="Enter description"></b-form-textarea>
      </div>
      <div class="form-group m-2">
        <label for="name">Calorie amount</label>
        <b-form-input v-model="plan.calories" placeholder="Enter start date" type="number"></b-form-input>
      </div>
      <div class="form-group m-2">
        <label for="name">Start date</label>
        <b-form-input v-model="plan.startDate" placeholder="Enter start date" type="date"></b-form-input>
      </div>

      <div class="form-group m-2">
        <label for="name">End date</label>
        <b-form-input v-model="plan.endDate" placeholder="Enter end date" type="date"></b-form-input>
      </div>
      <div class="form-group m-2">
        <b-form-checkbox v-model="plan.isPublic" name="check-button" switch>
          Make meal plan public?
        </b-form-checkbox>
      </div>
    </form>
  </b-modal>
</template>

<script>
export default {
  name: "AddMealPlanModal",
  props: {
    propMealPlan: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      plan: {
        title: "",
        image: "",
        calories: 200,
        description: "",
        startDate: "",
        endDate: "",
        isPublic: false
      }
    };
  },
  mounted() {
    if (this.propMealPlan) {
      this.plan = this.propMealPlan;
    }
  },
  watch: {
    propMealPlan: {
      handler: function () {
        this.plan = this.propMealPlan;
      },
      deep: true
    }
  },
  methods: {
    saveMealPlan() {
      this.$emit("add-meal-plan", this.plan);
    },
    cancel() {
      this.plan = {
        title: "",
        image: "",
        calories: 200,
        description: "",
        startDate: "",
        endDate: "",
        isPublic: false
      };
    }
  }
}
</script>
<style lang="scss">
#add-meal-plan-modal {
  .modal-content {
    background-color: #ffffff !important;
  }
}
</style>
<style scoped>

</style>
