<template>
  <div>
    <div class="container-xl">
      <div class="row">
        <div class="col-12">
          <div class="product-item-title d-flex">
            <div
                class="product-item-title-inner bg-lightwood p-2 me-auto border-radius-25"
                data-aos="fade-right"
            >
              <h2 class="section-heading mb-0">
                <span class="section-heading-upper">More, groups,  together</span>
                <span class="section-heading-lower">Collections</span>
              </h2>
            </div>
          </div>
        </div>
        <div class="my-3">
          <b-alert variant="danger" :show="collections.length<=0" dismissible>
            No collections found.
          </b-alert>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <collections-group
                    :prop-collections="collections"
                    :prop-is-admin="false"
                    v-if="collections.length > 0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollectionsGroup from "@/components/CollectionsGroup.vue";

export default {
  name: "CollectionsView",
  components: {CollectionsGroup},
  data() {
    return {
      collections: [],
      renderKey: 0,
      render: true,
      isAdmin: true
    }
  },
  mounted() {
    this.getCollections()
  },
  methods: {
    async getCollections() {
      this.collections = await this.$store.dispatch('collection/getCollections')
    }
  }
}
</script>

<style scoped>

</style>