import {child, get, ref, set, update} from "firebase/database";
import {database} from "../../../firebase.config";
import RecipeModel from "./RecipeModel";
import {DatabaseDAO} from "@/services/base/DatabaseDAO";

/**
 * @name RecipeDao
 * @description Data Access Object for Recipes
 * @class
 * @public
 */

class RecipeDao extends DatabaseDAO {

    /**
     * documentUrl is database url that is set to `recipes`
     * @type {string}
     * @public
     */

    documentUrl = `recipes/${process.env.VUE_APP_ENVIRONMENT}`


    /**
     * Add Recipe.
     *
     * @param {object} recipe  recipe to add
     * @return {object} added recipe
     */
    addRecipe(recipe) {
        const recipeModel = new RecipeModel(recipe.title, recipe.category, recipe.description, recipe.creatorEmail);
        const recipeUrl = `${this.documentUrl}/${recipeModel.id}`;
        const mergedRecipe = {...recipeModel, ...recipe};

        return set(ref(database, recipeUrl), mergedRecipe);
    }

    /**
     * Update recipe
     *
     * @param {string} recipeId The recipeId to update.
     * @param {object} recipe The recipe data to update.
     * @return {object} updated recipe.
     */
    updateRecipe(recipeId, recipe) {
        const recipeUrl = `${this.documentUrl}/${recipeId}`;

        const updates = {};
        updates[recipeUrl] = {...recipe};

        return update(ref(database), updates);
    }

    /**
     * Delete recipe
     *
     * @param {string} recipeId The recipeId to update.
     * @return {object} deleted recipe.
     */
    deleteRecipe(recipeId) {
        return this.updateRecipe(recipeId, null);
    }

    /**
     * Get recipe
     *
     * @param {string} recipeId The recipeId to update.
     * @return {promise} selected recipe.
     */
    getRecipe(recipeId) {
        const dbRef = ref(database);
        const recipeUrl = `${this.documentUrl}/${recipeId}`;

        return get(child(dbRef, recipeUrl)).then((snapshot) => {
            if (snapshot.exists()) {
                return snapshot.val();
            } else {
                return {};
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    /**
     * Get all recipes
     *
     * @return {promise} all recipes.
     */
    getRecipes() {
        const dbRef = ref(database);
        const recipeUrl = `${this.documentUrl}`;

        return get(child(dbRef, recipeUrl)).then((snapshot) => {
            if (snapshot.exists()) {
                return snapshot.val();
            } else {
                return [];
            }
        }).catch((error) => {
            console.error(error);
        });
    }

}

export default new RecipeDao;
