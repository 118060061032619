<template>
  <div>
    <base-section-loader v-if="!recipes.length"/>
    <carousel v-if="recipes.length >0" :recipes="recipes"/>
    <category-preview
        title="Latest Recipes"
        :recipes="latestRecipes"
    />
    <category-preview
        title="Super Quick Meals"
        :recipes="quickestRecipes"
    />
  </div>
</template>
<script>

import Carousel from "@/components/Carousel";
import CategoryPreview from "@/components/CategoryPreview";
import BaseSectionLoader from "@/components/bases/BaseSectionLoader";
import RecipeService from "@/services/recipe/RecipeService";

export default {
  name: 'Home',
  components: {
    BaseSectionLoader,
    CategoryPreview,
    Carousel,
  },
  data() {
    return {
      renderKey: 0,
      categoryLimits: 4,
      latestRecipes: [],
      recipes: [],
      quickestRecipes: [],
    }
  },
  mounted() {
    this.getRecipes();
    this.getLatestRecipes();
    this.getQuickestRecipes();
  },
  watch: {
    recipes() {
      this.renderKey++
    }
  },
  methods: {
    async getRecipes() {
      const recipes = await RecipeService.getRecipes(true);
      this.recipes = recipes;
      this.$store.commit("setRecipes", recipes);
    },
    async getLatestRecipes() {
      const recipes = await RecipeService.getLatestRecipes(this.categoryLimits);
      this.latestRecipes = recipes;
    },
    async getQuickestRecipes() {
      const recipes = await RecipeService.getQuickestRecipes(this.categoryLimits);
      this.quickestRecipes = recipes;
    }
  },
}

</script>
<style lang="scss" scoped>
</style>
