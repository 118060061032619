<template>
  <div>
    <slot name="header"></slot>
    <div class="container document-body">
      <slot name="body"></slot>
      <pulse-loader
          :loading="isLoading"
          :color="loader.color"
          :size="loader.size"
      />
      <slot name="navigation"></slot>
    </div>
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "LayoutDocument",
  components: {
    PulseLoader,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loader: {
        loading: false,
        color: "orange",
        size: "50px",
      },
    }
  }
};
</script>
<style lang="scss" scoped>
.document-body {
  padding-top: 80px;

   > *:first-of-type {
     margin-top: 0;
     padding-top: 0;
   }
}
</style>