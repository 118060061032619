import GalleryDao from "./GalleryDao";
import {BucketDAO} from "../base/BucketDAO";
import {getImageKitUrl} from "@/utils/ImageKitUtil";

/**
 * @name GalleryService
 * @description Service to manage Item
 * @class
 * @public
 */

class GalleryService {

    /**
     * Add Item.
     *
     * @param {object} item  item to add
     * @return {object} added item
     */
    addItem(item) {
        return GalleryDao.addItem(item);
    }

    /**
     * Update item
     *
     * @param {string} itemId The itemId to update.
     * @param {object} item The item data to update.
     * @return {object} updated item.
     */
    async updateItem(itemId, item) {
        const dbItem = await this.getItem(itemId);
        const updatedItem = {...dbItem, ...item};

        return GalleryDao.updateItem(itemId, updatedItem);
    }

    /**
     * Delete item
     *
     * @param {object} item The item to delete.
     * @return {object} deleted item.
     */
    async deleteItem(item) {
        await this.deleteItemFile(item.filePath);

        return GalleryDao.deleteItem(item.id);
    }

    /**
     * Delete item file
     *
     * @param {string} filePath The item to delete.
     * @return {promise} deleted item.
     */
    async deleteItemFile(filePath) {
        const bucketDao = new BucketDAO();

        return bucketDao.deleteFile(filePath);
    }

    /**
     * Get item
     *
     * @param {string} itemId The itemId to update.
     * @return {promise} selected item.
     */
    getItem(itemId) {
        return GalleryDao.getItem(itemId);
    }

    /**
     * Get all items
     *
     * @return {promise} all items.
     */
    async getItems() {
        return Object.values(await GalleryDao.getItems());
    }

    /**
     * Get all items by creator
     *
     * @param {string} creatorEmail The creatorEmail to filter by.
     * @return {promise} all items by creatorEmail.
     */
    async getItemsByCreator(creatorEmail) {
        const items = Object.values(await this.getItems());
        return Object.values(items.filter(item => item.creatorEmail === creatorEmail));
    }

    /**
     * Upload item file
     *
     * @param {string} file The file to upload.
     * @param {string} filePath The filePath to upload to.
     * @return {promise} uploaded itemRef and itemUrl.
     */
    async uploadItemFile(file, filePath) {
        const bucketDao = new BucketDAO();
        const uploadedFile = await bucketDao.addFile(file, filePath);

        return this.getItemDownloadURL(uploadedFile.ref);
    }

    /**
     * Get item downloadURL
     *
     * @param {object} itemRef The itemRef to uploadItem.
     * @return {promise} uploaded itemUrl.
     */
    async getItemDownloadURL(itemRef){
        const bucketDao = new BucketDAO();

        return bucketDao.getAuthorisedDownloadURL(itemRef);
    }

    /**
     * Get ImageKit Url
     *
     * @param {string} path The image path
     * @param {object} dimensions The image width and height dimensions
     * @return {string} imageKitUrl.
     */
    getImageKitUrl(path, dimensions) {
        return getImageKitUrl(path, dimensions);
    }
}

export default new GalleryService;
