import PodcastModel from "@/services/podcast/PodcastModel";
import PodcastChapterModel from "@/services/podcast/PodcastChapterModel";
import PodcastDao from "@/services/podcast/PodcastDao";
import {getImageKitUrl} from "@/utils/ImageKitUtil";
import {BucketDAO} from "@/services/base/BucketDAO";
import {uuid} from "@tinymce/tinymce-vue/lib/es2015/main/ts/Utils";

/**
 * @name PodcastService
 * @description Service to manage Podcasts
 * @class
 * @public
 */

class PodcastService {
    constructor() {
        this.recordUrl = "podcasts";
    }

    /**
     * Upload Podcast File
     *
     * @param {object} podcast The file to be uploaded and upload path
     * @return {object} path and url of uploaded file.
     */
    async uploadFile(podcast) {
        let filename = uuid(podcast.file.name)
        const filePath = `${podcast.path}/${filename}`;
        let bucket= new BucketDAO()
        await bucket.addFile(podcast.file, filePath);
        let url = await bucket.getDownloadURL(filePath);
        return {'path': filePath, 'url': url};
    }

    /**
     * Get downloadURL
     *
     * @param {object} fileRef The fileRef to uploaded file.
     * @return {promise} download URL.
     */
    async getDownloadURL(fileRef) {
        return new BucketDAO().getAuthorisedDownloadURL(fileRef);
    }

    /**
     * Delete file
     *
     * @param {string} filePath The filePath to delete.
     * @return {promise} deleted file status.
     */
    async deleteFile(filePath) {
        return new BucketDAO().deleteFile(filePath);
    }

    /**
     * Add Podcast
     *
     * @param {object} podcast The podcast object
     * @return {object} added podcast.
     */
    async addPodcast(podcast) {
        const newPodcast = new PodcastModel(podcast.title, podcast.anchor, podcast.note, podcast.tags, podcast.email, podcast.coverPath, podcast.cover);
        await PodcastDao.addRecord(newPodcast, this.recordUrl);
        return newPodcast;
    }

    /**
     * Add Podcast Chapter
     *
     * @param {string} podcastId The podcast string
     * @param {object} podcastChapter The podcast chapter object
     * @return {object} The updated podcast with added podcast chapter
     */
    async addPodcastChapter(podcastId, podcastChapter) {
        const podcast = await this.getPodcast(podcastId);
        const hasChapters = ('chapters' in podcast);
        const newChapter = {...new PodcastChapterModel(podcastChapter.title, podcastChapter.note, podcastChapter.category, podcastChapter.feedPath, podcastChapter.feed, podcastChapter.isFree), ...podcastChapter};
        if (!hasChapters) {
            podcast['chapters'] = [];
        }
        podcast['chapters'].push(newChapter);
        await this.updatePodcast(podcastId, podcast);

        return newChapter;
    }

    /**
     * Get Podcast
     *
     * @param {string} podcastId The podcast string
     * @return {object} The podcast object
     */
    async getPodcast(podcastId) {
        return PodcastDao.getRecord(podcastId, this.recordUrl);
    }

    /**
     * Get Podcast Chapter
     *
     * @param {string} podcastId The podcast string
     * @param {string} podcastChapterId The podcast chapter string
     * @return {object} The podcast chapter object
     */
    async getPodcastChapter(podcastId, podcastChapterId) {
        const podcastChapters = await this.getPodcastChapters(podcastId);
        return podcastChapters.find(podcastChapter => podcastChapter.id === podcastChapterId);
    }

    /**
     * Update Podcast
     *
     * @param {string} podcastId The podcast string
     * @param {object} updates The podcast update object
     * @return {object} The updated podcast object
     */
    async updatePodcast(podcastId, updates) {
        const podcast = await this.getPodcast(podcastId);
        const updatedPodcast = {...podcast, ...updates};
        return PodcastDao.updateRecord(podcastId, this.recordUrl, updatedPodcast);
    }


    /**
     * Update Podcast Chapter
     *
     * @param {string} podcastId The podcast string
     * @param {string} podcastChapterId The podcast chapter string
     * @param {object} updates The podcast update object
     * @return {object} The updated podcast chapter object
     */
    async updatePodcastChapter(podcastId, podcastChapterId, updates) {
        let podcast = await this.getPodcast(podcastId);
        podcast.chapters = podcast.chapters.map(podcastChapter => {
            if (podcastChapter.id === podcastChapterId) {
                return {...podcastChapter, ...updates};
            }
            return podcastChapter;
        })
        return this.updatePodcast(podcastId, podcast);
    }

    /**
     * Get Podcasts
     *
     * @return {object} The array of podcast objects
     */
    async getPodcasts() {
        return Object.values(await PodcastDao.getRecords(this.recordUrl));
    }

    /**
     * Get Podcast Chapters
     *
     * @param {string} podcastId The podcast string
     * @return {object} The array of podcast chapter objects
     */
    async getPodcastChapters(podcastId) {
        const podcast = await this.getPodcast(podcastId);
        const hasChapters = ("chapters" in podcast);
        let chapters = [];
        if (hasChapters) {
            chapters = podcast["chapters"];
        }
        return Object.values(chapters);
    }

    /**
     * Get Podcast Published Chapters
     *
     * @param {string} podcastId The podcast string
     * @return {object} The array of podcast published chapters
     */
    async getPodcastPublishedChapters(podcastId) {
        const chapters = await this.getPodcastChapters(podcastId);
        return chapters.filter((chapter) => chapter.published === true);
    }

    /**
     * Delete Podcast
     *
     * @param {string} podcastId The podcast string
     * @return {object} The deleted podcast object
     */
    async deletePodcast(podcastId) {
        const podcast = await this.getPodcast(podcastId);

        await this.deleteFile(podcast.coverPath);

        return PodcastDao.deleteRecord(podcastId, this.recordUrl);
    }

    /**
     * Delete Podcast Chapter
     *
     * @param {string} podcastId The podcast string
     * @param {string} podcastChapterId The podcast chapter  string
     * @return {object} The deleted podcast chapter object
     */
    async deletePodcastChapter(podcastId, podcastChapterId) {
        let podcast = await this.getPodcast(podcastId);
        let podcastChapters = await this.getPodcastChapters(podcastId);
        let chapter = podcastChapters.find(podcastChapter => podcastChapter.id === podcastChapterId);

        await this.deleteFile(chapter.feedPath);
        podcast['chapters'] = podcastChapters.filter(podcastChapter => podcastChapter.id !== podcastChapterId);

        return this.updatePodcast(podcastId, podcast);
    }

    /**
     * Get Podcasts By Email
     *
     * @param {string} email The podcast creator email
     * @return {object} The array of podcast objects filtered by email
     */
    async getPodcastsByEmail(email) {
        const podcasts = await this.getPodcasts();
        return podcasts.filter((podcast) => podcast.email === email);
    }

    /**
     * Get Published Podcasts and All Chapters
     *
     * @return {object} The array of published podcast objects and all chapters
     */
    async getPublishedPodcasts() {
        const podcasts = await this.getPodcasts();
        return podcasts.filter((podcast) => podcast.published === true);
    }

    /**
     * Get ImageKit Url
     *
     * @param {string} path The image path
     * @param {object} dimensions The image width and height dimensions
     * @return {string} imageKitUrl.
     */
    getImageKitUrl(path, dimensions) {
        return getImageKitUrl(path, dimensions);
    }
}

export default new PodcastService();
