<template>
  <div>
    <b-modal id="add-recipe-to-meal-plan" centered title="Add recipe to meal plan" class="modal"
             @ok="saveRecipeToMealPlan">
      <form class="col-12">
        <div class="form-group m-2">
          <label for="name">Select recipe</label>
          <b-form-select v-model="selectedRecipe" :options="recipeOptions"></b-form-select>
        </div>
        <div class="form-group m-2">
          <label for="name">Select day</label>
          <b-form-select v-model="selectedDay" :options="dayOptions" class="rounded-1"></b-form-select>
        </div>
        <div class="form-group m-2">
          <label for="name">Select meal type</label>
          <b-form-select v-model="selectedMealType" :options="mealTypeOptions"></b-form-select>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "AddRecipeToMealPlanModal",
  props: {
    recipes: {
      type: Array,
      required: true
    },
    mealPlan: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedDay: null,
      selectedMealType: null,
      selectedRecipe: null,
      dayOptions: [
        {value: null, text: 'Please select'},
        {value: 'Monday', text: 'Monday'},
        {value: 'Tuesday', text: 'Tuesday'},
        {value: 'Wednesday', text: 'Wednesday'},
        {value: 'Thursday', text: 'Thursday'},
        {value: 'Friday', text: 'Friday'},
        {value: 'Saturday', text: 'Saturday'},
        {value: 'Sunday', text: 'Sunday'}
      ],
      mealTypeOptions: [
        {value: null, text: 'Please select'},
        {value: 'breakfast', text: 'Breakfast'},
        {value: 'lunch', text: 'Lunch'},
        {value: 'dinner', text: 'Dinner'},

      ],
      recipeOptions: []
    };
  },
  methods: {
    validateRecipeDetails() {
      if (this.selectedDay === null || this.selectedMealType === null || this.selectedRecipe === null) {
        return false;
      }
      return true;
    },
    saveRecipeToMealPlan() {
      if (this.validateRecipeDetails()) {
        this.$emit('save-recipe-to-meal-plan', {
          "mealplan": this.mealPlan,
          "recipe": this.selectedRecipe,
          "day": this.selectedDay,
          "type": this.selectedMealType
        });
      } else {
        this.$toast.error("You must select all fields")
      }

    }
  },
  watch: {
    recipes: function (newVal) {
      this.recipeOptions = [
        {value: null, text: 'Please select one'},
        ...newVal.map(recipe => {
          return {value: recipe, text: recipe.title}
        })
      ]
    }
  }
}
</script>
<style lang="scss">
#add-recipe-to-meal-plan {
  .modal-content {
    background-color: #ffffff !important;
  }
}
</style>
<style scoped>
.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2px;
}

.custom-select {
  width: 100%;
  padding: 5px;
}

</style>
