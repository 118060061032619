<template :key="renderKey.related">
  <div class="photo-detail page">
    <div class="container-xl">
      <base-loader v-if="loading"/>
      <div v-if="!loading" class="photo-header">
        <b-carousel
            id="carousel-1"
            :interval="4000"
            indicators
            background="#ababab"
            img-width="1024"
            img-height="480"
            style="text-shadow: 1px 1px 2px #333;"
        >
          <b-carousel-slide
              :caption=image.title
              :text=image.title
              :img-src=image.url
          ></b-carousel-slide>
        </b-carousel>
      </div>
      <div>
        <h1 class="text-center">Related images</h1>
      </div>
      <div class="d-flex justify-content-between mt-5 mb-5 bg-dark flex-wrap">
        <b-img class="m-1" thumbnail rounded width="150px" height="100px" :src="image.url"
               v-for="image in images.slice(this.startIndex, this.startIndex+6)" :key="image.id"
               @click="handleImageClick(image)"></b-img>
      </div>

    </div>
  </div>
</template>
<script>
import GalleryService from "@/services/gallery/GalleryService";
import BaseLoader from "@/components/bases/BaseLoader";

export default {
  name: "ImagePage",
  components: {
    BaseLoader,
  },
  data() {
    return {
      index: 0,
      imageId: null,
      image: {
        title: '',
        description: '',
        secure_url: '',
        likes: []
      },
      images: [],
      items: [],
      hasMoreInfo: false,
      hasSharing: false,
      shareUrl: null,
      updatedShareUrl: null,
      startIndex: 0,
      renderKey: {
        sharing: 0,
        related: 0
      },

      showInfoModal: false,
      showShareModal: false,
      loading: true,
    }
  },
  created() {
    window.scrollTo(0, 0);
  },
  async mounted() {
    this.images = await this.getItems();
    this.imageId = this.getImageId();
    await this.loadImageAndDetails(this.imageId);
    this.startIndex = Math.floor(Math.random() * (this.images.length - 6));
  },
  methods: {
    handleImageClick(image) {
      this.$router.push({name: 'image', params: {imageId: image.id}});
      this.image = image;
      this.startIndex = Math.floor(Math.random() * (this.images.length - 6));
    },
    async getItems() {
      return GalleryService.getItems();
    },
    getImage(item) {
      return item.url
    },
    getImageId() {
      let imageId = this.$route.params.imageId;
      if (imageId) {
        return imageId;
      }
      return this.images[this.index].id;
    },
    async loadImageAndDetails(imageId) {
      window.scrollTo(0, 0);
      this.loading = true;
      this.image = await GalleryService.getItem(imageId);
      this.loading = false;
    },
    closeInfoModal() {
      this.showInfoModal = false;
    },
    closeShareModal() {
      this.showShareModal = false;
    },
  }
}
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 819px) {
  .hidden-md-down {
    display: none;
  }
}

.photo-header {
  display: flex;
  justify-content: center;
  padding: .5rem 0;

  @media (min-width: 768px) {
    padding: 1.5rem 0;
  }

  &.responsive {
    display: block;

    > div {
      margin: 0 0 .5rem;
    }

    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;

      > div {
        margin: 0;
      }
    }
  }

  p {
    color: #6c757d;
    font-size: .875rem;

    @media (min-width: 768px) {
      font-size: 1rem;
    }
  }

}

.information {
  overflow: hidden;
  max-width: 600px;
  margin: .5rem auto;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.1);

  p {
    white-space: normal;
    font-size: .9em;
  }

  .desc {
    font-weight: 700;
    color: #fdc60d;
  }
}

.actions {
  position: relative;
  margin: 0 auto;
}

.tooltip-wrapper {
  display: inline-block;
}

.tooltip-wrapper.disabled {
  cursor: not-allowed;
}

.related {
  margin: 1.5rem 0 0;

  @media (min-width: 768px) {
    margin: 2rem 0 0;
  }
}
</style>
