<template>
  <layout-admin-add-edit>
    <template #header>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">Spices</li>
          <li class="breadcrumb-item active" aria-current="page">{{ mode }} spice</li>
        </ol>
      </nav>
    </template>
    <template #body>
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <form class="text-start col-lg-6 col-md-8 col-sm-12 m-auto border rounded p-4" onsubmit="return false">
            <base-input placeholder="Spice name" type="text" label="Spice name" v-model="spice.name"
                        :model-value="spice.name"/>
            <base-text-area label="Spice description" placeholder="Spice description" rows="3" cols="50"
                            v-model="spice.description" :model-value="spice.description"/>
            <base-input placeholder="Spice price" type="number" label="Spice price" v-model="spice.price"
                        :model-value="spice.price || 0"/>
            <multi-select label="Spice category" data-set="category" @handleInput="handleInput"
                          v-model="spice.category" :pre-selected-values="spice.category"/>
            <multi-select label="Spice tags" data-set="tags" @handleInput="handleInput"
                          v-model="spice.tags" :pre-selected-values="spice.tags"/>
            <div class="mb-3">
              <label class="form-label">Images</label>
              <multi-image-upload data-set="images" :storage-path="storagePath" @handleUpload="handleUpload"
                                  :uploadedImages="spice.images" @upload-success="uploadImageSuccess"/>
            </div>
            <button :disabled="!uploadComplete" @click="save" class="mb-3 btn btn-primary">Save</button>
          </form>
        </div>
      </div>
    </template>
  </layout-admin-add-edit>
</template>

<script>
import BaseInput from "@/components/bases/BaseInput.vue";
import MultiImageUpload from "@/components/MultiImageUpload.vue";
import BaseTextArea from "@/components/bases/BaseTextArea.vue";
import MultiSelect from "@/components/MultiSelect";
import LayoutAdminAddEdit from "@/views/layouts/LayoutAdminAddEdit";

export default {
  name: "PostSpiceItem",
  components: {LayoutAdminAddEdit, BaseTextArea, MultiImageUpload, BaseInput, MultiSelect},
  props: {
    propSpice: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      spice: {
        name: "",
        description: "",
        images: [],
        category: [],
        tags: [],
        price: 0
      },
      storagePath: "spices",
      updateSpice: {},
      reload: 0,
      mode: "Create",
      uploadComplete: false
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.mode = "Update";
      this.getSpice();
    } else {
      this.mode = "Create";
    }
  },
  watch: {
    propSpice: {
      handler: function (newVal) {
        this.spice = newVal;
      },
      deep: true
    }
  },
  methods: {
    uploadImageSuccess() {
      // this.spice.images.push(data);
      this.uploadComplete = true;
    },
    async getSpice() {
      this.spice = await this.$store.dispatch("spices/getSpice", this.$route.params.id)
    },
    validateSpice() {
      if (this.spice.name === "" || this.spice.description === "" || this.spice.images.length === 0) {
        return false;
      }
      return true;
    },
    async save() {
      if (!this.validateSpice()) {
        if (this.uploadComplete === false) {
          this.$toast.error("Please wait until upload of all images is complete");
          return
        }
        this.$toast.error("Please fill all required fields (name, description, images)");
        return;
      }
      await this.$store.dispatch("spices/createSpice", this.spice);
      await this.$router.push({name: "AdminSpicesView"});
    },
    handleInput(dataSet, value) {
      this.spice[dataSet] = value;
    },
    handleUpload(upload) {
      this.spice[upload.key] = upload.values;
      if (upload.values.length <= 0) {
        this.uploadComplete = false;
      }
    }
  },
}
</script>

<style scoped>

</style>