<template>
  <div class="d-flex flex-wrap">
    <div v-for="collection in collections" :key="collection.id"  class="m-3">
      <CollectionItem :prop-is-admin="propIsAdmin" :prop-collection="collection" @edit-collection="editCollection"></CollectionItem>
    </div>

  </div>
</template>

<script>
import CollectionItem from "@/components/CollectionItem.vue";

export default {
  name: "CollectionsGroup",
  components: {CollectionItem},
  props: {
    propCollections: {
      type: Array,
      required: true
    },
    propIsAdmin: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      collections: this.propCollections,
      renderKey: 0,
      render: true,
      isAdmin: this.propIsAdmin
    }
  },
  methods: {
    editCollection(collection) {
      this.$emit('edit-collection', collection)
    },
    deleteCollection(id) {
      this.$emit('delete-collection', id)
    }
  }
}
</script>

<style scoped>

</style>