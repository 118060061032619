<template>
  <b-modal id="add-collection-modal" :title="collection.searchQuery? 'Edit collection':'New collection'"
           body-bg-variant="light" header-bg-variant="light"
           footer-bg-variant="light" @ok="createCollection" size="lg"
           :ok-title="collection.name.length<=0? 'Next' : 'Create'"
           :ok-disabled="collectionImages.length <=0" hide-footer>


    <b-tabs content-class="mt-3">
      <b-tab title="Select photos" active>
        <div class="step-one">
          <b-form-group label="Search images" label-for="search">
            <b-input-group>
              <b-form-input
                  id="search" v-model="searchQuery"
                  required
                  placeholder="Search images">
              </b-form-input>
              <b-input-group-append v-if="searchQuery || collection.searchQuery">
                <b-button variant="info" @click="setCollectionImages">Search</b-button>
              </b-input-group-append>
            </b-input-group>


          </b-form-group>
          <div class="d-flex flex-wrap col-12">
            <div class="mt-2 col-12" v-if="collectionImages.length">
              <b-alert show variant="success">
                Search has matched {{ collectionImages.length }} images. Select an image to set it as lead image
              </b-alert>
              <div class="d-flex">
                <div v-if="collection.leadImage">
                  <img :src="collection.leadImage" alt="Collection image" height="200" width="200" class="p-1">
                </div>
                <div class="d-flex flex-wrap overflow-scroll" style="height: 200px">
                  <div v-for="image in collectionImages.slice(0,20)" :key="image.id">
                    <img :src="image.url" :alt="image.title" height="100" width="100" class="p-1 hover-large"
                         @click="setCollectionLeadImage(image)">
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!collectionImages.length" class="mt-2 col-12">
              <b-alert show variant="danger">
                No matching images found
              </b-alert>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Name collection" active>
        <div class="step-two">
          <form>
            <b-form-group label="Collection name">
              <b-form-input
                  id="collection-name"
                  v-model="collection.name"
                  required
                  placeholder="Collection name">
              </b-form-input>
            </b-form-group>
            <b-form-group label="Collection description">
              <b-form-textarea
                  id="collection-description"
                  v-model="collection.description"
                  required
                  placeholder="Collection name">
              </b-form-textarea>
            </b-form-group>
            <b-form-group label="Collection lead image" v-if="collection.leadImage">
              <img :src="collection.leadImage" alt="Collection image" height="250" width="250"
                   class="hover-large">
            </b-form-group>
            <b-form-group label="Publish collection" v-slot="{ ariaDescribedby }">
              <b-form-radio v-model="collection.published" :aria-describedby="ariaDescribedby" :value="true">
                Yes
              </b-form-radio>
              <b-form-radio v-model="collection.published" :aria-describedby="ariaDescribedby" :value="false">
                No
              </b-form-radio>
            </b-form-group>
          </form>
        </div>
      </b-tab>
    </b-tabs>
    <div class="d-flex justify-content-between mt-5">
      <b-button variant="secondary" @click="hideCollectionModal">Cancel</b-button>
      <b-button variant="primary"
                :disabled="collection.description.length <= 0 || collection.name.length <= 0 || collection.leadImage.length <= 0"
                @click="createCollection">Save collection
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import GalleryService from "@/services/gallery/GalleryService";

export default {
  name: "AddCollectionModal",
  props: {
    propMode: {
      type: String,
      required: true
    },
    propCollection: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      searchQuery: '',
      mode: this.propMode,
      items: [],
      images: [],
      collection: {
        name: '',
        description: '',
        leadImage: [],
      },
      collectionImages: []
    }
  },
  watch: {
    propCollection: function (val) {
      if (val) {
        this.collection = val
        this.searchQuery = val.searchQuery
        this.collectionImages = val.images
      }
    }
  },
  mounted() {
    this.getAllImages()
  },
  methods: {
    randomizeImages(images) {
      // TODO: This needs to be refactored so it checks for those related to the current image by tags
      let randomImages = images.sort(() => Math.random() - 0.5);
      return randomImages
    },
    async getAllImages() {
      this.items = await GalleryService.getItems();
    },
    handleSearch(searchTerm) {
      return this.items.filter(image => {
        return image.title.toLowerCase().includes(searchTerm.toLowerCase());
      });
    },
    setCollectionImages() {
      this.collectionImages = this.handleSearch(this.searchQuery)

    },
    async createCollection() {
      this.collection.images = this.collectionImages
      this.collection.size = this.collectionImages.length
      this.collection.searchQuery = this.searchQuery
      await this.$emit('createCollection', this.collection)
      this.$toast.success('Collection created successfully')
      this.hideCollectionModal()
    },
    setCollectionLeadImage(image) {
      this.collection.leadImage = image.url

    },
    hideCollectionModal() {
      this.$emit('hideAddCollectionModal')
    }
  }


}
</script>

<style scoped>

</style>