import { v4 as uuidV4 } from "uuid";

/**
 * PodcastModel is the podcast model class.
 * @class
 * @constructor
 * @public
 * @property {uuid} id - podcast id
 * @property {string} title - podcast title
 * @property {string} anchor - podcast anchor
 * @property {string} note - podcast note
 * @property {array} tags - podcast tags
 * @property {array} chapters - podcast chapters
 * @property {boolean} published - podcast published
 * @property {string} email - podcast creator email
 * @property {string} coverPath - podcast cover path
 * @property {string} cover - podcast cover
 * @property {Date} createdAt - podcast createdAt
 * @property {Date} updatedAt - podcast updatedAt
 */

class PodcastModel {
    constructor(title, anchor, note, tags, email, coverPath, cover) {
        this.id = uuidV4();
        this.title = title;
        this.anchor = anchor;
        this.note = note;
        this.tags = tags;
        this.chapters = [];
        this.published = false;
        this.email = email;
        this.coverPath = coverPath;
        this.cover = cover;
        this.createdAt = new Date();
        this.updatedAt = null;
    }
}

export default PodcastModel;
