<template>
  <div>
    <div class="mb-3">
      <label class="form-label">{{ label }}</label>
      <input :type="type" class="form-control" :id="id" :placeholder="placeholder" @input="updateValue"
             :value="modelValue"/>
    </div>
  </div>
</template>
<script>

export default {
  name: "BaseInput",
  props: {
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true,
      default: "text"
    },
    id: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: true,
      default: "placeholder"
    },
    modelValue: {
      required: false,
      default: ""
    }

  },
  watch: {
    modelValue: {
      handler(newValue) {
        this.$emit("handleInput", newValue);
        this.emitValue(newValue);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    updateValue(event) {
      this.$parent.$emit('input', event.target.value);
      this.$emit('input', event.target.value);
    },
    emitValue(value) {
      this.$emit('input', value);
    }
  }
}
</script>

<style scoped>

</style>
