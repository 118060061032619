<template>
  <div>
    <div class="admin-meal-plan">
      <router-view :role="role" :propMealPlans="mealPlans"
                   @show-add-meal-plan-modal="showAddMealPlanModal" :propMealPlanMenu="currentMealPlanMenu"
                   @navigate-to-meal-plan="navigateToMealPlan"
                   @save-meal-plan="saveMealPlan"
                   @cancel-meal-plan="cancelMealPlan"
                   @save-recipe-to-meal-plan="addRecipeDetailsToMealPlan"
                   @edit-meal-plan="editMealPlan"
                   @delete-meal-plan="deleteMealPlan"
                   :propMealPlan="currentMealPlan"
      />
    </div>
    <div>
      <add-meal-plan-modal @add-meal-plan="addMealPlan" :propMealPlan="currentMealPlan"/>
    </div>
  </div>
</template>

<script>
import PlansService from "@/services/plan/PlansService";
import AddMealPlanModal from "@/components/plans/AddMealPlanModal.vue";
import DailyMenuService from "@/services/plan/DailyMenuService";

export default {
  name: "AdminMealPlanView",
  components: {AddMealPlanModal},
  data() {
    return {
      mealPlans: [],
      mode: 'view',
      currentMealPlan: {},
      currentMealPlanId: "",
      currentMealPlanMenu: [
        {
          id: 1,
          day: "Monday",
          menu: [
            {
              "id": 1,
            }
          ]
        },
        {
          id: 2,
          day: "Tuesday",
          menu: [
            {
              "id": 1
            }
          ]
        },
        {
          id: 3,
          day: "Wednesday",
          menu: [
            {
              "id": 1
            }
          ]
        },
        {
          id: 4,
          day: "Thursday",
          menu: [
            {
              "id": 1
            }
          ]
        },
        {
          id: 5,
          day: "Friday",
          menu: [
            {
              "id": 1
            }
          ]
        },
        {
          id: 6,
          day: "Saturday",
          menu: [
            {
              "id": 1
            }
          ]
        },
        {
          id: 7,
          day: "Sunday",
          menu: [
            {
              "id": 1
            }
          ]
        }
      ],
      role: 'admin',
      recipes: [],
      planTemplate: {
        id: "",
        day: "",
        menu: [
          {
            "id": "",
            "type": "",
            "recipes": []
          },
        ]
      },
    }
  },
  mounted() {
    this.getMealPlans();
  },
  methods: {
    async deleteMealPlan(id) {
      await PlansService.deletePlan(id)
      await DailyMenuService.deleteMenu(id)
      await this.getMealPlans()
    },
    async saveMealPlan(mealPlanMenu) {
      let menu = {"id": this.currentMealPlanId, "menu": mealPlanMenu}
      await DailyMenuService.addMenu(menu)
      this.$toast.success("Meal plan recipe added successfully");
    },
    async cancelMealPlan() {
      await this.$router.push({name: 'AdminListMealPlans'});
    },
    editMealPlan(mealPlan) {
      this.showAddMealPlanModal();
      this.currentMealPlan = mealPlan;
    },
    showAddMealPlanModal() {
      this.$bvModal.show('add-meal-plan-modal');
    },
    async addMealPlan(plan) {
      await PlansService.addPlan(plan);
      await DailyMenuService.addMenu({id:plan.id, menu: this.currentMealPlanMenu});
      await this.getMealPlans()
    },
    async getMealPlans() {
      this.mealPlans = Object.values(await PlansService.getPlans());
    },
    removeRecipeFromMealPlan(recipeDetails) {
      this.currentMealPlanMenu = this.currentMealPlanMenu.filter(recipe => recipe.id !== recipeDetails.id);
    },
    async addRecipeDetailsToMealPlan(recipeDetails) {
      let mealPlan = this.currentMealPlanMenu.filter(mealPlan => mealPlan.day === recipeDetails.day);
      let mealPlanIndex = this.currentMealPlanMenu.indexOf(mealPlan[0]);
      let mealPlanMenu = this.currentMealPlanMenu[mealPlanIndex].menu;
      if (mealPlanMenu === undefined) {
        this.currentMealPlanMenu[mealPlanIndex].menu = [];
      }
      mealPlanMenu = this.currentMealPlanMenu[mealPlanIndex].menu;
      let mealPlanMenuIndex = mealPlanMenu.findIndex(menu => menu.type === recipeDetails.type);
      if (mealPlanMenuIndex === -1 || mealPlanMenuIndex === undefined) {
        let newMenu = {
          "id": recipeDetails.type,
          "type": recipeDetails.type,
          "recipes": []
        }
        newMenu.recipes.push(recipeDetails.recipe);
        this.currentMealPlanMenu[mealPlanIndex].menu.push(newMenu);
      } else {
        this.currentMealPlanMenu[mealPlanIndex].menu[mealPlanMenuIndex].recipes.push(recipeDetails.recipe);
      }
      this.$toast.success('Recipe added to meal plan successfully')
    },
    async navigateToMealPlan(mealPlan) {
      this.currentMealPlan = mealPlan;
      this.currentMealPlanId = mealPlan.id;
      let dailyMealPlan = await DailyMenuService.getMenuById(mealPlan.id);
      if (dailyMealPlan.menu !== undefined) {
        this.currentMealPlanMenu = dailyMealPlan.menu;
      }
      await this.$router.push({name: 'AdminMealPlan', params: {id: mealPlan.id, mealPlan: mealPlan}});
    }

  }
}
</script>

<style lang="scss" scoped>
.admin-meal-plan {
  padding: 1.5rem;
  background-color: $darkbrown;
}

</style>
