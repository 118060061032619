import {DatabaseDAO} from "@/services/base/DatabaseDAO";

class SpicesService extends DatabaseDAO {
    constructor() {
        super();
        this.recordUrl = `spices/${process.env.VUE_APP_ENVIRONMENT}`
    }

    addSpice(spice) {
        return this.addRecord(spice, this.recordUrl);
    }
    updateSpice(spice) {
        return this.updateRecord(spice.id, this.recordUrl, spice);
    }
    getSpiceById(spiceId) {
        return this.getRecord(spiceId, this.recordUrl);
    }

    deleteSpice(recordId) {
        return this.deleteRecord(recordId, this.recordUrl);
    }

    async getSpices(publishedOnly = true) {
        let spices = Object.values(await this.getRecords(this.recordUrl));
        if (publishedOnly) {
            spices = spices.filter(r => r.published);
        }
        return spices;
    }

}

export default new SpicesService();