<template>
  <div>
    <b-modal
        ref="modal"
        @hide="closeModal"
        size="xl"
        body-class="modal-main"
        hide-header
        hide-footer
        id="photo-view-modal"
    >
      <button class="close-x" @click="closeModal">
        <b-icon-x-lg/>
      </button>

      <div class="container-xl">
        <div class="modal-actions">
          <div>
          <span
              tabindex="0"
              data-bs-toggle="tooltip"
              class="d-inline-block tooltip-wrapper disabled"
              title="You can only like once logged in!"
          >
            <base-button
                @click.native="addLike"
                variant="outline-secondary"
                :disabled="isDisabled"
            >
              <b-icon icon="hand-thumbs-up-fill"/>
              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
              {{ currentImage.likes.length }}<span class="visually-hidden">likes</span>
              </span>
            </base-button>
          </span>
          </div>
          <div>
            <base-button @click.native="downloadImage(currentImage)" class="btn btn-primary">
              Download
            </base-button>
          </div>
        </div>
        <div class="d-flex justify-content-center flex-column my-3">
          <photo-view-image-carousel :propCarouselImages="carouselImages" v-if="!isAdmin" :prop-slide="currentImageIndex" />

          <!--          This feature is for the admin side only! Will need refactoring but it allows one to edit only 1 image at a time-->
           <div v-if="isAdmin">
            <h3 class="text-decoration-underline text-primary" @click="goToRoute('PhotoView')">{{
                currentImage.title
              }}</h3>
            <p class="text-muted fst-italic" @click="showModal('info-modal')">
              {{ currentImage.description }}</p>
          </div>
          <b-img-lazy  v-if="isAdmin" :src="currentImage.url" img-fluid rounded :alt="currentImage.title" class="modal-main-img"/>
        </div>
        <div class="d-flex justify-content-end my-1">
          <div class="button-group">
            <base-button
                @click.native="showModal('info-modal')"
                variant="outline-secondary"
                small
            >
              <b-icon-info-circle-fill/>
              More info
            </base-button>
            <base-button
                @click.native="showModal('share-modal')"
                variant="outline-secondary"
                small
            >
              <b-icon-share-fill/>
              Share
            </base-button>
            <base-button
                @click.native="deleteImage"
                variant="danger"
                small v-if="isAdmin">
              <b-icon-trash-fill/>
              Delete
            </base-button>
            <base-button @click.native="showModal('edit-modal')" variant="primary" small v-if="isAdmin">
              <b-icon-pencil-square/>
              Edit

            </base-button>
          </div>
        </div>
      </div>


    </b-modal>
    <b-modal
        id="info-modal"
        @hide="hideModal('info-modal')"
        ref="modalInfo"
        body-class="modal-info"
        hide-header
        hide-footer
        centered
        no-fade
        size="sm"
    >
      <button class="close-x" @click="hideModal('info-modal')">
        <b-icon-x-lg/>
      </button>

      <div class="modal-info-top">
        <b-row>
          <b-col cols="5" class="text-center">
            <img
                :src="currentImage.url"
                :alt="currentImage.title"
                class="modal-info-img"
            />
          </b-col>
          <b-col cols="7">
            <div class="modal-info-heading">
              <h3>{{ currentImage.title }}</h3>
              <p>{{ currentImage.author }}</p>
              <p>{{ currentImage.description }}</p>
              <b-badge
                  v-for="tag in currentImage.tags"
                  variant="primary"
                  pill
                  :key="tag">
                {{ tag }}
              </b-badge>

            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
        id="share-modal"
        @hide="hideModal('share-modal')"
        ref="modalShare"
        body-class="modal-info"
        hide-header
        hide-footer
        centered
        no-fade
        size="sm"
    >
      <button class="close-x" @click="hideModal('share-modal')">
        <b-icon-x-lg/>
      </button>
      <h3>Spread the word</h3>
      <p>Share this image on your social media account</p>
      <social-sharing :image="currentImage" :index="currentImageIndex" :original-path="originalPath"/>
    </b-modal>
    <b-modal
        id="edit-modal"
        @hide="hideModal('edit-modal')"
        ref="modalShare"
        body-class="modal-info"
        hide-header
        hide-footer
        centered
        no-fade
        v-if="isAdmin"
    >
      <button class="close-x" @click="hideModal('edit-modal')">
        <b-icon-x-lg/>
      </button>
      <div>
        <h3>Edit image</h3>
        <p>Change the title, description and tags of this image</p>
        <div class="form-group">
          <label for="title">Title</label>
          <input
              type="text"
              class="form-control"
              id="title"
              v-model="currentImage.title"
          />
        </div>
        <div class="form-group">
          <label for="description">Description</label>
          <textarea
              class="form-control"
              id="description"
              rows="3"
              v-model="currentImage.description"
          ></textarea>
        </div>
        <div class="form-group">
          <multi-select label="Tags" data-set="tags" @handleInput="handleInput" v-model="currentImage.tags"
                        :pre-selected-values="currentImage.tags"/>
        </div>
        <div class="d-flex justify-content-end">
          <base-button @click.native="updateImage" variant="primary">
            Update
          </base-button>
        </div>

      </div>
    </b-modal>

  </div>
</template>

<script>
import BaseButton from "@/components/bases/BaseButton.vue";
import SocialSharing from "@/components/SocialSharing.vue";
import MultiSelect from "@/components/MultiSelect.vue";
import PhotoViewImageCarousel from "@/components/PhotoViewImageCarousel.vue";

export default {
  name: "PhotoViewModal",
  props: {
    propIsAdmin: {
      type: Boolean,
      default: false
    },
    propCurrentImage: {
      type: Object,
      default: null
    },
    propCarouselImages: {
      type: Array,
      default: null
    },
    propCurrentImageIndex: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      currentImage: {...this.propCurrentImage, tags: []},
      isAdmin: this.propIsAdmin,
      isDisabled: false,
      image: null,
      currentImageIndex: 0,
      originalPath: null,
      carouselImages: []
    };
  },
  watch: {
    propCurrentImage: function (val) {
      this.currentImage = val;
    },
    propIsAdmin: function (val) {
      this.isAdmin = val;
    },
    propCarouselImages: function (val) {
      this.carouselImages = val;
    },
    propCurrentImageIndex: function (val) {
      this.currentImageIndex = val;
    }
  },
  components: {
    PhotoViewImageCarousel,
    MultiSelect,
    BaseButton,
    SocialSharing
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    downloadImage(image) {
      this.$emit("download-image", image);
    },
    handleInput(input, values) {
      this.currentImage[input] = values;
    },
    updateImage() {
      let confirmUpdate = confirm("Are you sure you want to update this image?");
      if (confirmUpdate) {
        this.$emit("update-image", this.currentImage);
      }
      this.hideModal('edit-modal');
    },
    deleteImage() {
      let confirmDelete = confirm("Are you sure you want to delete this image?");
      if (confirmDelete) {
        this.$emit("delete-image", this.currentImage);
      }
    },
    addLike() {
      this.$emit("add-like", this.currentImage);
      this.isDisabled = true;
    },
    goToRoute(route) {
      this.$router.push({name: route, params: {id: this.currentImage.id}});
    },
    showModal(modal) {
      this.$bvModal.show(modal);
    },
    hideModal(modal) {
      this.$bvModal.hide(modal);
    },
  }

}
</script>

<style scoped>

</style>