<template>
  <b-container fluid="xl" class="my-3">

    <b-button variant="outline" @click="$router.go(-1)">
      <b-icon-arrow-left /> Back
    </b-button>

    <section class="my-3">
      <div class="podcast-summary section-title section-title">
        <h2>Podcast</h2>
        <p v-if="podcast.title">{{ podcast.title }}</p>
      </div>

      <div class="podcast-wrapper">
        <div class="podcast-hero">
          <div>
            <img
                v-if="('coverPath' in podcast)"
                class="podcast-icon"
                :src="podcast.cover"
                alt="Podcast cover"
                width="100"
                height="100"
            />
          </div>
          <div>

            <h6 v-if="podcast.anchor" class="hero-title">
              <span>Hosted By:</span> <strong>{{ podcast.anchor }}</strong>
            </h6>
            <tag-item v-for="tag in podcast.tags" :key="tag" :tag-name="tag"/>
          </div>
        </div>
        <div class="podcast-content">
          <div class="podcast-note" v-html="podcast.note"/>
          <div class="d-flex justify-content-end">
            <button
                class="btn btn-sm btn-outline-primary my-3"
                @click="handleNew"
                v-if="permissions.CAN_WRITE"
            >
              <em class="bi bi-plus-circle-fill"/> New Chapter
            </button>
          </div>

          <p class="label" v-if="podcast.chapters">Available chapters/episodes:</p>
          <p class="label label-error" v-else>No chapters/episodes available!</p>

          <PodcastChapter
              class="w-100"
              :chapter="chapter"
              v-for="(chapter, index) in orderedChapters"
              :key="(index+reloadKey)"
              :permissions="permissions"
              @edit="handleEdit"
              @publish="handlePublish"
              @unPublish="handleUnPublish"
              @delete="handleDelete"
              @pricing="handlePricing"
              @play="handlePlay"
              @stop="handleStop"
          />
        </div>
      </div>

    </section>

    <b-row class="text-center">
      <b-col cols="12">
        <div id="comments">
          <comments-box :resource-id="podcast.id" :resource-category="resourceCategory"/>
        </div>
      </b-col>
      <b-col cols="12">
        <social-sharing :share-content="shareContent" :show-name="false"/>
      </b-col>
    </b-row>

  </b-container>

</template>
<script>
import CommentsBox from "@/components/CommentsBox";
import PodcastChapter from "@/components/PodcastChapter";
import SocialSharing from "@/components/SocialSharing";
import TagItem from "@/components/TagItem";
import PodcastService from "@/services/podcast/PodcastService";
import {printHtmlToPdf} from "@/utils/PdfUtil";
import {timestampFieldSort} from "@/utils/SortUtil";

export default {
  name: "ViewPodcast",
  components: {
    CommentsBox,
    PodcastChapter,
    SocialSharing,
    TagItem
  },
  data() {
    return {
      podcast: {
        title: ""
      },
      resourceCategory: "podcasts",
      shareContent: {
        url: window.location.href,
        title: "",
        description: "",
        quote: "",
        hashtags: ""
      },
      printHtmlToPdf: printHtmlToPdf,
      PodcastService: PodcastService,
      permissions: {},
      reloadKey: 0,
      orderedChapters: []
    };
  },
  computed: {
    userPermissions() {
      return this.$store.state.userPermissions;
    },
    hasAdminAccess() {
      return this.permissions.CAN_WRITE || this.permissions.CAN_EDIT || this.permissions.CAN_DELETE;
    }
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.permissions = this.getUserPermissions(this.userPermissions);
    this.podcast = await this.getPodcast();
    this.shareContent.title = this.podcast.title;
    this.orderedChapters = await this.getOrderedPodcastChapters();
  },
  methods: {
    getUserPermissions(userPermissions) {
      this.$emit("userPermissions", userPermissions)
      return {
        CAN_WRITE: true,
        CAN_EDIT: true,
        CAN_PUBLISH: true,
        CAN_DELETE: true
      };
    },
    async getPodcast() {
      const podcastId = this.$route.params.podcastId;
      return PodcastService.getPodcast(podcastId);
    },
    async getOrderedPodcastChapters() {
      if (!this.hasAdminAccess) {
        this.podcast.chapters = await PodcastService.getPodcastPublishedChapters(this.podcast.id);
      }
      if (this.hasAdminAccess) {
        this.podcast.chapters = await PodcastService.getPodcastChapters(this.podcast.id);
      }

      this.podcast.chapters = this.getFilteredChapter(this.podcast.chapters, this.$route.params.chapterId);
      return this.getChaptersByLatestOrder(this.podcast.chapters);
    },
    handleNew() {
      this.$router.push({
        name: "AddPodcastChapter",
        params: {podcastId: this.podcast.id}
      });
    },
    handleEdit(chapter) {
      this.$router.push({
        name: "EditPodcastChapter",
        params: {podcastId: this.podcast.id, chapterId: chapter.id},
      });
    },
    async handlePublish(chapter) {
      const podcastId = this.podcast.id;
      const chapterId = chapter.id;
      if (window.confirm("Confirm! Do you want to publish this chapter?")) {
        await PodcastService.updatePodcastChapter(podcastId, chapterId, {published: true, updatedAt: new Date()});
        await this.reloadPodcast();
        this.$toast.success(`Chapter was published successfully!`);
      }
    },
    async handleUnPublish(chapter) {
      const podcastId = this.podcast.id;
      const chapterId = chapter.id;
      if (window.confirm("Confirm! Do you want to UnPublish this chapter?")) {
        await PodcastService.updatePodcastChapter(podcastId, chapterId, {published: false, updatedAt: new Date()});
        await this.reloadPodcast();
        this.$toast.info(`Chapter was unpublished successfully!`);
      }
    },
    async handleDelete(chapter) {
      const podcastId = this.podcast.id;
      const chapterId = chapter.id;
      if (window.confirm("Confirm! Do you want to delete this chapter?")) {
        await PodcastService.deletePodcastChapter(podcastId, chapterId);
        await this.reloadPodcast();
        this.$toast.error(`Chapter was deleted successfully!`);
      }
    },
    async reloadPodcast() {
      this.podcast = await this.getPodcast();
      this.orderedChapters = await this.getOrderedPodcastChapters();
      this.reloadKey++;
    },
    getChaptersByLatestOrder(chapters) {
      return timestampFieldSort(chapters, "createdAt");
    },
    handlePricing() {
      this.$router.push({name: "Pricing"});
    },
    getFilteredChapter(chapters, chapterId) {
      if (chapterId) {
        chapters = chapters.filter(chapter => chapter.id === chapterId);
      }
      return chapters;
    },
    handlePlay(chapter) {
      this.$store.commit("setPlaying", chapter);
    },
    handleStop() {
      this.$store.commit("setPlaying", null);
    }
  }
};
</script>
<style lang="scss" scoped>
.viewer-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: .5rem;
  background: #f7f7f7;
  border-radius: 8px;

  .back {
    height: min-content;
  }
}

.podcast-wrapper {
  border: 1px solid orange;
  border-radius: 8px;
  padding: 1rem .5rem;

  @media (min-width: 768px) {
    padding: 1.5rem;
  }

  .label {
    margin: 0 0 1rem;
    padding: 1rem;
    background: #94bf5a;
    border-radius: 8px;
    color: white;
    font-size: .875rem;

    &.label-error {
      background: #bf5a5a;
    }
  }
}

.podcast-hero {
  display: flex;
  margin: 0 0 1rem;

  .podcast-icon {
    display: block;
    width: 100px;
    height: auto;
    border-radius: 8px;
  }

  > div {
    margin-right: 1rem;

    &:last-child {
      margin: 0;
    }
  }

  .hero-title {
    > span {
      display: block;
      font-size: .875rem;
      font-weight: 500;
      padding: 0;
      margin: 0 0 5px 0;
      color: #6c757d;
    }
  }

  @media (max-width: 767px) {
    .podcast-icon {
      width: 70px;
    }

    .hero-title {
      font-size: 1rem;
    }
  }
}

.podcast-note {
  display: block;
  margin: 0 0 1rem;
  padding: 1rem;
  background: #f7f7f7;
  color: #6c757d;
  border-radius: 8px;
  font-size: .875rem;

  ::v-deep {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.text-justify {
  text-align: justify;
}
</style>
