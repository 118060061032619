import {DatabaseDAO} from "@/services/base/DatabaseDAO";


class PlansService extends DatabaseDAO {
    constructor() {
        super();
        this.recordUrl = `meal-plans/${process.env.VUE_APP_ENVIRONMENT}`
    }

    addPlan(plan) {
        return this.addRecord(plan, this.recordUrl);
    }

    getPlanById(planId) {
        return this.getRecord(planId, this.recordUrl);
    }

    deletePlan(recordId) {
        return this.deleteRecord(recordId, this.recordUrl);
    }

    getPlans() {
        return this.getRecords(this.recordUrl);
    }

}

export default new PlansService();
