import { render, staticRenderFns } from "./EditRecipe.vue?vue&type=template&id=72d8c154&scoped=true&"
import script from "./EditRecipe.vue?vue&type=script&lang=js&"
export * from "./EditRecipe.vue?vue&type=script&lang=js&"
import style0 from "./EditRecipe.vue?vue&type=style&index=0&id=72d8c154&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72d8c154",
  null
  
)

export default component.exports