import {v4 as uuidv4} from 'uuid';

/**
 * RecipeModel is the recipe model class.
 * @class
 * @constructor
 * @public
 * @property {id} id - recipe id
 * @property {string} title - recipe title
 * @property {string} description - recipe description
 * @property {string} ingredients - recipe ingredients
 * @property {string} steps - recipe steps
 * @property {string} notes - recipe steps
 * @property {array} images - recipe images
 * @property {array} tags - recipe tags
 * @property {boolean} isPublished - recipe isPublished
 * @property {array} reactions - recipe reactions
 * @property {string} creatorEmail - recipe createdAt
 * @property {string} createdAt - recipe createdAt
 */

class RecipeModel {
    constructor(title, category, description, creatorEmail) {
        this.id = uuidv4();
        this.title = title;
        this.category = category;
        this.description = description;
        this.ingredients = "";
        this.steps = "";
        this.notes = "";
        this.images = [];
        this.tags = []
        this.isPublished = false;
        this.reactions = [];
        this.creatorEmail = creatorEmail;
        this.createdAt = new Date().toISOString();
    }
}

export default RecipeModel;