<template>
  <div class="container-xl">
    <div class="row">
      <div class="col-12">
        <b-jumbotron :header="collection.name.toLocaleUpperCase()" lead="" class="bg-light p-3">
          <b-button v-b-toggle.collapse variant="primary">More</b-button>
          <b-collapse id="collapse" class="mt-2">
            <p>
              {{collection.description}}
            </p>
            <div>

            </div>
          </b-collapse>
        </b-jumbotron>
      </div>
    </div>
    <grid-images :prop-images="images" :prop-mixed-grid="true" :prop-search-available="false"/>
  </div>
</template>

<script>
import GridImages from "@/components/GridImages.vue";

export default {
  name: "CollectionView",
  components: {GridImages},
  props: {
    propCollection: {
      type: Object,
      required: true
    },
    propIsAdmin: {
      type: Boolean,
      required: true
    },

  },
  data() {
    return {
      collection: this.propCollection,
      isAdmin: this.propIsAdmin,
      images: []
    }
  },
  mounted() {
    this.getCollection()
  },
  methods: {
    async getCollection() {
      let collectionId = this.$route.params.id
      this.collection = await this.$store.dispatch('collection/getCollection', collectionId)
      this.images = this.collection.images
    }
  }
}
</script>

<style scoped>

</style>