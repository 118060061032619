<template>
  <div>
    <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <div class="accordion-header" id="headingOne">
          <div class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
               aria-expanded="true" aria-controls="collapseOne">
            <h4>
              Advertise your business here
            </h4>
          </div>
        </div>
        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
             data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div>
              <!--  Pricing Table-->
              <div class="container">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="card mb-5 mb-lg-0">
                      <div class="card-body">
                        <h5 class="card-title text-muted text-uppercase text-center">Basic</h5>
                        <h6 class="card-price text-center">$499<span class="period">/month</span></h6>
                        <hr>
                        <ul class="fa-ul">
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span><strong>Choose 10 recipes to
                            advertise</strong></li>

                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span><strong>Adverts shown on random 20
                            recipes</strong></li>
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span><strong>100 clicks per day</strong></li>
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span>Phone support
                          </li>
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span>Weekly status report
                          </li>
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span>Adverts shown in print media
                          </li>
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span>Adverts posted on social media
                          </li>


                        </ul>
                        <div class="d-grid">
                          <button type="button" class="btn btn-primary text-uppercase" @click="subscribeToNewsletter">
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card mb-5 mb-lg-0">
                      <div class="card-body">
                        <h5 class="card-title text-muted text-uppercase text-center">Pro</h5>
                        <h6 class="card-price text-center">$799<span class="period">/month</span></h6>
                        <hr>
                        <ul class="fa-ul">
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span><strong>Choose 20 recipes to
                            advertise</strong></li>

                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span><strong>Adverts shown on top 20
                            recipes</strong></li>
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span><strong>1000 clicks per day</strong></li>
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span>Phone support
                          </li>
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span>Weekly status report
                          </li>
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span>Adverts shown in print media
                          </li>
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span>Adverts posted on social media
                          </li>


                        </ul>
                        <div class="d-grid">
                          <button type="button" class="btn btn-primary text-uppercase" @click="subscribeToNewsletter">
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title text-muted text-uppercase text-center">Enterprise</h5>
                        <h6 class="card-price text-center">$999<span class="period">/month</span></h6>
                        <hr>
                        <ul class="fa-ul">
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span><strong>Choose recipes to
                            advertise</strong></li>
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span><strong>Unlimited clicks</strong></li>
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span>Adverts will be shown on the home page
                          </li>
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span>Adverts posted on social media
                          </li>
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span>Adverts will be shown in print media
                          </li>
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span>Dedicated Phone Support
                          </li>
                          <li><span
                              class="fa-li"><i class="fas fa-check"></i></span>Hourly Status Reports
                          </li>
                        </ul>
                        <div class="d-grid">
                          <button type="button" class="btn btn-primary text-uppercase" @click="subscribeToNewsletter">
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div>
          <form v-if="subscribe">
            <!--            Subscribe to newsletter-->
            <div class="container">
              <div class="row">
                <div class="col-lg-8 mx-auto">
                  <div>
                    <h2 class="text-center">Showcase your business</h2>
                    <p class="lead mb-5 text-center">Please share your email and a sales executive will get you setup
                      easily</p>
                  </div>
                  <div class="input-group mb-3">
                    <input type="email" class="form-control" placeholder="Enter your email address"
                           aria-label="Recipient's username" aria-describedby="button-addon2" v-model="customer.email">
                    <button class="btn btn-secondary" type="button" id="button-addon2" @click="shareEmail">Share
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SalesService from "@/services/pricing/SalesService";

export default {
  name: "Pricing",
  data() {
    return {
      subscribe: false,
      customer: {
        email: '',
        message: '',
      }
    }
  },
  methods: {
    subscribeToNewsletter() {
      this.subscribe = true;
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    async shareEmail() {
      if (this.validateEmail(this.customer.email)) {
        this.$toast.success('Thank you for sharing your email. We will get back to you shortly');
        await SalesService.createNewSalesLead(this.customer)
        this.subscribe = false;
      } else {
        this.$toast.error('Please enter a valid email address');

      }

    }
  }
}
</script>

<style scoped>

</style>
