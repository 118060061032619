import {DatabaseDAO} from "@/services/base/DatabaseDAO";

class DailyMenuService extends DatabaseDAO {

    constructor() {
        super();
        this.recordUrl = `daily-menus/${process.env.VUE_APP_ENVIRONMENT}`
    }

    addMenu(menu) {
        return this.addRecord(menu, this.recordUrl);
    }

    getMenuById(menuId) {
        return this.getRecord(menuId, this.recordUrl);
    }

    deleteMenu(menuId) {
        return this.deleteRecord(menuId, this.recordUrl);
    }
}


export default new DailyMenuService();
