<template>
  <div id="app" class="d-flex flex-column min-vh-100">
    <head-navbar :key="reload" @loadRecipes="loadRecipes" @setSearchQuery="setSearchQuery"></head-navbar>
    <router-view :propRecipes="recipes" @signedIn="signedIn" :propSearchQuery="searchQuery" class="main-content"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import HeadNavbar from "@/components/HeadNavbar";
import MainFooter from "@/components/MainFooter";

export default {
  components: {HeadNavbar, MainFooter},
  data() {
    return {
      reload: 0,
      recipes: [],
      searchQuery: {}
    }
  },
  watch: {
    isMenuOpen() {
      document.querySelector('body').style.overflowY = this.isMenuOpen ? 'hidden' : null;
    }
  },
  computed: {
    isMenuOpen() {
      return this.$store.state.isMenuOpen
    },
  },
  methods: {
    setSearchQuery(query) {
      this.searchQuery = query
    },
    signedIn() {
      this.reload++
    },
    loadRecipes(recipes) {
      this.recipes = recipes
    }
  }
}
</script>

<style lang="scss">
$radius: 33px;

body {
  overflow-x: hidden;

  &.modal-open {
    overflow: hidden;
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(175, 38, 89, .25);
  }
}

#sidebar-menu {
  &.b-sidebar {
    max-width: 100%;
    width: 320px;
    padding: 3.5rem 2.75rem 4rem;

    @media screen and (min-width: 465px) {
      width: 415px;
    }

    @media screen and (min-width: 768px) {
      height: 100%;
    }
  }
}

.btn,
button,
[type="button"],
[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 1;
}

.form-control {
  height: auto !important;
}

textarea {
  padding: 1rem;
  border-radius: 33px;
  background: $atmosphere;
  border: none;

  &::placeholder {
    color: $gray2;
  }
}

.input-group-prepend {
  > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.input-group-append {
  > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.sr-only-focusable:focus,
.sr-only-focusable:active {
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  height: auto !important;
  margin: auto !important;
  overflow: visible !important;
  width: auto !important;
  white-space: normal !important;
}

.font-cursive {
  font-family: $font-family-cursive;
}

.page {
  margin: 1rem 0;
}

//modals
.modal-backdrop {
  opacity: 0.7 !important;
}

.modal-content {
  background: transparent !important;
  border-radius: $radius !important;
}

.close-x {
  position: absolute;
  background: none;
  border: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #050404;
}

.modal-main {
  padding: 1.5rem;
  background: #ffffff;

  .close-x {
    right: 20px;
    bottom: 20px;

    @media (min-width: 768px) {
      color: #ffffff;
      left: -50px;
      right: auto;
      bottom: auto;
    }
  }
}

.modal-info {
  padding: 1.5rem 2rem;
  background: $darkviolet;
  color: #ffffff;
  border-radius: $radius;

  .close-x {
    color: #ffffff;
    right: 20px;
    bottom: 20px;
  }

  .modal-info-img {
    vertical-align: top;
    width: 100%;
    border-radius: 17px;
  }

  h3 {
    margin-top: 0;
    color: #ffffff;
    font-size: 1rem;
  }

  h5 {
    font-size: .875rem;

    @media (min-width: 768px) {
      font-size: 1rem;
    }
  }

  p {
    font-size: .875rem;
    color: #acacac;
  }
}

.modal-info-details {
  padding: 1rem 0;

  > div {
    margin: 0 0 1.5rem;

    &:last-child {
      margin: 0;
    }
  }
}

.modal-share {
  min-height: 350px;
  padding: 1.5rem 2rem;
  background: #ffffff;
  color: #000000;
  border-radius: $radius;
  text-align: center;

  .close-x {
    color: #000000;
    right: 20px;
    bottom: 20px;
  }

  h3 {
    margin-top: 0;
    color: #000000;
    font-size: 1.25rem;

    @media (min-width: 768px) {
      font-size: 1.375rem;
    }
  }

  h5 {
    font-size: .875rem;

    @media (min-width: 768px) {
      font-size: 1rem;
    }
  }

  p {
    font-size: .875rem;
    color: #777777;
  }
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 0;

  @media (min-width: 768px) {
    padding: .5rem;
  }

  &.responsive {
    display: block;

    > div {
      margin: 0 0 .5rem;
    }

    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;

      > div {
        margin: 0;
      }
    }
  }

  p {
    position: relative;
    color: #a3a0a0;
    font-size: .875rem;

    @media (min-width: 768px) {
      font-size: 1rem;
      background: #ddf2fc;
      color: #2466a9;
      padding: 0.5rem;
      border-radius: 8px;
      margin: 0;
      font-weight: 500;

      &::before {
        position: absolute;
        content: 'name:';
        top: -1.25rem;
        font-size: .75em;
        font-weight: 300;
      }
    }
  }

  .button-group {
    display: flex;

    [variant] {
      margin: 0 .5rem 0 0;

      &:last-child {
        margin: 0;
      }
    }
  }

}

.page-section {
  margin-top: 1rem;
  margin-bottom: 0;

  @media (min-width: 768px) {
    margin-top: 4rem;
  }
}

.section-main {
  margin-top: -3rem;
  padding-top: 5rem;
  padding-bottom: 2rem;
  background-color: $darkbrown;
}

.meal-plan-hero {
  + section {
    @media (min-width: 768px) {
      padding-top: 4rem;
    }
    @media (min-width: 992px) {
      padding-top: 8rem;
    }
  }
}

.section-heading .section-heading-upper {
  display: block;
  font-size: 1rem;
  font-weight: 200;
}
.section-heading .section-heading-lower {
  display: block;
  font-family: $font-family-cursive;
  font-size: 5rem;
  font-weight: 100;
}

.btn-xl {
  font-weight: 700;
  font-size: 0.8rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.intro {
  position: relative;

  .intro-text {
    z-index: 1;
  }

  .intro-img {
    width: 100%;
    z-index: 0;
  }
}

.product-item {
  .product-item-img {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .product-item-img {
    display: none;
  }
}

@media (max-width: 991px) {
  .product-item-title {
    margin-bottom: 1rem;
  }
  .product-item-img {
    margin-bottom: 1rem;
  }
  .product-item-title-inner {
    display: block;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .intro {
    .intro-img {
      width: 75%;
      float: right;
    }
    .intro-text {
      left: 0;
      width: 60%;
      margin-top: 3rem;
      position: absolute;
    }
    .intro-button {
      width: 100%;
      left: 0;
      position: absolute;
      bottom: -2rem;
    }
  }

  .product-item {
    .product-item-title {
      position: relative;
      z-index: 1;
      margin-bottom: -9rem;
      transform: rotate(-3deg);
    }
    .product-item-title-inner {
      display: flex;
    }
    .product-item-img {
      position: relative;
      display: flex;
      margin-bottom: 0;
      z-index: 0;
      max-width: 60vw;
      left: -55px;
    }
    .product-item-description {
      position: relative;
      z-index: 1;
      margin-top: -4rem;
      max-width: 50vw;
    }
  }
}

@media (min-width: 1200px) {
  .intro {
    .intro-text {
      width: 45%;
    }
  }
}

// disable animation mobile/tablet
@media only screen and (max-width: 991px) {
  .card,
  .base-card,
  .title,
  .intro-text,
  .intro-img,
  .product-item-img,
  .product-item-title-inner,
  .product-item-description
  {
    opacity: 1 !important;
    transform: translate(0) scale(1) !important;
  }
}

</style>
