<template>
  <div>
    <div class="container">
      <div class="row my-3">
        <div class="col-12">
          <h1>Manage Collections</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="row my-3">
            <div class="col-12 d-flex justify-content-end">
              <button class="btn btn-primary" @click="addCollection">Add Collection</button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <collections-group
                  :prop-collections="collections"
                  :prop-is-admin="true"
                  @edit-collection="editCollection"
                  @delete-collection="deleteCollection"
                  v-if="collections.length > 0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <add-collection-modal prop-mode="add" ref="addCollectionModal"
                          @hideAddCollectionModal="hideAddCollectionModal"
                          @createCollection="createCollection" :prop-collection="collection"></add-collection-modal>
  </div>
</template>

<script>
import AddCollectionModal from "@/components/AddCollectionModal.vue";
import CollectionsGroup from "@/components/CollectionsGroup.vue";

export default {
  name: "AdminCollectionsView",
  components: {CollectionsGroup, AddCollectionModal},
  data() {
    return {
      collections: [],
      renderKey: 0,
      render: true,
      isAdmin: true,
      collection: {}
    }
  },
  mounted() {
    this.getCollections()
  },
  methods: {
    addCollection() {
      this.$bvModal.show('add-collection-modal')
      console.log('add-collection-modal')
    },
    hideAddCollectionModal() {
      this.$bvModal.hide('add-collection-modal')
    },
    editCollection(collection) {
      this.collection = collection
      this.$bvModal.show('add-collection-modal')
    },
    deleteCollection(id) {
      this.$refs.addCollectionModal.deleteCollection(id)
    },
    createCollection(collection) {
      //   dispatch
      this.$store.dispatch('collection/createCollection', collection)
    },
    async getCollections() {
      this.collections = await this.$store.dispatch('collection/getCollections')
    }
  }
}
</script>

<style scoped>

</style>