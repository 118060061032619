<template>
  <div class="recipe-page">
    <carousel
        :recipe="recipe"
        @anchorClick="scrollToRef('recipeBody')"
        show-anchor
    />
    <div
        id="recipeBody"
        ref="recipeBody"
        class="recipe-body"
    >
      <b-container fluid="lg">
        <div class="grid no-print">
          <b-row>
            <b-col cols="12" lg="3">
              <h4><strong>{{ recipe.title }}</strong></h4>
            </b-col>
            <b-col cols="6" lg="auto">
              <h6><strong>Cooking Time</strong></h6>
              <p>
                {{ recipe.cookingTime | timeFilter }}
              </p>
            </b-col>
            <b-col cols="6" lg="auto">
              <h6><strong>Servings</strong></h6>
              <p>
                {{ recipe.servings }}
              </p>
            </b-col>
            <b-col cols="6" lg="auto" class="no-border-sm">
              <h6><strong>Share</strong></h6>
              <div class="share">
                <facebook :url="url" :title="socialTitle" :scale="socialScale"></facebook>
                <twitter :url="url" :title="socialTitle" :scale="socialScale"></twitter>
                <pinterest :url="url" :title="socialTitle" :scale="socialScale"></pinterest>
              </div>
            </b-col>
            <b-col cols="6" lg="auto" class="print-button no-print">
              <button
                  class="btn btn-warning"
                  @click="print"
              >
                <i class="bi bi-printer"></i> Print
              </button>
            </b-col>
          </b-row>
          <b-row class="recipe-summary">
            <b-col cols="12" class="no-border">
              <div
                  class="placeholder-wrapper"
              >
                <div
                  v-if="imageUrl"
                  class="placeholder-img"
                  :style="{
                    'background-image': `url(${imageUrl})`,
                    'background-repeat': 'no-repeat',
                    'background-size': 'cover',
                    'background-position': 'center center'
                  }"
                >
                </div>
              </div>
              <p>{{ recipe.description }}</p>

            </b-col>
          </b-row>
          <b-row class="no-print">
            <b-col v-if="recipe.video" cols="12">
              <h6><strong>Video</strong></h6>
              <vimeo-player :prop-video-url="recipe.video" />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="4">
              <h6><strong>Ingredients</strong></h6>
              <ul>
                <li
                    v-for="ingredient in recipe.ingredients"
                    :key="ingredient"
                >
                  {{ ingredient }}
                </li>
              </ul>
            </b-col>
            <b-col cols="12" lg="8">
              <b-row>
                <b-col cols="12" class="no-border">
                  <h6><strong>Steps to prepare</strong></h6>
                  <ol>
                    <li v-for="step in recipe.steps" :key="step">
                      {{ step }}
                    </li>
                  </ol>
                </b-col>
                <b-col v-if="recipe.notes" cols="12" class="no-border">
                  <div class="well">
                    <h6><strong>Notes</strong></h6>
                    <p>{{ recipe.notes }}</p>
                  </div>
                </b-col>
                <b-col cols="12" class="tags no-border ">
                  <h6><strong>Tags</strong></h6>
                  <ul>
                    <li
                        v-for="(tag, index) in handleUndefinedValues('tags')"
                        :key="index"
                    >
                      {{ tag }}
                    </li>
                  </ul>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>

        <!--        section for print only-->
        <div class="row print-only" id="printMe">
          <div class="col-lg-6">
            <div class="mt-2">
              <h4><strong>{{ recipe.title }}</strong></h4>
            </div>
            <div class="mt-2">
              <h6><u><strong>Description</strong></u></h6>
              <p v-html="recipe.description"></p>
            </div>
            <div class="ingredients mt-2 text-left">
              <h6><u><strong>Ingredients</strong></u></h6>
              <ul>
                <li v-for="ingredient in recipe.ingredients" :key="ingredient">
                  {{ ingredient }}
                </li>
              </ul>
            </div>
            <div class="mt-2">
              <h6><u><strong>Servings</strong></u></h6>
              <p v-html="recipe.servings"></p>
            </div>
            <div class="mt-2">
              <h6><u><strong>Cooking Time</strong></u></h6>
              <p v-html="recipe.cookingTime"></p>
            </div>
            <div class="mt-2">
              <h6><u><strong>Cuisines</strong></u></h6>
              <ul>
                <li v-for="(cuisine, index) in handleUndefinedValues('cuisines')" :key="index">{{ cuisine }}</li>
              </ul>
            </div>
            <div class="mt-2">
              <h6><u><strong>Cooking Devices</strong></u></h6>
              <ul>
                <li v-for="(cuisine, index) in handleUndefinedValues('cookingDevices')" :key="index">{{ cuisine }}</li>
              </ul>
            </div>
          </div>
          <div class="d-flex flex-column col-lg-6">
            <div class="mt-2">
              <h6><u><strong>Category</strong></u></h6>
              <ul>
                <li v-for="(category, index) in handleUndefinedValues('category')" :key="index">{{ category }}</li>
              </ul>
            </div>
            <div class="mt-2">
              <h6><u><strong>Sub Categories</strong></u></h6>
              <ul>
                <li v-for="(subcategory, index) in handleUndefinedValues('subcategories')" :key="index">{{
                    subcategory
                  }}
                </li>
              </ul>
            </div>
            <div class="steps bg-aqua-active text-left">
              <h6><u><strong>Steps to prepare</strong></u></h6>
              <ol>
                <li v-for="step in recipe.steps" :key="step">
                  {{ step }}
                </li>
              </ol>
            </div>
            <div class="mt-2">
              <h6><u><strong>Notes</strong></u></h6>
              <p> {{ recipe.notes }}</p>
            </div>
            <div class="text-left">
              <h6><u><strong>Tags</strong></u></h6>
              <ul>
                <li v-for="(tag, index) in handleUndefinedValues('tags')" :key="index">{{ tag }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="d-flex justify-content-between btn-group btn-group-md mb-4" role="group">
            <button class="btn btn-info" @click="likeRecipe"><i class="bi bi-hand-thumbs-up"></i> Likes
              {{ recipe.likes || 0 }}
            </button>
            <button class="btn btn-success"><i class="bi bi-bag"></i> Order</button>
            <button class="btn btn-warning" @click="print"><i class="bi bi-printer"></i> Print</button>
            <button class="btn btn-success" v-if="!recipe.isPublished && this.checkLoggedIn()"
                    @click="changeRecipeVisibility('publish')">
              <i
                  class="bi bi-eye"></i> Publish
            </button>
            <button v-if="recipe.isPublished && this.checkLoggedIn()" class="btn btn-outline-danger"
                    @click="changeRecipeVisibility('unpublish')"><i
                class="bi bi-eye-slash"></i>Unpublish
            </button>
            <button v-if="this.checkLoggedIn()" class="btn btn-primary" @click="goToEditRecipe"><i
                class="bi bi-pencil-square"></i> Edit
            </button>


          </div>
        </div>
        <div class="row">
          <comments-box :reaction-id="recipeId"></comments-box>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import {getFromSessionStorage} from "@/assets/js/common";
import RecipeService from "@/services/recipe/RecipeService";
import CommentsBox from "@/components/CommentsBox";
import VimeoPlayer from "@/components/VimeoPlayer.vue";
import Carousel from "@/components/Carousel";
import defaultImage from "@/assets/img/syd-wachs-epqNIYI6S7E-unsplash.jpeg";
import {
  Facebook,
  Twitter,
  Pinterest,
} from "vue-socialmedia-share";

export default {
  name: "Recipe",
  components: {
    Carousel,
    CommentsBox,
    Facebook,
    Twitter,
    Pinterest,
    VimeoPlayer
  },
  data() {
    return {
      recipe: {},
      recipeId: "",
      output: null,
      recipeKey: 0,
      url: '',
      socialTitle: "Check out this recipe from Mpishi",
      socialScale: '2',
      defaultImage: defaultImage,
      imageUrl: '',
      videoUrl: '',
    }
  },
  computed: {},
  watch: {
    '$route.params.id': function (newVal) {
      this.recipeId = newVal;
      this.loadRecipe();
    },
    recipe: function () {
      this.recipeKey++;
      this.imageUrl = this.getLeadingImageUrl();
      // this.videoUrl = this.getVideoUrl();
    }
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.loadRecipe();
    await this.countRecipeView();
  },
  methods: {
    getLeadingImageUrl() {
      if (this.recipe.images.length > 1) {
        return this.recipe.images[1].url;
      }
      return this.defaultImage.imageUrl
    },
    scrollToRef(refName) {
      this.$refs[refName].scrollIntoView({behavior: 'smooth'});
    },
    changeRecipeVisibility(action) {
      let confirm = window.confirm("Are you sure you want to change visibility of this recipe?");
      if (!confirm) {
        return;
      }
      if (action === "publish") {
        this.recipe.isPublished = true;
      } else {
        this.recipe.isPublished = false;
      }
      if (this.recipe.images.length === 0) {
        this.recipe.images.push(this.defaultImage);
      }
      RecipeService.updateRecipe(this.recipeId, this.recipe);
      this.$toast.success("Recipe visibility changed!")
      this.loadRecipe();

    },
    handleUndefinedValues(key) {
      if (this.recipe[key] === undefined) {
        return ["N/A"]
      }
      return this.recipe[key]
    },
    async countRecipeView() {
      this.recipeId = this.$route.query.id;
      await RecipeService.addRecipeView(this.recipeId);
    },
    async likeRecipe() {
      await RecipeService.addRecipeLike(this.recipeId);
      this.$toast.success("You have liked!")
      this.$router.go()
    },
    async loadRecipe() {
      this.recipeId = this.$route.query.id;
      this.url = `https://www.mpishi.co/recipe?id=${this.$route.query.id}`
      let defaultRecipe = this.$store.state.defaultRecipe
      let recipe = await RecipeService.getRecipe(this.recipeId);
      // Merge default recipe with the one from the database
      this.recipe = {...defaultRecipe, ...recipe};
      this.setRecipeImages();
    },
    getRecipeCategory(recipe) {
      return (typeof (recipe.category) === "string" ? recipe.category.split(",") : recipe.category)
    },
    goToEditRecipe() {
      this.$store.commit("setRecipe", this.recipe);
      this.$router.push({name: "EditRecipe", params: {id: this.recipeId}});
    },
    checkLoggedIn() {
      let user = getFromSessionStorage("user_details");
      return !!user;
    },
    setRecipeImages() {
      let allImages = [];
      for (const image of this.recipe.images) {
        const newImage = {
          'url': image.imageUrl,
          'tags': ['No Tags']
        }
        allImages.push(newImage);
      }
      this.recipe.images = allImages;
    },
    async print() {
      const localOptions = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
          'https://maxcdn.bootstrapcdn.com/bootstrap/5.0.0/css/bootstrap.min.css',
          'https://unpkg.com/kidlat-css/css/kidlat.css'
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: false, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
      };
      await this.$htmlToPaper('printMe', localOptions);
    }
  }
}
</script>

<style lang="scss" scoped>
.recipe-body {
  padding: 2rem 0;
  color: $darkbrown;
  background-color: #ffffff;
}

.recipe-img {
  width: 150px;
}

.recipe-summary {
  align-items: center;

  [class*='col-'] {
    .placeholder-wrapper {
      display: none;

      + p {
        margin: 0;
        font-style: italic;
      }
    }

    @media (min-width: 768px) {
      display: flex;

      .placeholder-wrapper {
        display: inline-block;

        + p {
          display: inline-block;
          align-self: center;
          margin: 0 0 0 1.5rem;
          font-size: 1.2rem;
        }
      }
    }
  }
}

.grid {
  margin: 0 0 1rem;
  border: 1px solid $lightgray;

  .row {
    margin-left: 0;
    margin-right: 0;
    border-bottom: 1px solid $lightgray;

    &:last-child {
      border: 0;
    }
  }

  h4 {
    margin: 0;
  }

  p {
    &:last-child {
      margin: 0;
    }
  }

  [class*='col-'] {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }

  .print-button {
    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (min-width: 992px) {
    .col-lg-auto {
      flex: 1 1 0%;
      width: auto;
    }

    [class*='col-'] {
      padding: 1rem;
      border: 0;
      border-right: 1px solid #eee;

      [class*='col-'] {
        padding: 0;
      }

      &:last-child {
        border: 0;
      }
    }
  }
}

.tags {
  margin: 2rem 0 1rem;

  ul {
    list-style: none;
    margin: 1rem 0 0;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 .5rem .5rem 0;
    padding: .125rem 1rem;
    font-size: 1rem;
    background-color: $mediumviolet;
    border-radius: 50rem;
    text-transform: lowercase;
  }
}

.placeholder-wrapper,
.placeholder-img {
  width: 150px;
  height: 150px;
  cursor: pointer;
  border-radius: 50%;
}

.share {
  > span {
    margin-right: .25rem;
    cursor: pointer;
  }
}

.well {
  padding: 1rem;
  background: $lightgray;

  @media (min-width: 768px) {
    padding: 2rem;
  }
}

.no-border {
  border: 0 !important;
}

.no-border-sm {
  @media (max-width: 767px) {
    border: 0 !important;
  }
}

.print-only {
  display: none;
}

@media print {
  .no-print {
    display: none;
  }

  .print-only {
    display: block;
  }

  h4 {
    margin: 0 !important;
  }

  p {
    margin: 0;
  }
}
</style>
