<template>
  <b-container fluid="xl" class="preview">
    <div class="spices-view">
      <h1 class="my-5">Spices</h1>
      <div class="d-flex">
        <b-col
            v-for="(spice, index) in spices"
            :key="index"
            cols="12"
            md="4"
            lg="3"
            class="m-1"

        >
          <spice-preview-item :propSpice="spice" :role="role" @editSpice="editSpice" @publishSpice="publishSpice"/>
        </b-col>
      </div>

    </div>
  </b-container>
</template>

<script>
import SpicePreviewItem from "@/components/spices/SpicePreviewItem.vue";

export default {
  name: "AdminSpicesView",
  components: {SpicePreviewItem},
  data() {
    return {
      spices: [],
      loading: true,
      role: "admin",
      action: "preview",
      selectedSpice: {}
    };
  },
  mounted() {
    this.getSpices();
  },
  methods: {
    publishSpice(spice) {
      this.$store.dispatch("spices/createSpice", spice);
    },
    async getSpices() {
      this.spices = await this.$store.dispatch("spices/getSpices", {publishedOnly: false});
      this.loading = false;
    },
    editSpice(spice) {
      this.$router.push({name: "AdminSpiceEdit", params: {id: spice.id}});
    },
  }
}
</script>

<style scoped>

</style>