import Vue from 'vue';
import VueRouter from 'vue-router';
import Router from 'vue-router';
import AddRecipe from "@/views/AddRecipe";
import EditRecipe from "@/views/EditRecipe";
import Recipe from "@/views/Recipe";
import Home from "@/views/Home";
import Administration from "@/views/Administration";
import store from "../store/index"
import {auth} from "../../firebase.config";
import GallerySlider from "@/views/GallerySlider";
import ImagePage from "@/views/Image";
import AddGallery from "@/views/AddGallery";
import Pricing from "@/views/Pricing";
import ListMealPlans from "@/components/plans/ListMealPlans.vue";
import MealPlan from "@/components/plans/MealPlan.vue";
import AdminMealPlanView from "@/views/AdminMealPlanView.vue";
import MealPlanView from "@/views/MealPlanView.vue";
import Podcasts from "@/views/podcasts/Podcasts.vue";
import ViewPodcast from "@/views/podcasts/ViewPodcast.vue";
import AddPodcast from "@/views/podcasts/AddPodcast.vue";
import EditPodcast from "@/views/podcasts/EditPodcast.vue";
import MyPodcasts from "@/views/podcasts/MyPodcasts.vue";
import AddPodcastChapter from "@/views/podcasts/AddPodcastChapter.vue";
import EditPodcastChapter from "@/views/podcasts/EditPodcastChapter.vue";
import RecipesView from "@/views/RecipesView.vue";
import AdminRecipesView from "@/views/AdminRecipesView.vue";
import AdminSpicesView from "@/views/AdminSpicesView.vue";
import PostSpiceItem from "@/components/spices/PostSpiceItem.vue";
import SpicesView from "@/views/SpicesView.vue";
import SpiceItem from "@/components/spices/SpiceItem.vue";
import GalleryView from "@/views/GalleryView.vue";
import PhotoView from "@/views/PhotoView.vue";
import AdminGalleryView from "@/views/AdminGalleryView.vue";
import CollectionsView from "@/views/CollectionsView.vue";
import AdminCollectionsView from "@/views/AdminCollectionsView.vue";
import CollectionView from "@/views/CollectionView.vue";

Vue.use(VueRouter)


const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/recipes',
        name: 'Recipes',
        component: RecipesView
    },
    {
        path: '/pricing',
        name: 'Pricing',
        component: Pricing
    },
    {
        path: '/photo/:id',
        name: 'PhotoView',
        component: PhotoView,
    },
    {
        path: '/spices',
        name: 'SpicesView',
        component: SpicesView,
    },
    {
        path: '/spice/:id',
        name: 'SpiceView',
        component: SpiceItem,
    },
    {
        path: '/admin/spices',
        name: 'AdminSpicesView',
        component: AdminSpicesView,
    },
    {
        path: '/admin/spices/create',
        name: 'AdminSpiceCreate',
        component: PostSpiceItem,
    },
    {
        path: '/admin/spices/edit/:id',
        name: 'AdminSpiceEdit',
        component: PostSpiceItem,
    },
    {
        path: '/admin/recipes',
        name: 'AdminRecipes',
        component: AdminRecipesView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin/recipe/add',
        name: 'AddRecipe',
        component: AddRecipe,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin/recipe/edit/:id',
        name: 'EditRecipe',
        component: EditRecipe,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/recipe/:id',
        name: 'Recipe',
        component: Recipe
    },
    {
        path: '/admin',
        name: 'Administration',
        component: Administration,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/gallery',
        name: 'GalleryView',
        component: GalleryView
    },
    {
        path: '/image/:imageId?',
        name: 'image',
        component: ImagePage,
        meta: {
            showSearchNav: true,
        }
    },
    {
        path: '/gallery/image/:galleryItemIndex?',
        name: 'GallerySlider',
        component: GallerySlider
    },
    {
        path: '/admin/gallery/add',
        name: 'AddGallery',
        component: AddGallery,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin/gallery',
        name: 'AdminGalleryView',
        component: AdminGalleryView,
        meta: {
            requiresAuth: true,
            mixedGrid: false
        }
    },
    {
        path: '/meal-plans/',
        name: 'MealPlans',
        component: MealPlanView,
        children: [
            {
                path: 'list',
                name: 'PublicListMealPlans',
                component: ListMealPlans,
            },
            {
                path: ':id/view',
                name: 'PublicMealPlan',
                component: MealPlan,
                meta: {}

            }
        ]
    },
    {
        path: '/admin/meal-plans',
        name: 'AddMealPlan',
        component: AdminMealPlanView,
        children: [
            {
                name: 'AdminListMealPlans',
                path: 'list',
                component: ListMealPlans,
            },
            {
                name: 'AdminMealPlan',
                path: ':id/view',
                component: MealPlan,

            }

        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/podcasts",
        name: "Podcasts",
        component: Podcasts,
    },
    {
        path: "/podcasts/view/:podcastId",
        name: "ViewPodcast",
        component: ViewPodcast
    },
    {
        path: "/podcasts/view/:podcastId/chapter/:chapterId",
        name: "ViewPodcastChapter",
        component: ViewPodcast
    },
    {
        path: "/podcasts/add",
        name: "AddPodcast",
        component: AddPodcast,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/podcasts/edit/:podcastId",
        name: "EditPodcast",
        component: EditPodcast,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/podcasts/my",
        name: "MyPodcasts",
        component: MyPodcasts,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/podcasts/:podcastId/chapter/add",
        name: "AddPodcastChapter",
        component: AddPodcastChapter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/podcasts/:podcastId/chapter/edit/:chapterId",
        name: "EditPodcastChapter",
        component: EditPodcastChapter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/collection/:id",
        name: "CollectionView",
        component: CollectionView,
    },
    {
        path: "/collections",
        name: "Collections",
        component: CollectionsView,
    },
    {
        path: "/admin/collections",
        name: "AdminCollectionView",
        component: AdminCollectionsView,
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        // always scroll to top
        window.scrollTo(0, 0);
        return { top: 0, left: 0 }
    },
    routes
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

router.beforeEach(async (to, from, next) => {
    if (to.meta.requiresAuth && !auth.currentUser && to.name !== 'Administration') {
        return {name: 'Administration'}

    } else {
        store.commit("setLoggedIn", false)
        next()

    }
});

export default router
