function booleanFieldSort(dataset, datasetField) {
    return dataset.sort((a, b) => Number(b[datasetField]) - Number(a[datasetField]));
}

function timestampFieldSort(dataset, datasetField) {
    return dataset.sort((a,b) =>  new Date(b[datasetField]) - new Date(a[datasetField]));
}

export {
    booleanFieldSort,
    timestampFieldSort
};
