import {child, get, ref, set, update} from "firebase/database";
import {database} from "../../../firebase.config";
import {v4 as uuidv4} from "uuid";

/**
 * @description Data Access Object for Gallery
 * @class
 * @public
 */

class GalleryDao {

    /**
     * documentUrl is database url that is set to `gallery`
     * @type {string}
     * @public
     */
    documentUrl = `gallery/${process.env.VUE_APP_ENVIRONMENT}`



    /**
     * Add Item.
     *
     * @param {object} item  item to add
     * @return {object} added item
     */
    addItem(item) {
        if(!item.id){
            item.id = uuidv4()
        }
        const itemUrl = `${this.documentUrl}/${item.id}`;
        return set(ref(database, itemUrl), item);
    }

    /**
     * Update item
     *
     * @param {string} itemId The itemId to update.
     * @param {object} item The item data to update.
     * @return {object} updated item.
     */
    updateItem(itemId, item) {
        const itemUrl = `${this.documentUrl}/${itemId}`;

        const updates = {};
        updates[itemUrl] = {...item};

        return update(ref(database), updates);
    }

    /**
     * Delete item
     *
     * @param {string} itemId The itemId to delete.
     * @return {object} deleted item.
     */
    deleteItem(itemId) {
        return this.updateItem(itemId, null);
    }

    /**
     * Get item
     *
     * @param {string} itemId The itemId to update.
     * @return {promise} selected item.
     */
    getItem(itemId) {
        const dbRef = ref(database);
        const itemUrl = `${this.documentUrl}/${itemId}`;

        return get(child(dbRef, itemUrl)).then((snapshot) => {
            if (snapshot.exists()) {
                return snapshot.val();
            } else {
                return {};
            }
        }).catch((error) => {
            throw new Error(error);
        });
    }

    /**
     * Get all items
     *
     * @return {promise} all items.
     */
    getItems() {
        const dbRef = ref(database);
        const itemUrl = `${this.documentUrl}`;

        console.log('itemUrl', itemUrl)

        return get(child(dbRef, itemUrl)).then((snapshot) => {
            if (snapshot.exists()) {
                return snapshot.val();
            } else {
                return [];
            }
        }).catch((error) => {
            throw new Error(error);
        });
    }
}

export default new GalleryDao;