<template>
  <div class="mb-3">
    <label class="form-label">{{ label }}</label>
    <multiselect
        v-model="currentlySelectedValues"
        :options="preSelectedValues"
        :tag-placeholder="`Add this as new ${this.dataSet}`"
        :placeholder="`Search or add ${this.dataSet}`"
        :multiple="true"
        :taggable="taggable"
        @tag="addSelectedOption"
        @remove="removeSelectedOption"
    />
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';

export default {
  name: "MultiSelect",
  components: {
    Multiselect
  },
  props: {
    label: {
      type: String,
      required: true,
      default: "Select an option"
    },
    dataSet: {
      type: String
    },
    taggable: {
      type: Boolean,
      default: true
    },
    preSelectedValues: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({
        value: [],
        options: []
      })
    }
  },
  data() {
    return {
      multiselect: this.options,
      selectableOptions: [],
      currentlySelectedValues: []
    }
  },
  mounted() {
    this.selectableOptions = this.options.options;
    this.currentlySelectedValues = this.preSelectedValues;
  },
  watch: {
    preSelectedValues: {
      handler(newValue) {
        this.setSelectedOptions(newValue);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    setSelectedOptions(selectedOptions) {
      this.currentlySelectedValues = selectedOptions
    },
    addSelectedOption(tag) {
      this.currentlySelectedValues.push(tag);
      this.$emit("handleInput", this.dataSet, this.currentlySelectedValues);
    },
    removeSelectedOption(tag) {
      this.currentlySelectedValues = this.currentlySelectedValues.filter(item => item !== tag);
      this.$emit("handleInput", this.dataSet, this.currentlySelectedValues);
    },
  }
}
</script>
<style scoped>
</style>
