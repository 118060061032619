<template>
  <div class="card">
    <div class="card-body">
      <div
          class="placeholder-wrapper"
      >
        <div class="recipe-overlay">
          <button
              @click="viewRecipe(recipe.id)"
              class="btn recipe-overlay-button"
          >
            View Recipe
          </button>
        </div>
        <div
            class="placeholder-img"
            :style="{
            'background-image': `url(${imageUrl})`,
            'background-repeat': 'no-repeat',
            'background-size': 'cover',
            'background-position': 'center center'
          }"
        ></div>
      </div>

      <div class="card-title">{{ recipe.title }}</div>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-between m-auto">
        <div
            class="label-time"
            :class="[!recipe.cookingTime || recipe.cookingTime === 0 ? 'blank' : '']"
        >
          {{ recipe.cookingTime | timeFilter }}
        </div>
        <div class="label-likes">
          <b-icon-hand-thumbs-up/>
          <span class="number">{{ recipe.likes || 0 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RecipeService from "@/services/recipe/RecipeService";
import config from "@/services/recipe/RecipeConfig";

export default {
  computed: {
    imageUrl() {
      if (this.recipe.images.length > 1) {
        return this.recipe.images[1].imageUrl;
      }
      return this.recipe.images[0].imageUrl;
    }
  },
  data() {
    return {
      RecipeService: RecipeService,
      defaultImage: config.defaultImage,
    }
  },
  methods: {
    viewRecipe() {
      this.$router.push({name: "Recipe", params: {id: this.recipe.id}, query: {id: this.recipe.id}})
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$el.addEventListener("mouseenter", () => {
        this.$el.querySelector(".recipe-overlay").style.backgroundColor = "rgba(0, 0, 0, 0.5)";
        this.$el.querySelector(".recipe-overlay-button").style.opacity = "1";
      });
      this.$el.addEventListener("mouseleave", () => {
        this.$el.querySelector(".recipe-overlay").style.backgroundColor = "transparent";
        this.$el.querySelector(".recipe-overlay-button").style.opacity = "0";
      });
    });
  },
  name: "RecipePreviewItem",
  props: {
    recipe: {
      type: [Object, Array]
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  border: 0;
  margin-bottom: 1.5rem;
}

.card-title {
  margin: .5rem 0 0;
  font-size: .75em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -1px;

  @media (min-width: 768px) {
    font-size: .875em;
  }
}

.card-body {
  padding: 0;
}

.card-footer {
  bottom: 0;
  padding: .5rem 0 1rem;
  border: 0;
  background-color: #ffffff;
}

.placeholder-wrapper,
.placeholder-img,
.recipe-overlay {
  height: 30vh;
  border-radius: 33px;
  cursor: pointer;

  @media (min-width: 576px) {
    height: 40vh;
  }

  @media (min-width: 768px) {
    height: 200px;
  }

  @media (min-width: 992px) {
    height: 190px;
  }

  @media (min-width: 1400px) {
    height: 250px;
  }
}

.recipe-overlay {
  position: absolute;
  width: 100%;
  transition: all 250ms ease;
  background-color: transparent;

  .recipe-overlay-button {
    position: relative;
    display: block;
    margin: 0 auto;
    background-color: $violet;
    border: 2px solid $violet;
    color: #ffffff;
    top: 40%;
    opacity: 0;
  }
}

.placeholder-wrapper {
  position: relative;
  background-color: $violet;

  &:hover,
  &:active,
  &:focus {

    .recipe-overlay {
      background-color: rgba(0, 0, 0, .5);
    }

    .recipe-overlay-button {
      opacity: 1;
    }
  }
}

.gallery-image {
  height: 30vh !important;
}

.label-time {
  position: relative;
  padding: .25rem .5rem;
  font-size: .75em;
  text-transform: uppercase;
  background-color: $lightviolet;

  &.blank {
    visibility: hidden;
  }
}

.label-likes {
  $size: 1.4rem;

  position: relative;
  display: block;

  .bi {
    font-size: $size;
  }

  .number {
    display: inline-block;
    position: relative;
    top: -6px;
    min-width: 1.2rem;
    min-height: 1rem;
    text-align: center;
    padding: .25rem;
    font-size: .5em;
    font-weight: 600;
    background-color: $violet;
    color: #ffffff;
    border-radius: 100%;
  }

}
</style>
