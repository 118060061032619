<template>
  <div>
    <b-carousel
        v-if="recipe"
        id="carousel-1"
        v-model="slide"
        :interval="interval"
        background="#333333"
        fade
    >
      <b-carousel-slide
          v-for="(image, i) in recipe.images"
          :key="i"
          class="carousel-slide recipe"
          :class="{'no-img': checkImageInCarousel(image)}"
          :style="{
            'background-image': `url(${!image.url ? image.url : defaultImage})`,
            'background-repeat': 'no-repeat',
            'background-size': 'cover',
            'background-position': 'center center'
          }"
          img-blank
      >
        <div
            class="wrapper"
        >
          <b-container fluid="lg">
            <div class="super-heading">How to make</div>
            <h1>{{ recipe.title }}</h1>
          </b-container>
        </div>
        <button-anchor
            v-if="showAnchor"
            @anchorClick="handleAnchorClick"
        >
          Go To Recipe
        </button-anchor>
      </b-carousel-slide>

    </b-carousel>
    <b-carousel
        v-if="recipes"
        id="carousel-2"
        v-model="slide"
        :interval="interval"
        background="#333333"
        controls
        indicators
    >

      <b-carousel-slide
          v-for="recipe in recipes"
          :key="recipe.id"
          class="carousel-slide"
          :style="{
            'background-image': `url(${!recipe.images? recipe.images[0].imageUrl : defaultImage})`,
            'background-repeat': 'no-repeat',
            'background-size': 'cover',
            'background-position': 'center center'
          }"
          img-blank
      >
        <div
            class="wrapper"
        >
          <b-container fluid="xl">
            <h1>{{ recipe.title }}</h1>
            <div class="description">
              {{ recipe.description }}
            </div>
            <a
                @click.prevent="viewRecipe(recipe.id)"
                href="#"
            >
              See Recipe
            </a>
          </b-container>
        </div>
      </b-carousel-slide>

    </b-carousel>

  </div>
</template>

<script>
import ButtonAnchor from "@/components/ButtonAnchor";
import defaultImage from "@/assets/img/alexandr-podvalny-WOxddhzhC1w-unsplash.jpeg";

export default {
  name: 'Carousel',
  components: {ButtonAnchor},
  data() {
    return {
      slide: 0,
      sliding: null,
      interval: 5000,
      defaultImage: defaultImage,
    }
  },
  props: {
    recipe: {
      type: Object
    },
    recipes: {
      type: Array
    },
    showAnchor: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    handleAnchorClick() {
      this.$emit("anchorClick");
    },
    viewRecipe(id) {
      this.$router.push({name: "Recipe", params: {id}, query: {id}})
    },
    checkImageInCarousel(image) {
      if(image.url === null || image.url === undefined) {
        return true;
      }
      if (image.url.includes('image-coming-soon')) {
        return false;
      }
      return true;
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel-slide {
  width: 100%;
  height: 40vh;

  @media (min-width: 768px) {
    height: 700px;
  }

  &.recipe {
    height: 30vh;

    @media (min-width: 768px) {
      height: 480px;
    }

    &.no-img {
      background-color: $mediumbrown !important;
      background-image: none;
    }
  }

  h1 {
    font-weight: 800;
    letter-spacing: -2px;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 1.3rem;
    }
  }

  .description {
    margin: 0 0 .5rem;
    font-size: .75em;

    @media (min-width: 768px) {
      font-size: 1rem;
    }

    @media (min-width: 1200px) {
      width: 65%;
    }
  }

  a {
    text-transform: uppercase;
    color: $violet;
    font-size: .875em;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: darken($violet, 10%);
    }

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  .wrapper {
    padding: 1rem;
    background: rgba(0, 0, 0, 0.75);

    @media (min-width: 768px) {
      padding: 2rem;
    }
  }
}

::v-deep {
  .carousel-caption {
    text-align: left;

    @media (max-width: 991px) {
      padding: 0;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: $violet;
    padding: 1rem;
    height: 5rem;
    border-radius: .25rem;

    @media (max-width: 767px) {
      background-color: transparent;
    }

    @media (max-width: 575px) {
      display: none;
    }
  }

  .carousel-indicators {
    li {
      box-sizing: content-box;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      width: 30px;
      height: 3px;
      margin-right: 3px;
      margin-left: 3px;
      text-indent: -999px;
      cursor: pointer;
      background-color: #fff;
      background-clip: padding-box;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      opacity: .5;
      transition: opacity .6s ease;
    }

    .active {
      opacity: 1;
    }
  }
}

.recipe {
  .wrapper {
    padding: 1rem;
    background: rgba(255, 255, 255, 0.75);

    @media (min-width: 768px) {
      padding: 2rem;
    }
  }

  ::v-deep {
    .carousel-caption {
      padding-top: 0;
      left: 0;
      right: 0;
      top: 0;
      color: #000000;
    }
  }
}
</style>