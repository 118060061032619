var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.recipe)?_c('b-carousel',{attrs:{"id":"carousel-1","interval":_vm.interval,"background":"#333333","fade":""},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.recipe.images),function(image,i){return _c('b-carousel-slide',{key:i,staticClass:"carousel-slide recipe",class:{'no-img': _vm.checkImageInCarousel(image)},style:({
          'background-image': ("url(" + (!image.url ? image.url : _vm.defaultImage) + ")"),
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
          'background-position': 'center center'
        }),attrs:{"img-blank":""}},[_c('div',{staticClass:"wrapper"},[_c('b-container',{attrs:{"fluid":"lg"}},[_c('div',{staticClass:"super-heading"},[_vm._v("How to make")]),_c('h1',[_vm._v(_vm._s(_vm.recipe.title))])])],1),(_vm.showAnchor)?_c('button-anchor',{on:{"anchorClick":_vm.handleAnchorClick}},[_vm._v(" Go To Recipe ")]):_vm._e()],1)}),1):_vm._e(),(_vm.recipes)?_c('b-carousel',{attrs:{"id":"carousel-2","interval":_vm.interval,"background":"#333333","controls":"","indicators":""},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.recipes),function(recipe){return _c('b-carousel-slide',{key:recipe.id,staticClass:"carousel-slide",style:({
          'background-image': ("url(" + (!recipe.images? recipe.images[0].imageUrl : _vm.defaultImage) + ")"),
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
          'background-position': 'center center'
        }),attrs:{"img-blank":""}},[_c('div',{staticClass:"wrapper"},[_c('b-container',{attrs:{"fluid":"xl"}},[_c('h1',[_vm._v(_vm._s(recipe.title))]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(recipe.description)+" ")]),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.viewRecipe(recipe.id)}}},[_vm._v(" See Recipe ")])])],1)])}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }