<template>
  <div class="card podcast-preview shadow-lg mb-5 bg-body rounded" aria-hidden="true">
    <label-tag v-if="podcast.isFree" theme="corner-label">Free</label-tag>
    <img
        :src="podcast.cover"
        class="card-img-top rounded"
        :alt="podcast.title"
        width="220px"
        height="270px"
        @click="viewPodcast"
    />
    <div class="mt-2" v-if="loggedIn">
      <em class="bi bi-pencil-square icon" @click="editPodcast"></em>
    </div>
  </div>
</template>

<script>
import PodcastService from "@/services/podcast/PodcastService";
import LabelTag from "@/components/LabelTag";

export default {
  name: "PodcastPreview",
  components: {LabelTag},
  props: {
    podcast: {
      type: Object,
    },
  },
  computed: {
    loggedIn(){
      return this.$store.state.logged;
    }
  },
  data() {
    return {
      PodcastService: PodcastService
    };
  },
  methods: {
    viewPodcast() {
      this.$emit("viewPodcast", this.podcast);
    },
    editPodcast() {
      this.$router.push({name: "EditPodcast", params: {podcastId: this.podcast.id}});
    }
  }
};
</script>

<style scoped>
.podcast-preview {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: .25rem;
  width: 200px !important;
  border: 0;
  cursor: pointer;
  overflow: hidden;
}

.icon {
  font-size: 1.2rem;
  color: green;
}
</style>
