<template>
  <div>
    <div
        @click="goToCollection(collection)"
        class="collection-box"

    >
      <img :src="collection.leadImage"
           srcset=""
           class="image-featured">
      <div class="collection-row" v-if="collection.images">
        <img :src="collection.images[0].url"
             srcset=""
             class="image-secondary">
        <img :src="collection.images[1].url"
             srcset=""
             class="image-secondary">
        <div class="image-secondary image-wrapper">
          <img :src="collection.images[2].url"
               srcset=""
               class="image-wrapper-img">
          <h3 class="label">
            +{{ collection.size }}
          </h3>
        </div>
      </div>
    </div>
    <div v-if="propIsAdmin" class="d-flex justify-content-between">
      <b-button class="m-1" variant="danger" size="sm" @click="deleteCollection(collection.id)">
        Delete
      </b-button>
      <div>
        <b-button class="m-1" variant="primary" size="sm" @click="editCollection(collection)">Edit
        </b-button>
        <b-button class="m-1" variant="secondary" size="sm" @click="viewCollection(collection)">View
        </b-button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "CollectionItem",
  props: {
    propCollection: {
      type: Object,
      required: true
    },
    propIsAdmin: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      collection: this.propCollection,
      isAdmin: this.propIsAdmin
    }
  },
  methods: {
    goToCollection(collection) {
      this.$router.push({name: 'CollectionView', params: {id: collection.id}})
    },
    deleteCollection() {
      let confirmDelete = confirm("Are you sure you want to delete this collection?")
      if (confirmDelete) {
        this.$store.dispatch('deleteCollection', this.collection.id)
      }
    },
    editCollection() {
      this.$emit('edit-collection', this.collection)
    },
    viewCollection(collection) {
      this.$router.push({name: 'CollectionView', params: {id: collection.id}})

    }
  }

}
</script>

<style lang="scss" scoped>
.collection-box {
  position: relative;
  display: flex;
  flex-direction: column;

  cursor: pointer;
  //transition: filter .25s ease;
  text-decoration: none;
  background-color: transparent;

  //transition: .3s ease-in-out;

  margin: 0 auto;

  &:focus,
  &:hover {
    filter: brightness(.7);
    transition: filter .25s ease;
  }

  @media (min-width: 768px) {
    min-width: 350px;
    max-width: 390px;
  }
}

.collection-name {
  display: block;
  margin-top: 20px;
  padding: 0;
  padding-right: unset;
  padding-left: unset;
  border: none;
  outline: none;
  background: 0 0;

  color: #2c343e;
  fill: #2c343e;

  font-weight: 600;
  font-size: 23px;
  line-height: 36px;
  letter-spacing: -.015em;

  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.image-featured {
  position: relative;
  width: 100%;
  background: #dfdfe0;
  overflow: hidden;
  margin: 0 0 2px;
  min-width: 100%;
  height: 265px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  -o-object-fit: cover;
  object-fit: cover;
}

.collection-row {
  display: flex;
  height: 100px;
  margin-top: 2px;
}

.image-secondary {
  position: relative;
  background: #dfdfe0;
  margin: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;

  &:first-child {
    border-bottom-left-radius: 20px;
  }

  &:nth-child(2) {
    margin: 0 4px;
  }

  &:last-child {
    border-bottom-right-radius: 20px;
  }
}

.image-wrapper {

  .label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: block;
    margin: 0;
    padding: 0;
    padding-right: unset;
    padding-left: unset;
    border: none;
    outline: none;
    background: 0 0;

    color: #fff;
    fill: #fff;

    font-weight: 600;
    font-size: 33px;
    line-height: 40px;
    letter-spacing: -.02em;
  }

  &::before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, .2);
  }
}

.featured {
  background-color: #f2f7f9;
}


.button {
  background: #fec70a;
  margin-top: 1rem;
  box-shadow: none;
  border: none;
  color: black;

}

.button:hover {
  background: #f37033;
  box-shadow: none;
}

.scrollable-row {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin: 0 -15px;
}

</style>