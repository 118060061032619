<template>

  <base-card>
    <template #header>
      <h4 class="mb-0">{{ spice.name }}</h4>
    </template>
    <div class="card-img">
      <div class="img-wrap" @click="viewSpice()" :style="{backgroundImage: `url(${imageUrl})`}">
<!--        <img :src="imageUrl" alt="spice image">-->
      </div>
    </div>

<!--    <b-card-body>-->

<!--      <b-card-sub-title class="mb-2">{{ mealplan.category }}</b-card-sub-title>-->
<!--      <b-card-text>{{ mealplan.description }}</b-card-text>-->
<!--    </b-card-body>-->

    <b-card-footer>
      <b-button
          variant="outline-light"
          @click="viewSpice()"
          v-if="role==='public'"
      >
        View
      </b-button>
      <b-button
          variant="outline-light"
          @click="editSpice()"
          v-if="role==='admin'"
      >
        Edit
      </b-button>
      <b-button
          variant="secondary"
          @click="publishSpice(true)"
          v-if="role === 'admin' && !spice.published"
      >
        Publish
      </b-button>
      <b-button
          variant="danger"
          @click="publishSpice(false)"
          v-if="role === 'admin' && spice.published"
      >
        Unpublish
      </b-button>
      <b-button
          variant="danger"
          @click="deleteSpice()"
          v-if="role === 'admin'"
      >
        Delete
      </b-button>
    </b-card-footer>
  </base-card>

</template>

<script>
import BaseCard from "@/components/bases/BaseCard";

export default {
  name: "SpicePreviewItem",
  components: {BaseCard},
  props: {
    propSpice: {
      type: Object,
      required: true
    },
    role: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      spice: this.propSpice
    };
  },
  watch: {
    propSpice: function (newVal) {
      this.spice = newVal;
    }
  },
  computed: {
    imageUrl() {
      if (this.spice.images.length > 0) {
        return this.spice.images[0].imageUrl;
      } else {
        return "https://via.placeholder.com/300x300";
      }
    }
  },
  methods: {
    publishSpice(status) {
      let confirmPublish = confirm("Are you sure you want to publish this spice?");
      if (!confirmPublish) {
        return;
      }
      this.spice.published = status;
      this.$emit("publishSpice", this.spice)
    },
    viewSpice() {
      this.$emit("viewSpice", this.spice)
    },
    editSpice() {
      this.$emit("editSpice", this.spice)
    },
    async deleteSpice() {
      let confirmDelete = confirm("Are you sure you want to delete this spice?");
      if (!confirmDelete) {
        return;
      }
      await this.$store.dispatch("spices/deleteSpice", this.spice.id);
      this.$toast.success("Spice deleted");
      await this.$router.push({name: "AdminSpicesView"});
    }
  }
}
</script>

<style lang="scss" scoped>

@media only screen and (min-width: 768px) {
  ::v-deep {
    .card-header {
      min-height: 120px;

      h4 {
        font-size: 1.2rem;
      }
    }
  }

}
.card-img {
  padding: 15%;

  .img-wrap {
    cursor: pointer;
    height: 200px;
    mix-blend-mode: soft-light;
    border-radius: 30px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
  }

  img {
    mix-blend-mode: soft-light;
    border-radius: 30px;
  }
}
</style>