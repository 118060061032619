<template>
  <b-container class="my-3">

    <b-button variant="outline" @click="$router.go(-1)">
      <b-icon-arrow-left /> Back
    </b-button>

    <div class="d-flex flex-wrap">

      <div class="d-flex flex-column col-6 p-5">
        <div class="">
          <b-img :src="imageUrl" fluid alt="Responsive image"></b-img>
        </div>
        <div class="d-flex overflow-scroll my-1 border border-1 rounded">
          <div>
            <div>
              <!--              Navigation arrow-->
            </div>
            <b-img
              v-for="(image, index) in spice.images"
              :key="index"
              :src="image.imageUrl"
              fluid
              alt="Responsive image"
              width="100px"
              class="p-1 border"
              @click="setImageUrl(image)"
            />
            <div>
              <!--              Navigation arrow-->
            </div>
          </div>

        </div>
      </div>
      <div class="d-flex flex-column col-6 py-5 px-2">
        <h1>{{ spice.name }}</h1>
        <p>{{ spice.description }}</p>
        <div v-for="tag in spice.tags" :key="tag" class="d-flex my-1">
          <div class="tag tag-pill tag-primary">{{ tag }}</div>
        </div>
        <div v-for="category in spice.category" :key="category" class="d-flex my-1">
          <div class="tag tag-pill tag-success">{{ category }}</div>
        </div>
      </div>

    </div>

  </b-container>

</template>

<script>
export default {
  name: "SpiceItem",
  data() {
    return {
      spice: {
        name: "",
        description: "",
        images: [],
        category: [],
        tags: [],
        price: 0
      },
      storagePath: "spices",
      updateSpice: {},
      reload: 0,
      mode: "Create",
      imageUrl: ""
    };
  },
  computed: {
    leadImageUrl() {
      if (this.imageUrl.length > 0) {
        return this.imageUrl
      }
      return this.spice.images[0].imageUrl
    }
  },
  async created() {
    await this.getSpice()
    this.setLeadImageUrl()
  },
  methods: {
    async getSpice() {
      this.spice = await this.$store.dispatch("spices/getSpice", this.$route.params.id)
    },
    setImageUrl(image) {
      this.imageUrl = image.imageUrl
    },
    setLeadImageUrl() {
      this.imageUrl = this.spice.images[0].imageUrl
    }


  }
}
</script>

<style scoped>
.tag-success {
  color: #fff;
  background-color: #21bb21;
}

.tag-primary {
  color: #fff;
  background-color: #BB2A60FF;
}

.tag-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.tag {
  display: inline-block;
  padding: 0.4em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

</style>