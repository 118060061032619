<template>
  <div class="w-100">
    <div class="my-3" v-if="searchAvailable">
      <!--      Search for image-->
      <b-input-group>
        <b-form-input
            v-model="searchTerm"
            placeholder="Search for image"
            @input="handleSearch"
            class="p-2"
        ></b-form-input>
        <b-input-group-append>
          <b-button variant="" class="p-2">
            <b-icon-search/>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
    <div v-if="images"
         :class="[mixedGrid ? 'grid-mixed' : 'grid']"
         class="my-5">

      <div
          @click="handleImageClick(image, index)"
          v-for="(image, index) in images"
          :key="`${image.url}-${index}`"
          class="grid-item card"
          :title="image.title"

      >

        <lazy-background
            :image-source="image.url"
            :loading-image="defaultLoadingImage"
            :error-image="defaultLoadingImage"
            image-class="placeholder-img"
            background-size="cover">
        </lazy-background>
      </div>
    </div>
    <b-card v-if="images.length === 0" class="text-center">
      <div class="text-danger d-flex justify-content-center align-items-center">
        <b-icon-image scale="3" class="m-5"/>
        <h1>
          No images found
        </h1>
      </div>
    </b-card>
  </div>
</template>

<script>
import GalleryService from "@/services/gallery/GalleryService";


export default {
  name: "GridImages",
  props: {
    propImages: {
      type: Array,
      required: true
    },
    propMixedGrid: {
      type: Boolean,
      default: false
    },
    propGridTitle: {
      type: String,
      default: "Images"
    },
    propSearchAvailable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      defaultLoadingImage: "https://firebasestorage.googleapis.com/v0/b/mpishi/o/gallery%2FGrated%20carrots.jpg?alt=media&token=33e67024-3e4a-4ee6-a877-354e74cc9008",
      showModal: false,
      showInfoModal: false,
      showShareModal: false,
      images: this.propImages,
      mixedGrid: this.propMixedGrid,
      searchAvailable: this.propSearchAvailable,
      searchTerm: "",
      title: this.propGridTitle,
      slide: 0,
      sliding: null,
      interval: 3000,
      controls: true,
      isDisabled: false,
      // controls: !window.matchMedia('only screen and (max-width: 768px)').matches,
      currentImage: {},
      currentIndex: '',
      originalPath: '',
      //images: [],
      index: 0,
      item: {
        id: null,
        title: '',
        description: '',
        url: '',
        tags: [],
        asset_id: '',
        public_id: '',
        created_at: '',
        updated_at: '',
        user: {
          id: null,
          name: '',
          email: '',
          avatar: '',
          created_at: '',
          updated_at: ''
        }
      },
      imageId: null,
      image: {
        title: '',
        description: '',
        secure_url: '',
        likes: []
      },
      hasSharing: false,
      shareUrl: null,
      renderKey: {
        sharing: 0,
        related: 0
      },
      licenses: [],
      cartItem: {
        "image": {
          "id": "",
          "url": ""
        },
        "license": {
          "name": "",
          "price": 0
        }
      }
    }
  },
  watch: {
    propImages() {
      this.images = this.propImages;
    },
    slide: async function (val) {
      let image = this.images[val];
      window.history.pushState("", `Photo - ${image.title}`, `/photo/${image.id}`);
    },
    searchTerm() {
      this.handleSearch();
    },
  },
  computed: {
    likes() {
      if (this.image.likes) {
        return this.image.likes.length;
      }
      return 0;
    },
  },
  methods: {

    randomizeImages(images) {
      // TODO: This needs to be refactored so it checks for those related to the current image by tags
      let randomImages = images.sort(() => Math.random() - 0.5);
      return randomImages
    },

    async getImages() {
      let images = await GalleryService.getItems();
      this.images = this.randomizeImages(images);
      this.loading = false;
    },
    handleImageClick(image, idx) {
      this.$emit('image-clicked', image, idx);
    },
    closeModal() {
      this.showModal = false;
      history.pushState(
          {},
          null,
          this.originalPath
      )
    },
    closeInfoModal() {
      this.showInfoModal = false;
    },
    closeShareModal() {
      this.showShareModal = false;
    },
    async getImage(imageId) {
      return GalleryService.getItem(imageId);
    },
    handleSearch() {
      if (this.searchTerm.length > 0) {
        this.images = this.propImages.filter(image => {
          return image.title.toLowerCase().includes(this.searchTerm.toLowerCase());
        });
      } else {
        this.images = this.propImages;
      }
    },

    async addLike() {
      const userEmail = this.loggedInUser.email;
      if (userEmail === undefined) {
        this.$toast.error('You must be logged in to like an image!');
      } else {
        const image = this.images[this.slide];
        const imageId = image.asset_id;
        let imageLikes = image.likes;
        if (imageLikes === undefined) {
          image.likes = [];
        }
        if (typeof imageLikes === "number") {
          image.likes = [];
          imageLikes = image.likes;
        }
        const imageLiked = imageLikes.includes(userEmail);
        if (imageLiked) {
          this.$toast.success('You already liked this image!');
        } else {
          image.likes.push(userEmail);
          await GalleryService.updateItem(imageId, image);
          this.image = await this.getImage(imageId);
          this.$toast.success('Image liked!');
        }
      }
    }
  },

  mounted() {
    this.loading = true;
    this.interval = 0;
  },
}
</script>

<style lang="scss" scoped>
$timing: 300ms;

::v-deep {
  .modal-body {
    @media (max-width: 767px) {
      padding-bottom: 3rem;
    }
  }

  .carousel-caption {
    position: relative;
    right: auto;
    bottom: auto;
    left: auto;
    padding: 10px;
    color: inherit;
    text-align: left;
  }

  .carousel-control-prev,
  .carousel-control-next {
    height: 2rem;
    top: 300px;
  }

  .carousel-control-prev {
    left: -140px;
  }

  .carousel-control-next {
    right: -140px;
  }
}

.slide {
  .slide-img {
    text-align: center;

    img {
      margin: 0 auto;
      width: 100%;
      border-radius: 33px;

      @media (min-width: 768px) {
        width: auto;
        height: 650px;
      }
    }
  }
}

.placeholder-img {
  height: 100%;
  cursor: pointer;
  background-position: 50% 50%;
  border-radius: 5px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-auto-rows: minmax(160px, auto);
  grid-gap: 20px;
  grid-auto-flow: row dense;

  @media (min-width: 576px) {
    grid-template-columns: repeat(4, auto);
    grid-auto-rows: minmax(180px, auto);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(4, auto);
    grid-auto-rows: minmax(240px, auto);
  }
}

.grid-mixed {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-auto-rows: minmax(160px, auto);
  grid-gap: 20px;
  grid-auto-flow: row dense;

  @media (min-width: 576px) {
    gap: 15px;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 300px 300px;

    .grid-item {
      background: $violet;
      display: block;


      &:nth-child(6n + 1) {
        grid-row: span 2;
      }

      &:nth-child(6n + 2) {
        grid-row: span 2;
        grid-column: span 2;
      }

      &:nth-child(6n + 4) {
        grid-column: 2 / 3;
      }
    }
  }

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 300px 300px 300px 300px;

    .grid-item {
      &:nth-child(6n + 1) {
        grid-row: span 2;
      }

      &:nth-child(6n + 2) {
        grid-row: span 2;
        grid-column: span 2;
      }

      &:nth-child(6n + 4) {
        grid-column: 2 / 3;
      }
    }

  }
}

.card {
  min-height: 100px;
  background-size: cover;
  background-position: 50% 50%;
  background-color: transparent;
  border: 0;
  transition: all $timing ease-in-out;
  overflow: hidden;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -13px;
    margin-left: -13px;
    opacity: 0;
    color: #fff;
    font-family: 'Bootstrap-icons';
    font-size: 26px;
    content: '\F52A';
    pointer-events: none;
    z-index: 9000;
    transition: all $timing ease-in-out;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    content: '';
    cursor: pointer;
    pointer-events: none;
    transition: all $timing ease-in-out;
  }

  &:hover {
    transform: scale(1.025);

    &::before {
      animation: fly-in $timing forwards;
    }

    &::after {
      opacity: 1;
    }
  }
}

@keyframes fly-in {
  from {
    opacity: 0;
    left: 100%;
    transform: scale(5) rotate(-360deg);
  }
  to {
    opacity: 1;
    left: 50%;
    transform: scale(1) rotate(0);
  }
}

.tooltip-wrapper {
  display: inline-block;
}

.tooltip-wrapper.disabled {
  cursor: not-allowed;
}

.btn {
  position: relative;
}


</style>