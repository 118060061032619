<template>
  <div class="col-12">
    <div class="d-flex justify-content-start align-items-center">

      <div class="m-2">
        <div>
          <img src="https://picsum.photos/60" class="rounded rounded-circle float-start border" alt="profile picture">
        </div>
      </div>
      <div class="m-2 col-8">
        <textarea class="col-12" placeholder="Leave your comment" rows="3" v-model="comment.text"></textarea>
      </div>
      <div class="m-2">
        <button class="btn btn-dark" @click="saveComment"><i class="bi bi-send"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {v4 as uuidv4} from 'uuid';
import RecipeService from "@/services/recipe/RecipeService";

export default {
  name: "CommentInput",
  props: {
    reactionId: {
      type: String
    }
  },
  data() {
    return {
      comment: {
        user: {
// TO DO: Get user from logged in objects
          id: "1",
          name: "Anonymous User",
          image: "https://picsum.photos/seed/" + Date.now().toString() + "/60"
        },
        text: "",
        created: Date.now()
      }
    }
  },
  methods: {
    validateComment() {
      if (this.comment.text.length >= 5) {
        return true
      } else {
        this.$toast.warning("You need more than 5 characters to post")
        return false
      }
    },
    updateComment() {
      this.comment.id = uuidv4()
      this.comment.created = Date.now()
      return this.comment
    },
    resetComment() {
      this.comment.text = ""
    },
    async saveComment() {
      if (this.validateComment()) {
        let updatedComment = this.updateComment()
        await RecipeService.addRecipeComment(this.reactionId, updatedComment)
        this.resetComment()
        this.$toast.success("You have commented")
        this.$emit("comment-posted")
      }

    }
  }
}
</script>

<style lang="scss" scoped>

</style>
