const config = {
    categories: {
        main: [
            {"name": "Breakfast & Brunch"},
            {"name": "Lunch", "code": "Lunch"},
            {"name": "Dinner", "code": "Dinner"},
        ],
        general: [
            {"name": "Breads", "code": "Breads"},
            {"name": "Condiments/Spices", "code": "Condiments/Spices"},
            {"name": "Drinks", "code": "Drinks"},
            {"name": "Drink Mixes & Cocktails", "code": "Drink Mixes & Cocktails"},
            {"name": "Sauces", "code": "Sauces"},
            {"name": "Snacks", "code": "Snacks"},
            {"name": "Soups & Broth", "code": "Soups & Broth"},
            {"name": "Vegetarian", "code": "Vegetarian"},
            {"name": "Vinegars & Mayonnaise", "code": "Vinegars & Mayonnaise"},
        ],
        timeBased: [
            {"name": "15-minute meals", "code": "15-minute meals"},
            {"name": "30 minute meals", "code": "30 minute meals"},
            {"name": "1 hour meal", "code": "1 hour meal"}
        ]
    },
    defaultImage: {
        imagePath: null,
        imageUrl: "https://www.electricmirror.com/wp-content/uploads/2022/05/image-coming-soon.jpg"
    },
    storagePath: "recipe/images",
    videoStoragePath: "recipe/videos"
};

module.exports = {...config};
