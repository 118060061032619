<template>
  <footer class="footer">
    <div class="container-xl">
      <div class="row">
        <div class="info-col col-lg-4">
          <div class="info-wrapper">
            <img src="../assets/mpishi-logo.svg" alt="Mpishi" class="logo">
            <p>
              {{description}}
            </p>
          </div>
        </div>
        <div class="links-col col-lg-4">
          <ul>

            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/gallery">Gallery</a>
            </li>

            <li v-for="(document, index) in documents" :key="index">
              <a :href="document.link" target="_blank">{{ document.name }}</a>
            </li>
          </ul>
        </div>
        <div class="social-col col-lg-4">
          <p class="social-label">
            <span>Help us</span>
            <strong>Spread the word</strong>
            <span>about Mpishi</span>
          </p>
          <div class="grid-wrapper">
            <facebook :url="url" :title="socialTitle" :scale="socialScale"></facebook>
            <twitter :url="url" :title="socialTitle" :scale="socialScale"></twitter>
            <linkedin :url="url" :title="socialTitle" :scale="socialScale"></linkedin>
            <telegram :url="url" :title="socialTitle" :scale="socialScale"></telegram>
            <whats-app :url="url" :title="socialTitle" :scale="socialScale"></whats-app>
            <pinterest :url="url" :title="socialTitle" :scale="socialScale"></pinterest>
            <reddit :url="url" :title="socialTitle" :scale="socialScale"></reddit>
            <google :url="url" :title="socialTitle" :scale="socialScale"></google>
            <email :url="url" :subject="socialTitle" :scale="socialScale"></email>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      &copy; Copyright {{ year }}
      <a :href="company.website" target="_blank">{{ company.name }}</a>
    </div>
  </footer>
</template>
<script>
import {
  Facebook,
  Twitter,
  Linkedin,
  Pinterest,
  Reddit,
  Telegram,
  WhatsApp,
  Email,
  Google
} from "vue-socialmedia-share";
export default {
  name: 'MainFooter',
  components: {
    Facebook,
    Twitter,
    Linkedin,
    Pinterest,
    Reddit,
    Telegram,
    WhatsApp,
    Email,
    Google
  },
  data() {
    return {
      year: new Date().getFullYear(),
      company: {
        name: 'Thome Legacy Technologies',
        website: 'https://thometechnology.web.app/'
      },
      description: 'Mpishi is a site for sharing unique and authentic recipes.',
      documents: [
        {
          'name': 'Terms of Use',
          'link': '/legal/terms-of-use.pdf'
        },
        {
          'name': 'Privacy Policy',
          'link': '/legal/privacy-policy.pdf'
        }
      ],
      url: "https://www.mpishi.co",
      socialTitle: "Check out Mpishi",
      socialScale: '2',
    }
  }
}

</script>
<style lang="scss" scoped>
footer {
  margin: auto 0 0;
  background: $brown;
  color: #fff;
  font-size: .9em;

  .info-col {
    .logo {
      margin: 0 0 1rem;
      height: 80px;

      ::v-deep .logo-img {
        height: 100px;
      }
    }

    .info-wrapper {
      height: 100%;

      @media (min-width: 992px) {
        width: 90%;
        padding: 1rem;
        background: rgba(0,0,0,.1);
        border-radius: .25rem;
      }
    }

    p {
      font-size: .875em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .links-col {
    padding: 1.5rem;
    background: rgba(0,0,0,.1);

    @media (min-width: 992px) {
      padding: 0;
      background: none;
    }
  }

  .social-col {
    padding: 1rem;
    background: rgba(255,255,255,.025);
    border-radius: .25rem;

    .social-label {
      display: inline-block;
      margin: 0 0 1rem;
      font-size: 1rem;
      color: $lightred;

      strong, span {
        display: block;
        margin: 0 0 -.125rem;
      }

      span {
        font-size: .75em;
        font-weight: 300;
      }

      strong {
        font-weight: 600;
      }
    }

    .grid-wrapper {
      display: grid;
      grid-template-columns: 32px 32px 32px;
      grid-gap: 10px;

      span {
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
          transition: all 200ms ease-in-out;
        }
      }

      @media (min-width: 375px) {
        float: right;
      }
    }
  }

  .row {
    display: flex;
    margin: 1.5rem 0;

    @media (min-width: 992px) {
      margin: 3rem 0;
    }
  }

  .links-col,
  .social-col {
    margin-top: 2rem;

    @media (min-width: 992px) {
      margin-top: 0;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 0 .5rem;
    }

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .copyright {
    padding: 1rem;
    font-size: .75em;
    text-align: center;
    color: white;
    background: rgba(0,0,0,.25);

    a {
      text-decoration: none;
      color: white;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }

    @media (min-width: 992px) {
      font-size: .875em;
    }
  }

}
</style>
