<template>
  <div class="col-12 m-2 overflow-scroll h-75">
    <div v-for="(comment, index) in comments" :key="index" class="bg-fuchsia">
      <div class="d-flex mt-3 align-items-center">
        <div>
          <img :src="comment.user.image" alt="commenter profile" class="rounded rounded-circle">
        </div>
        <div class="comment-card d-flex flex-column m-3">
          <div class="comment-header d-flex align-items-center justify-content-between">
            <div class="label-user">{{ comment.user.name }}</div>
            <div class="label-timestamp">{{ getRelativeTime(comment.created) }}</div>
          </div>
          <div class="comment-text text-wrap text-start mt-2">
            <span class="text-wrap ">{{ comment.text }}</span>
          </div>
        </div>
        <hr class="border-primary border-3 opacity-75">
      </div>
    </div>
  </div>
</template>

<script>

import moment from "moment";

export default {
  name: "CommentsList",
  props: {
    comments: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    getRelativeTime(epochTime) {
      let timeFormat = 'dddd, MMMM Do, YYYY h:mm:ss A'
      let conversionFactor = 1000
      let toDate = moment.unix(epochTime / conversionFactor).utc();
      return moment(toDate, timeFormat).fromNow();
    },
  }
}
</script>

<style lang="scss" scoped>
.comment-card {
  width: 100%;
}

.comment-header {
  border-bottom: 4px solid #f3f1f1;
}

.label-user {
  font-family: $font-family-cursive;
  font-size: 1.5rem;
  color: $brown;
}

.label-timestamp {
  font-size: .75rem;
  color: $gray2;
}

.comment-text {
  color: $gray3;
}
</style>
