<template>
  <div v-if="items" class="category-preview">
    <b-container
        fluid="xl"
    >
      <div
          v-if="items.length"
          class="title"
          data-aos="fade-down"
      >
        <h3>{{ title }}</h3>
      </div>
      <b-row class="grid d-flex flex-wrap">
        <b-col
          v-for="(item, index) in items"
          :key="index"
          cols="12"
          md="4"
          lg="3"
        >
          <RecipePreviewItem
            :recipe="item"
            data-aos="fade-up"
            :data-aos-delay="(index*10)+50"
          />
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import RecipePreviewItem from "@/components/RecipePreviewItem";

export default {
  name: 'CategoryPreview',
  components: {
    RecipePreviewItem,
  },
  props: {
    title: {
      type: String
    },
    recipes: {
      type: Array
    }
  },
  data() {
    return {
      routeName: this.$route.name,
    }
  },
  computed: {
    items () {
      let tempItems = []
      let tempRecipes = this.recipes.slice(0, 4);
      for(let i = 0; i < tempRecipes.length; i++) {
        tempItems.push(
          tempRecipes[i]
        )
      }
      return tempItems
    }
  },
}
</script>

<style lang="scss" scoped>
.category-preview {
  padding: 2rem 0 1rem;

  @media (min-width: 768px) {
    padding: 3rem 0 2rem;
  }
}

.grid {
  @media (min-width: 768px) {
    margin: 0 -15px;
  }
}

h3 {
  font-weight: 400;
  font-size: 1.2rem;
  margin: .5rem 1rem 1rem;
  padding: 0 0 .5rem;
  letter-spacing: -1.75px;
  color: $violet;
  text-align: center;
  border-bottom: 2px solid $violet;

  @media (min-width: 768px) {
    margin: 1rem 0 2rem;
    font-size: 1.5rem;
  }
}

</style>