<template>
  <div>
    <div class="mb-3">
      <label class="form-label">{{ label }}</label>
      <textarea class="form-control" :id="id" @input="updateValue" :value="modelValue"> </textarea>
    </div>
  </div>
</template>

<script>

export default {
  name: "BaseTextArea",
  props: {
    label: {
      type: String,
      required: true,
      default: ""
    },
    id: {
      type: String,
      required: false,
    },
    modelValue: {
      type: String,
      required: false,
      default: ""
    }

  },
  watch: {
    modelValue: {
      handler(newValue) {
        this.$emit("handleInput", newValue);
        this.emitValue(newValue);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    updateValue(event) {
      this.$parent.$emit('input', event.target.value);
      this.$emit('input', event.target.value);
    },
    emitValue(value) {
      this.$emit('input', value);
    }
  }
}
</script>

<style scoped>

</style>
