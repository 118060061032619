<template>
  <div class="d-flex flex-column">
<!--    <div class="mb-2" v-if="role==='admin'">-->
<!--      <b-button variant="info" @click="showAddRecipeToMealPlan">Add recipe to plan</b-button>-->
<!--    </div>-->
    <div class="d-flex justify-content-between mb-3">
      <b-button variant="outline-light" @click="$router.go(-1)">
        <b-icon-arrow-left /> Back
      </b-button>

      <b-button v-if="role==='admin'" variant="info" @click="showAddRecipeToMealPlan">Add recipe to plan</b-button>

    </div>

    <div class="meal-plan-heading m-2">

      <h1 class="title">{{ mealPlan.title }}</h1>
      <h3 class="category">{{ mealPlan.category }}</h3>
      <p class="desc">{{ mealPlan.description }}</p>
      <b-badge variant="warning">{{ mealPlan.calories }}</b-badge>

    </div>
    <div class="d-flex justify-content-start align-content-start flex-wrap">
      <b-card
          v-for="dailyPlan in propMealPlanMenu"
          :key="dailyPlan.day"
          :title="dailyPlan.day.toUpperCase()"
          :sub-title="dailyPlan.day"
          class="m-2 flex-shrink-1"
      >
        <div v-if="dailyPlan.menu">
          <div v-for="menu in dailyPlan.menu" :key="menu.id">
            <h6 class="text-capitalize"><u>{{ menu.type }}</u></h6>

            <ul class="list-group-flush">
              <li v-for="recipe in menu.recipes" :key="recipe.id" class="list-group-item">
                <div>
                  {{ recipe.recipe }}
                  <a :href="generateUrl(recipe.id)">
                    <b-card-header>{{ recipe.title }}</b-card-header>

                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </b-card>
    </div>
    <div v-if="role==='admin'">
      <hr>
      <div class="d-flex justify-content-end col-12">
        <b-button variant="primary" @click="saveMealPlan" class="mr-1">Save</b-button>
        <b-button variant="secondary" @click="cancelMealPlan">Cancel</b-button>
      </div>
    </div>
    <add-recipe-to-meal-plan-modal v-if="role==='admin'" :meal-plan="currentMealPlanId" :recipes="recipes"
                                   @save-recipe-to-meal-plan="saveRecipeToMenu"/>
  </div>
</template>
<script>
import DailyMenuService from "@/services/plan/DailyMenuService";
import AddRecipeToMealPlanModal from "@/components/plans/AddRecipeToMealPlanModal.vue";
import RecipeService from "@/services/recipe/RecipeService";
import defaultImage from "@/assets/img/syd-wachs-epqNIYI6S7E-unsplash.jpeg";
import PlansService from "@/services/plan/PlansService";

export default {
  name: "DailyMealPlan",
  components: {AddRecipeToMealPlanModal},
  props: {
    role: {
      type: String,
      required: true,
      default: 'public',
    },
    propMealPlan: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
          title: '',
          category: '',
          description: '',
        }
      }
    },
    propMealPlanMenu: {
      type: Array,
      required: true,
      default: () => [
        {
          id: 1,
          day: "Monday",
          menu: []
        },
        {
          id: 2,
          day: "Tuesday",
          menu: []
        },
        {
          id: 3,
          day: "Wednesday",
          menu: []
        },
        {
          id: 4,
          day: "Thursday",
          menu: []
        },
        {
          id: 5,
          day: "Friday",
          menu: []
        },
        {
          id: 6,
          day: "Saturday",
          menu: []
        },
        {
          id: 7,
          day: "Sunday",
          menu: []
        }
      ],
    }
  },
  data() {
    return {
      defaultImage: defaultImage,
      mealPlanMenu: [],
      currentMealPlanId: "",
      recipes: [],
      mealPlan: {
        id: "",
        name: "",
        title: "",
        description: "",
        startDate: "",
        endDate: "",
        category: "",
        calories: "",
      }
    }
  },
  watch: {
    propMealPlanMenu: function (newVal) {
      this.mealPlanMenu = newVal;
    },
    propMealPlan: function (newVal) {
      this.mealPlan = newVal;
    }
  },
  mounted() {
    this.getMealPlanMenu()
    this.getRecipes();
  },
  methods: {
    generateUrl(id) {
      return "/recipe?id=" + id
    },
    async getRecipes() {
      this.recipes = Object.values(await RecipeService.getRecipes(false));
    },
    showAddRecipeToMealPlan() {
      this.currentMealPlanId = this.$route.params.id;
      this.$bvModal.show('add-recipe-to-meal-plan');
    },
    async getMealPlanMenu() {
      this.mealPlan = await PlansService.getPlanById(this.$route.params.id);
      let mealPlanDetails = await DailyMenuService.getMenuById(this.$route.params.id);
      if (mealPlanDetails.menu !== undefined) {
        this.mealPlanMenu = mealPlanDetails.menu;
      }
    },
    saveRecipeToMenu(recipeDetails) {
      this.$emit('save-recipe-to-meal-plan', recipeDetails);
    },
    saveMealPlan() {
      this.$emit('save-meal-plan', this.propMealPlanMenu);
    },
    cancelMealPlan() {
      this.$emit('cancel-meal-plan');
    }
  }
}
</script>

<style scoped>
.meal-plan-heading {
  color: #ffffff;
}
</style>