<template>
  <div class="loader" :class="{large: large}">
    <span class="loader-span" />
  </div>
</template>

<script>
export default {
  name: 'BaseLoader',
  props: {
    large: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.loader {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.loader-span {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  border: 4px solid #cccccc;
  -webkit-animation: fill 2s linear infinite alternate;
  animation: fill 2s linear infinite alternate;
  color: $violet;
  border-radius: 0 0 4px 4px;

  &::after {
    content: "";
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    border: 4px solid #cccccc;
    width: 20px;
    height: 25px;
    border-radius: 0 4px 4px 0;
  }
}

.large {
  .loader-span {
    width: 120px;
    height: 120px;
    border: 12px solid #cccccc;

    &::after {
      border: 12px solid #cccccc;
      width: 60px;
      height: 75px;
    }
  }
}

@keyframes fill {
  0%{ box-shadow: 0 0  inset }
  100%{ box-shadow: 0 -48px inset }
}

</style>