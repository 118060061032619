<template>
  <div>

    <section class="page-section spice-hero clearfix">
      <div class="container">
        <div class="product-item">
          <div class="product-item-title d-flex">
            <div
                class="product-item-title-inner bg-lightwood p-5 me-auto border-radius-33"
                data-aos="fade-right"
            >
              <h2 class="section-heading mb-0">
                <span class="section-heading-upper">Appetizing, aromatic, distinctive</span>
                <span class="section-heading-lower">Spices</span>
              </h2>
            </div>
          </div>
          <img
              class="product-item-img mx-auto border-radius-15 img-fluid"
              :src="defaultImage"
              alt="Spices"
              data-aos="fade-left"
          >
          <div
              class="product-item-description d-flex ms-auto"
              data-aos="fade-up"
              data-aos-delay="100"
          >
            <div class="bg-darkwood p-5 border-radius-33">
              <p class="mb-0 text-white">
                Spices are aromatic substances obtained from various parts of plants, such as roots, seeds, bark, or fruits. They are used to add flavor, aroma, and color to food, and have been valued for their medicinal and culinary properties for centuries.
              </p>
              <p class="mt-3">
                <base-button
                    variant="dark"
                    @click.native="scrollToElement('spiceSection')"
                    class="scroll-button"
                    data-aos="fade-up"
                    data-aos-delay="100"
                >
                  <b-icon-arrow-down class="me-1" /> See All Spices
                </base-button>

              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="page-section section-main spice-section"
      :style="{'background-image': `url(${spiceSectionBackground})`}"
    >
      <b-container>
        <h3 ref="spiceSection" class="heading" data-aos="fade-left">All Spices</h3>

        <base-section-loader v-if="loading" />

        <b-row>
          <b-col
            v-for="(spice, index) in spices"
            :key="index"
            class="column"
            cols="12"
            md="6"
            lg="3"
          >
            <spice-preview-item
              :propSpice="spice"
              :role="role"
              @viewSpice="viewSpice"
              class="card"
              data-aos="fade-up"
            />

          </b-col>
        </b-row>
      </b-container>
    </section>

  </div>

</template>

<script>
import SpicePreviewItem from "@/components/spices/SpicePreviewItem.vue";
import defaultImage from "@/assets/img/zahrin-lukman-VSNoQdimlQQ-unsplash.jpg";
import spiceSectionBackground from "@/assets/img/jimmy-dean-my1mDMraGf0-unsplash.jpg";
import BaseButton from "@/components/bases/BaseButton";
import BaseSectionLoader from "@/components/bases/BaseSectionLoader";

export default {
  name: "SpicesView",
  components: {
    BaseSectionLoader,
    BaseButton,
    SpicePreviewItem
  },
  data() {
    return {
      defaultImage: defaultImage,
      spiceSectionBackground: spiceSectionBackground,
      spices: [],
      loading: null,
      role: "public",

    };
  },
  mounted() {
    this.getSpices();
  },
  methods: {
    scrollToElement(refName) {
      const element = this.$refs[refName];
      element.scrollIntoView({ behavior: "smooth" });
    },
    async getSpices() {
      this.loading = true;
      this.spices = await this.$store.dispatch("spices/getSpices", {publishedOnly: true});
      this.loading = false;
    },
    viewSpice(spice) {
      this.$router.push({name: "SpiceView", params: {id: spice.id}});
    },
  }
}
</script>

<style lang="scss" scoped>
.spice-section {
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
  background-repeat: no-repeat;
}

.heading {
  font-family: $font-family-cursive;
  font-size: 3rem;
  padding-top: 2rem;
  padding-bottom: 3rem;
  color: #ffffff;
}

.column {
  padding-bottom: 1.5rem;
}

@media only screen and (max-width: 767px) {
  .heading {
    text-align: center;
    font-size: 4rem;
  }

  .scroll-button {
    font-size: 1.125rem;

    .b-icon.bi {
      font-size: 1.125rem;
    }
  }
}

@media only screen and (max-width: 575px) {
  .spice-section {
    background-image: none !important;
  }
}

@media only screen and (max-width: 991px) {
  .btn,
  h3,
  .heading
  {
    opacity: 1 !important;
    transform: translate(0) scale(1) !important;
  }
}
</style>