<template>
  <div class="container-xl">
    <base-section-loader v-if="loading"/>
    <grid-images :prop-images="images" :prop-mixed-grid="mixedGrid" @image-clicked="handleImageClick"/>
    <photo-view-modal :prop-is-admin="true" :prop-current-image="currentImage"
                      @close-modal="closeModal" v-if="currentImage" @download-image="downloadImage"
                      @add-like="addLike" @update-image="handleUpdateImage"
                      @delete-image="handleDeleteImage"></photo-view-modal>
  </div>
</template>

<script>
import GalleryService from "@/services/gallery/GalleryService";
import GridImages from "@/components/GridImages.vue";
import PhotoViewModal from "@/components/PhotoViewModal.vue";

export default {
  name: 'AdminGalleryView',
  components: {PhotoViewModal, GridImages},
  props: {
    propMixedGrid: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      GalleryService: GalleryService,
      showModal: false,
      mixedGrid: false,
      requiresAuth: false,
      slide: 0,
      sliding: null,
      interval: 3000,
      controls: !window.matchMedia('only screen and (max-width: 768px)').matches,
      currentImage: {},
      currentIndex: '',
      originalPath: '',
      images: [],
      index: 0,
      item: {
        id: null,
        title: '',
        description: '',
        url: '',
        tags: [],
        asset_id: '',
        public_id: '',
        created_at: '',
        updated_at: '',
        user: {
          id: null,
          name: '',
          email: '',
          avatar: '',
          created_at: '',
          updated_at: ''
        }
      },
      imageId: null,
      image: {
        title: '',
        description: '',
        secure_url: '',
        likes: []
      },
      hasSharing: false,
      shareUrl: null,
      renderKey: {
        sharing: 0,
        related: 0
      },
      licenses: [],
      cartItem: {
        "image": {
          "id": "",
          "url": ""
        },
        "license": {
          "name": "",
          "price": 0
        }
      }
    }
  },

  computed: {
    likes() {
      if (this.image.likes) {
        return this.image.likes.length;
      }
      return 0;
    },
  },
  watch: {
    slide: async function (val) {
      let image = this.images[val];
      window.history.pushState("", `Photo - ${image.title}`, `/photo/${image.id}`);
    }

  },
  mounted() {
    this.getImages();
    this.interval = 0;
  },
  methods: {
    downloadImage(image) {
      let link = document.createElement('a');
      link.href = image.url;
      link.download = image.title;
      link.click();
      link.remove();
    },
    handleInput(input, values) {
      this.item[input] = values;
    },
    handleUpdateImage(image) {
      GalleryService.updateItem(image.id, image).then(() => {
        this.$toast.success('Image updated successfully');
      });
    },
    handleDeleteImage(item, index) {
      GalleryService.deleteItem(item).then(() => {
        this.images.splice(index, 1);
      });
    },
    async getImages() {
      let images = await GalleryService.getItems()
      this.images = images;
    },
    handleImageClick(image, idx) {
      this.currentImage = image;
      this.currentIndex = idx;
      this.$bvModal.show('photo-view-modal');
      this.slide = idx;
      this.showModal = true;
      this.originalPath = this.$route.path;
      let id = image.id || image.asset_id;
      window.history.pushState("", `Photo - ${image.title}`, `/photo/${id}`);
    },
    closeModal() {
      this.$bvModal.hide('photo-view-modal');
      history.pushState(
          {},
          null,
          this.originalPath
      )
    },
    async getImage(imageId) {
      return GalleryService.getItem(imageId);
    },

    async addLike() {
      const userEmail = this.loggedInUser.email;
      if (userEmail === undefined) {
        this.$toast.error('You must be logged in to like an image!');
      } else {
        const image = this.images[this.slide];
        const imageId = image.asset_id;
        let imageLikes = image.likes;
        if (imageLikes === undefined) {
          image.likes = [];
        }
        if (typeof imageLikes === "number") {
          image.likes = [];
          imageLikes = image.likes;
        }
        const imageLiked = imageLikes.includes(userEmail);
        if (imageLiked) {
          this.$toast.success('You already liked this image!');
        } else {
          image.likes.push(userEmail);
          await GalleryService.updateItem(imageId, image);
          this.image = await this.getImage(imageId);
          this.$toast.success('Image liked!');
        }
      }
    }
  }
  ,

}
</script>

<style lang="scss" scoped>
$timing: 300ms;

::v-deep {
  .carousel-caption {
    position: relative;
    right: auto;
    bottom: auto;
    left: auto;
    padding: 10px;
    color: inherit;
    text-align: left;
  }

  .carousel-control-prev,
  .carousel-control-next {
    height: 2rem;
    top: 300px;
  }

  .carousel-control-prev {
    left: -140px;
  }

  .carousel-control-next {
    right: -140px;
  }
}

.slide {
  .slide-img {
    text-align: center;

    img {
      margin: 0 auto;
      max-width: 550px;
      height: auto;
      border-radius: 33px;

      @media (min-width: 768px) {
        width: auto;
        height: 650px;
      }
    }
  }
}

.placeholder-img {
  height: 100%;
  cursor: pointer;
  background-position: 50% 50%;
  border-radius: 5px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-auto-rows: minmax(160px, auto);
  grid-gap: 20px;
  grid-auto-flow: row dense;

  @media (min-width: 576px) {
    grid-template-columns: repeat(4, auto);
    grid-auto-rows: minmax(180px, auto);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(4, auto);
    grid-auto-rows: minmax(240px, auto);
  }
}

.grid-mixed {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-auto-rows: minmax(160px, auto);
  grid-gap: 20px;
  grid-auto-flow: row dense;

  @media (min-width: 576px) {
    gap: 15px;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 300px 300px;

    .grid-item {
      background: #fec70a;

      &:nth-child(6n + 1) {
        grid-row: span 2;
      }

      &:nth-child(6n + 2) {
        grid-row: span 2;
        grid-column: span 2;
      }

      &:nth-child(6n + 4) {
        grid-column: 2 / 3;
      }
    }
  }

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 300px 300px 300px 300px;

    .grid-item {
      &:nth-child(6n + 1) {
        grid-row: span 2;
      }

      &:nth-child(6n + 2) {
        grid-row: span 2;
        grid-column: span 2;
      }

      &:nth-child(6n + 4) {
        grid-column: 2 / 3;
      }
    }

  }
}

.card {
  min-height: 100px;
  background-size: cover;
  background-position: 50% 50%;
  background-color: transparent;
  border: 0;
  transition: all $timing ease-in-out;
  overflow: hidden;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -13px;
    margin-left: -13px;
    opacity: 0;
    color: #fff;
    font-family: 'Bootstrap-icons';
    font-size: 26px;
    content: '\F52A';
    pointer-events: none;
    z-index: 9000;
    transition: all $timing ease-in-out;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(46, 132, 206, 0.7);
    content: '';
    cursor: pointer;
    pointer-events: none;
    transition: all $timing ease-in-out;
  }

  &:hover {
    transform: scale(1.025);

    &::before {
      animation: fly-in $timing forwards;
    }

    &::after {
      opacity: 1;
    }
  }
}

@keyframes fly-in {
  from {
    opacity: 0;
    left: 100%;
    transform: scale(5) rotate(-360deg);
  }
  to {
    opacity: 1;
    left: 50%;
    transform: scale(1) rotate(0);
  }
}

.tooltip-wrapper {
  display: inline-block;
}

.tooltip-wrapper.disabled {
  cursor: not-allowed;
}

.btn {
  position: relative;
}

</style>