<template>
  <div
      ref="searchbar"
      class="searchbar"
  >
    <b-container fluid="xl">
      <b-form @submit.prevent="handleSubmit()">
        <b-row class="row-search">
          <b-col cols="12">
            <div class="search-heading my-1">
              <label class="text-white fw-bold">Search the recipes</label>
            </div>
            <div>
              <b-input-group>
                <b-input-group-prepend>
                  <b-button
                      type="submit"
                      variant="outline-dark"
                      class="search-btn"
                  >
                    <b-icon icon="search"/>
                  </b-button>
                </b-input-group-prepend>

                <b-form-input
                    v-model="searchQuery"
                    type="text"
                    :placeholder="placeHolder"
                    @input="handleSubmit"
                    class="search-input"
                ></b-form-input>

                <b-input-group-append>
                  <b-button
                      @click="clear('searchQuery')"
                      variant="secondary"
                  >
                    <b-icon icon="x"/>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>

        </b-row>
        <div class="toggle" v-if="searchQuery">
          <b-link
              class="toggle-link"
              :class="filtersVisible ? null : 'collapsed'"
              :aria-expanded="filtersVisible ? 'true' : 'false'"
              aria-controls="collapse-1"
              @click="filtersVisible = !filtersVisible"
          >
            <b-icon-chevron-down
                :rotate="!filtersVisible ? 0 : 180"
            />
            <span class="toggle-label">
              {{ !filtersVisible ? 'Show filters' : 'Hide filters' }}
            </span>
          </b-link>
          <b-collapse
              v-model="filtersVisible"
              id="collapse-1"
          >
            <b-row class="row-filters">
              <b-col cols="12" md="3">
                <b-form-select
                    v-if="categoryOptions"
                    v-model="selectedCategory"
                    @change="handleCategoryChange"
                >
                  <b-form-select-option
                      :value="null"
                      class="select-option-default"
                  >
                    Category
                  </b-form-select-option>
                  <b-form-select-option
                      v-for="option in categoryOptions"
                      :value="option"
                      :key="option"
                      class="select-option"
                  >
                    {{ option }}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
              <b-col cols="12" md="3">
                <b-form-select
                    v-if="subcategoryOptions"
                    v-model="selectedSubcategory"
                    @change="handleSubcategoryChange"
                >
                  <b-form-select-option
                      :value="null"
                      class="select-option-default"
                  >
                    Subcategory
                  </b-form-select-option>
                  <b-form-select-option
                      v-for="option in subcategoryOptions"
                      :value="option"
                      :key="option"
                      class="select-option"
                  >
                    {{ option }}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
              <b-col cols="12" md="3">
                <b-form-select
                    v-if="cuisineOptions"
                    v-model="selectedCuisine"
                    @change="handleCuisineChange"
                >
                  <b-form-select-option
                      :value="null"
                      class="select-option-default"
                  >
                    Cuisine
                  </b-form-select-option>
                  <b-form-select-option
                      v-for="option in cuisineOptions"
                      :value="option"
                      :key="option"
                      class="select-option"
                  >
                    {{ option }}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
          </b-collapse>
        </div>
      </b-form>
    </b-container>
  </div>
</template>
<script>
export default {
  name: 'SearchBar',
  props: {
    placeHolder: {
      type: String
    },
    searchTerm: {
      type: String
    },
    ingredientTerm: {
      type: String
    },
    categoryOptions: {
      type: Array
    },
    subcategoryOptions: {
      type: Array
    },
    cuisineOptions: {
      type: Array
    }
  },
  data() {
    return {
      filtersVisible: false,
      searchQuery: this.searchTerm,
      ingredientQuery: this.ingredientTerm,
      selectedCategory: null,
      selectedSubcategory: null,
      selectedCuisine: null,
    }
  },
  methods: {
    clear(queryName) {
      if (queryName === 'searchQuery') {
        this.searchQuery = null;
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.searchQuery,
          }
        });
      }
    },

    resetAll() {
      this.searchQuery = null;
      this.ingredientQuery = null;
      this.selectedCategory = null;
      this.selectedSubcategory = null;
      this.selectedCuisine = null;
    },
    handleSubmit() {
      if (this.searchQuery === '') {
        this.resetAll();
      }
      if (this.searchQuery.length > 3) {
        this.scroll();
        this.appendQuery('search', this.searchQuery)
        this.$emit("handleSearch", this.searchQuery);
      }
    },
    handleCategoryChange() {
      this.$emit("handleCategoryChange", this.selectedCategory);
    },
    handleSubcategoryChange() {
      this.$emit("handleSubcategoryChange", this.selectedSubcategory);
    },
    handleCuisineChange() {
      this.$emit("handleCuisineChange", this.selectedCuisine);
    },

    appendQuery(queryName, type) {
      this.$router.push({
        query: {
          ...this.$route.query,
          [queryName]: type
        }
      });
    },
    scroll() {
      const element = this.$refs.searchbar;
      const top = element.offsetTop;

      window.scrollTo(0, top);
    },
  }
}
</script>
<style lang="scss" scoped>
.searchbar {
  position: relative;
  background-color: $darkviolet;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (min-width: 768px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .input-group {
    min-height: 60px;
    z-index: 1020;
  }

  .btn {
    height: 100%;
  }

}

.search-input {
  height: auto;
}

.search-btn {
  background-color: #ffffff;
  border-color: #ced4da;
  border-right: none;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    color: #ffffff;
    background-color: $darkgray;
  }
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.row-search {
  margin: 0 0 1rem;
}

.row-filters {
  margin: 0;
}

.radio-group {
  margin: 0 0 .5rem;
}

::v-deep {
  .custom-radio {
    display: inline-block;
    margin: 0 1rem 0 0;
    color: #ffffff;

    .custom-control-input {
      margin: 0 .5rem 0 0;
    }
  }
}

.custom-select {
  width: 100%;
  margin: 0 0 1rem;
  padding: 0.5rem;
  color: white;
  background-image: none;
  background-color: rgba(255, 255, 255, .05);
  border: 0;
  border-radius: 0;
  border-top: 2px solid white;
  border-bottom: 2px solid $violet;
  text-transform: capitalize;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  @media (min-width: 768px) {
    margin: 0;
  }
}

.select-option-default {
  color: $violet;
}

.toggle {
  .toggle-link {
    color: #ffffff;
    text-decoration: none;
    padding: 0 0 0 1rem;
    font-size: .75em;
    transition: all 250ms ease;
  }

  .collapse {
    padding-top: 1rem;
  }
}
</style>
