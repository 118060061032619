<template>
  <div>
    <section class="page-section meal-plan-hero clearfix">
      <b-container fluid="xl" class="position-relative">
        <div class="intro">
          <div
              class="intro-text border-radius-33 bg-lightwood left-0 text-center p-5 mb-3 mb-lg-0"
              data-aos="fade-left"
          >
            <h2 class="section-heading mb-4">
              <span class="section-heading-upper">Daily Recipe</span>
              <span class="section-heading-lower">Meal Plans</span>
            </h2>
            <p class="mb-3">
              Meal planning is a fun way to save time, money, and stress by organizing your meals ahead of time. With a set of delicious and nutritious recipes, you'll always have ingredients on hand and never get bored with your meals. Give it a try and become your own personal chef!
            </p>
          </div>
          <img
              class="intro-img border-radius-15 img-fluid "
              :src="defaultImage"
              alt="Meal Plans"
              data-aos="fade-right"
          >
        </div>
      </b-container>
      <div class="container position-relative">

      </div>
    </section>

    <section id="mealplanSection" class="page-section section-main">
      <b-container
          fluid="xl"
      >
        <router-view
            :role="role"
            :propMealPlans="mealPlans"
            @navigate-to-meal-plan="navigateToMealPlan"
            :propMealPlanMenu="currentMealPlanMenu"
            @cancel-meal-plan="cancelMealPlan"
            :propMealPlan="currentMealPlan"
        />
      </b-container>
    </section>

  </div>
</template>

<script>
import PlansService from "@/services/plan/PlansService";
import DailyMenuService from "@/services/plan/DailyMenuService";
import defaultImage from "@/assets/img/syd-wachs-epqNIYI6S7E-unsplash.jpeg";
export default {
  name: "MealPlanView",
  components: {},
  data() {
    return {
      defaultImage: defaultImage,
      mealPlans: [],
      mode: 'view',
      currentMealPlan: {},
      reload: 0,
      currentMealPlanId: "",
      currentMealPlanMenu: [
        {
          id: 1,
          day: "Monday",
          menu: []
        },
        {
          id: 2,
          day: "Tuesday",
          menu: []
        },
        {
          id: 3,
          day: "Wednesday",
          menu: []
        },
        {
          id: 4,
          day: "Thursday",
          menu: []
        },
        {
          id: 5,
          day: "Friday",
          menu: []
        },
        {
          id: 6,
          day: "Saturday",
          menu: []
        },
        {
          id: 7,
          day: "Sunday",
          menu: []
        }
      ],
      role: 'public',
      recipes: [],
      planTemplate: {
        id: "",
        day: "",
        menu: [
          {
            "id": "",
            "type": "",
            "recipes": []
          },
        ]
      },
    }
  },
  mounted() {
    this.getMealPlans();
  },
  methods: {
    async cancelMealPlan() {
      await this.$router.push({name: 'PublicMealPlanView'});
    },
    async getMealPlans() {
      let mealPlans = Object.values(await PlansService.getPlans());
      mealPlans = mealPlans.filter(plan => plan.isPublic === true);
      this.mealPlans = mealPlans;
    },
    async navigateToMealPlan(mealPlan) {
      this.currentMealPlan = mealPlan;
      this.currentMealPlanId = mealPlan.id;
      let dailyMealPlan = await DailyMenuService.getMenuById(mealPlan.id);
      if (dailyMealPlan.menu !== undefined) {
        this.currentMealPlanMenu = dailyMealPlan.menu;
      }
      await this.$router.push({name: 'PublicMealPlan', params: {id: mealPlan.id, mealPlan: mealPlan}});
    }

  }
}
</script>

<style lang="scss" scoped>

</style>
