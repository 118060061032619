<template>
  <b-container fluid="xl" class="photo-view">
    <base-section-loader v-if="loading"/>
    <div v-if="!loading" class="photo-wrapper d-flex flex-column">
      <div class="photo-details">
        <h1 class="heading">{{ image.title }}</h1>
        <p class="description">{{ image.description }}</p>
      </div>
      <img :src="image.url" :alt="image.title || 'A meal'" class="image">

      <div class="d-flex justify-content-between my-3">
        <div>
          <base-button @click.native="addLike" class="like-button">
            <b-icon-heart-fill/>
            Like
          </base-button>
        </div>
        <div>
          <base-button @click.native="downloadImage" class="download-button">
            <b-icon-arrow-bar-down/>
            Download
          </base-button>
        </div>
        <div>
          <social-sharing :image="image" :twitter="true"/>
        </div>
      </div>
    </div>
    <div v-if="images.length > 0" class="related-images container-xl">
      <b-card title="Related Images">
        <grid-images
            :prop-images="images"
            :prop-cols="4"
            :prop-search-available="false"
            @image-clicked="handleImageClick"
        />
        <photo-view-modal v-if="images" :prop-is-admin="false" :prop-current-image="currentImage"
                      @close-modal="closeModal"  @download-image="downloadImage"
                      @add-like="addLike" :prop-carousel-images="images"></photo-view-modal>
      </b-card>

    </div>

  </b-container>

</template>
<script>
import GalleryService from "@/services/gallery/GalleryService";
import BaseSectionLoader from "@/components/bases/BaseSectionLoader";
import GridImages from "@/components/GridImages.vue";
import SocialSharing from "@/components/SocialSharing.vue";
import BaseButton from "@/components/bases/BaseButton.vue";
import PhotoViewModal from "@/components/PhotoViewModal.vue";

export default {
  name: "PhotoView",
  components: {
    PhotoViewModal,
    BaseButton,
    SocialSharing,
    GridImages,
    BaseSectionLoader
  },
  data() {
    return {
      GalleryService: GalleryService,
      imageId: null,
      image: {},
      loading: null,
      images: [],
      currentImage: {},
    }
  },
  mounted() {
    this.setImage();
    this.getImages();
  },
  methods: {
    closeModal(){
      this.$bvModal.hide('photo-view-modal');
    },
    addLike() {
      this.image.likes++;
      this.GalleryService.updateItem(this.image);
      this.$toast.success('You liked this image');
    },
    async setImage() {
      this.loading = true;
      this.imageId = this.$route.params.id;
      this.image = await this.GalleryService.getItem(this.imageId);
      this.loading = false;
    },
    async getImages() {
      let images = await GalleryService.getItems();
      this.images = this.randomizeImages(images);
      this.loading = false;
    },
    randomizeImages(images) {
      // TODO: This needs to be refactored so it checks for those related to the current image by tags
      let randomImages = images.sort(() => Math.random() - 0.5);
      return randomImages.slice(0, 12);
    },
    downloadImage(image) {
      let link = document.createElement('a');
      link.href = image.url;
      link.download = image.title;
      link.click();
      link.remove();
    },
    handleImageClick(image, idx) {
      this.currentImage = image;
      this.currentIndex = idx;
      this.slide = idx;
      this.showModal = true;
      this.originalPath = this.$route.path;
      let id = image.id || image.asset_id;
      window.history.pushState("", `Photo - ${image.title}`, `/photo/${id}`);
      this.$bvModal.show('photo-view-modal');
    }
  },
}
</script>
<style lang="scss" scoped>
.photo-view {
  margin: 2rem auto;
  text-align: center;

  @media (min-width: 992px) {
    margin: 3rem auto;
    text-align: left;
  }
}

.photo-actions {
  margin: 2rem auto;

  > div {

    @media (max-width: 450px) {
      display: block;
      text-align: center;

      .download-button {
        margin-bottom: 2rem;
      }
    }

  }

  @media (min-width: 450px) {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    width: 85%;
  }
}

.photo-wrapper {
  position: relative;

  @media (min-width: 992px) {
    top: -90px;
  }
}

.heading {
  display: inline-block;
  position: relative;
  padding: 1rem;
  margin: 0 auto 1rem;
  font-size: 1rem;
  background: $lightwood;
  border-radius: 17px;

  @media (min-width: 992px) {
    font-size: 1.125rem;
    margin: 0;
    padding: 2rem;
    bottom: -90px;
    left: 32px;
    z-index: 3;
    transform: rotate(-2deg);
  }

}

.description {
  position: relative;
  text-align: center;

  @media (min-width: 992px) {
    text-align: right;
    margin-right: 1rem;
  }

}

.image {
  position: relative;
  border-radius: 33px;
}

</style>
