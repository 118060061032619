<template>
  <b-row>
    <b-col
        v-for="(item, index) in paginatedItems"
        :key="index"
        cols="12"
        md="4"
        lg="3"
    >
      <recipe-preview-item :recipe="item" data-aos="fade-up" :data-aos-delay="(index*10)+50"/>
    </b-col>
  </b-row>

</template>

<script>
import RecipePreviewItem from "@/components/RecipePreviewItem.vue";

export default {
  name: "PaginatedRecipes",
  components: {RecipePreviewItem},
  props: {
    propPaginatedItems: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      paginatedItems: this.propPaginatedItems
    }
  },
  watch: {
    propPaginatedItems: function (newVal) {
      this.paginatedItems = newVal;
    }
  }
}
</script>

<style scoped>

</style>