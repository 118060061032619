<template>
  <layout-admin-add-edit>
    <template #header>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">Recipes</li>
          <li class="breadcrumb-item active" aria-current="page">Add Recipe</li>
        </ol>
      </nav>
    </template>
    <template #body>
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <form class="text-start col-lg-6 col-md-8 col-sm-12 m-auto border rounded p-4" onsubmit="return false">
            <add-recipe-form v-model="recipe" v-on="$listeners" mode="add"></add-recipe-form>
            <div class="mb-3">
              <label class="form-label">Images</label>
              <multi-image-upload data-set="images" :storage-path="storagePath" @handleUpload="handleUpload"/>
            </div>
            <button @click="saveRecipe" class="mb-3 btn btn-primary">SAVE</button>
          </form>
        </div>
      </div>
    </template>
  </layout-admin-add-edit>

</template>
<script>

import MultiImageUpload from "@/components/MultiImageUpload";
import RecipeService from "@/services/recipe/RecipeService";
import {categories, storagePath, videoStoragePath} from "@/services/recipe/RecipeConfig";
import AddRecipeForm from "@/components/recipes/AddRecipeForm";
import LayoutAdminAddEdit from "@/views/layouts/LayoutAdminAddEdit";

export default {
  name: "AddRecipe",
  components: {
    LayoutAdminAddEdit,
    AddRecipeForm,
    MultiImageUpload
  },
  data() {
    return {
      ingredient: '',
      recipe: {
        title: "",
        category: [],
        description: "",
        ingredients: [],
        steps: [],
        notes: "",
        tags: [],
        images: [],
        cookingTime: 0,
        subcategories: [],
        cuisines: [],
        cookingDevices: [],
        creatorEmail: "",
        isPublished: false,
      },
      multiSelectOptions: {
        category: {
          value: [],
          options: this.getRecipeCategoryOptions()
        }
      },
      storagePath: storagePath,
      videoStoragePath: videoStoragePath
    }
  },
  methods: {
    getRecipeCategoryOptions() {
      return [...categories.main, ...categories.general, ...categories.timeBased];
    },
    saveRecipe() {
      const hasEmptyField = (this.recipe.title === "" || this.recipe.category.length === 0
          || this.recipe.description === "" || this.recipe.ingredients === "" || this.recipe.steps === "" );
      if (hasEmptyField) {
        this.$toast.error("Add title, category, description, ingredients and steps for recipe");
      }
      if (!hasEmptyField) {
        this.recipe.images =
            [{
              "imagePath": "recipe/images/default-mpishi-image.jpeg",
              "imageUrl": "https://firebasestorage.googleapis.com/v0/b/mpishi/o/gallery%2Fdefault-mpishi-image.jpeg?alt=media&token=5649ed8f-77d5-44a4-9cf5-976d6c1e6a1c"
            }].concat(this.recipe.images);

        RecipeService.addRecipe(this.recipe);

        this.$router.push({name: "MyRecipes"});
        this.$toast.success("Recipe added successfully!");
      }
    },
    handleInput(input) {
      this.recipe[input.key] = input.values;
    },
    handleUpload(upload) {
      this.recipe[upload.key] = upload.values;
    }
  }
}

</script>

<style scoped>
label {
  font-weight: bold;
}
</style>
