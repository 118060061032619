<template>
  <layout-admin-add-edit :is-loading="loader.show">
    <template #header>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">Podcasts</li>
          <li class="breadcrumb-item active" aria-current="page">Add Podcast</li>
        </ol>
      </nav>
    </template>
    <template #body>
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <div class="text-start col-lg-6 col-md-8 col-sm-12 m-auto border rounded p-4" onsubmit="return false">
            <div class="mb-3">
              <label for="title" class="form-label">Podcast Title</label>
              <input id="title" class="d-flex col-12 form-input p-2" type="text" v-model="podcast.title"
                     @change="toggleDisabledSaveButton(false)" :maxlength="maxInputChars" placeholder="Podcast Title"/>
            </div>
            <div class="mb-3">
              <label for="anchor" class="form-label">Podcast Anchor(s)</label>
              <input id="anchor" class="d-flex col-12 form-input p-2" type="text" v-model="podcast.anchor"
                     @change="toggleDisabledSaveButton(false)" :maxlength="maxInputChars"
                     placeholder="Podcast Anchor(s)"/>
            </div>
            <div>
              <div class="mb-3">
                <HTMLTextEditor v-model="podcast.description" label="Podcast Description"/>
              </div>
            </div>
            <div class="mb-3">
              <multi-select data-set="tags" @handleInput="handleInput" @change="toggleDisabledSaveButton(false)" label="Podcast Tags"/>
            </div>
            <div class="mb-3">
              <label class="form-label">Podcast Cover</label>
              <input type="file" placeholder="Podcast cover" class="form-control" accept="image/*"
                     @change="setSelectedFiles($event.target.files)"/>
            </div>
            <div class="mt-5 mb-3">
              <ButtonMenu
                  :document="podcast"
                  :permissions="permissions"
                  :is-disabled="isDisabled"
                  :is-saved="saved"
                  @cancel="handleCancel"
                  @save="handleSave"
                  @view="handleView"
                  @edit="handleEdit"
                  @publish="handlePublish(true)"
                  @unPublish="handlePublish(false)"
                  @delete="handleDelete"
                  :key="reloadKey"
              />
            </div>
            <div class="writer-generated">
              <span class="chip" v-if="saved">
                last saved {{ getRelativeTime(this.lastSavedAt) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </layout-admin-add-edit>
</template>

<script>
import ButtonMenu from "@/components/ButtonMenu";
import MultiSelect from "@/components/MultiSelect";
// import loading from "vue-full-loading";
import moment from "moment";
import PodcastService from "@/services/podcast/PodcastService";
import HTMLTextEditor from "@/components/HTMLTextEditor.vue";
import LayoutAdminAddEdit from "@/views/layouts/LayoutAdminAddEdit";

export default {
  name: "AddPodcast",
  components: {
    LayoutAdminAddEdit,
    HTMLTextEditor,
    ButtonMenu,
    // loading,
    MultiSelect
  },
  data() {
    return {
      loader: {
        show: false,
        label: "Loading...",
      },
      maxInputChars: 140,
      podcast: {
        title: "",
        anchor: "",
        note: "",
        coverProvided: false,
        coverPath: "",
        cover: "",
        tags: [],
        email: localStorage.getItem("email"),
      },
      isDisabled: true,
      podcastId: null,
      saved: false,
      lastSavedAt: "",
      permissions: {},
      reloadKey: 0
    };
  },
  computed: {
    userPermissions: function () {
      return this.$store.state.userPermissions;
    },
  },
  mounted() {
    this.permissions = this.getUserPermissions(this.userPermissions);
  },
  methods: {
    getUserPermissions(userPermissions) {
      this.$emit("userPermission", userPermissions)
      return {
        CAN_WRITE: true,
        CAN_READ: true,
        CAN_EDIT: true,
        CAN_PUBLISH: true,
        CAN_DELETE: true
      };
    },
    toggleDisabledSaveButton(isDisabled) {
      this.isDisabled = isDisabled;
      this.reloadKey++;
    },
    getRelativeTime(timestamp) {
      return moment(timestamp).fromNow();
    },
    onEditorChange(event) {
      this.content = event.html;
      this.toggleDisabledSaveButton(false);
    },
    setSelectedFiles(files) {
      this.toggleDisabledSaveButton(false);
      const file = files[0];
      if (file) {
        this.podcast.cover = file;
        this.podcast.coverProvided = true;
      }
      if (!file) {
        this.podcast.cover = "";
        this.podcast.coverPath = "";
        this.podcast.coverProvided = false;
      }
    },
    handleInput(input) {
      this.podcast[input.key] = input.values;
    },
    handleCancel() {
      this.$router.push({name: "MyPodcasts"});
    },
    async handleSave() {
      this.loader.show = true;
      this.toggleDisabledSaveButton(true);

      if (this.podcast.coverProvided) {
        const podcastFile = {"path": "podcasts/covers", "file": this.podcast.cover};
        const {path, url} = await PodcastService.uploadFile(podcastFile);

        this.podcast.coverPath = path;
        this.podcast.cover = url;
      }

      if (this.podcastId) {
        this.podcast.updatedAt = new Date();
        await PodcastService.updatePodcast(this.podcastId, this.podcast);
      }

      if (!this.podcastId) {
        const podcast = await PodcastService.addPodcast(this.podcast);
        this.podcastId = this.podcast.id = podcast.id;
      }

      this.saved = true;
      this.lastSavedAt = Date.now();
      this.loader.show = false;
      this.podcast.coverProvided = false;
    },
    handleView() {
      this.$router.push({name: "ViewPodcast", params: {podcastId: this.podcastId}});
    },
    handleEdit() {
      this.$router.push({name: "EditPodcast", params: {podcastId: this.podcastId}});
    },
    async handlePublish(isPublished) {
      this.podcast.published = isPublished;
      await PodcastService.updatePodcast(this.podcastId, this.podcast);
      this.toggleDisabledSaveButton(true);
    },
    async handleDelete() {
      await PodcastService.deletePodcast(this.podcastId);
      this.toggleDisabledSaveButton(true);
      this.handleCancel();
    },
  }
};
</script>

<style scoped></style>
