import Vue from "vue"
import Vuex from "vuex"
import RecipeService from "@/services/recipe/RecipeService";
import SpicesService from "@/services/spices/SpicesService";
import CollectionsService from "@/services/collections/CollectionsService";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loggedIn: function () {
            return false;
        },
        recipes: [],
        galleryItems: [],
        activeMenuView: '',
        isMenuOpen: false,
        isLoggedIn: false,
        defaultRecipe: {
            id: '',
            title: '',
            description: '',
            ingredients: [],
            instructions: [],
            category: [],
            subcategories: [],
            cuisines: [],
            cookingTime: '',
            servings: '',
            difficulty: '',
            creatorEmail: '',
            createdAt: '',
            views: 0,
            images: [],
            video: "",
        }
    },
    mutations: {
        setLoggedIn(state, loggedIn) {
            this.state.loggedIn = loggedIn;
        },
        setRecipes(state, recipes) {
            this.state.recipes = recipes;
        },
        setGalleryItems(state, galleryItems) {
            this.state.galleryItems = galleryItems;
        },
        setRecipe(state, recipe) {
            this.state.recipe = recipe;
        },
        setActiveMenuView(state, viewName) {
            this.state.activeMenuView = viewName
        },
        openMenu(state) {
            state.isMenuOpen = true
        },
        closeMenu(state) {
            state.isMenuOpen = false
        },
        setCategories(state) {
            this.state.categories = state.categories;

        }
    },
    getters: {
        getRecipe(state) {
            return state.recipe;
        },
        getGalleryItems(state) {
            return state.galleryItems;
        },
        getRecipes: (state) => {
            return (Object.values(state.recipes));
        },
        getTopRecipes(state) {
            const allRecipes = (Object.values(state.recipes));
            const recipeLimit = 8;

            return allRecipes.slice().sort((a, b) => {
                return b.views - a.views
            }).slice(0, recipeLimit);
        },
        getLatestRecipes(state) {
            const allRecipes = (Object.values(state.recipes));
            const recipeLimit = 4;

            return allRecipes
                .filter(r => r.createdAt.length)
                .sort(({createdAt: a}, {createdAt: b}) => b - a)
                .slice(0, recipeLimit);
        },
        getQuickestRecipes(state) {
            const allRecipes = (Object.values(state.recipes));
            const recipeLimit = 4;

            return allRecipes
                .filter(r => r.cookingTime.length)
                .sort(({cookingTime: a}, {cookingTime: b}) => a - b)
                .slice(0, recipeLimit);
        },
        getCategories(state) {
            const tempRecipes = state.recipes.filter(r => r.category);
            const categories = [...new Set(
                tempRecipes.reduce(
                    (previous, current) => [...previous, ...current.category],
                    []
                )
            )
            ].sort();
            const editedCategories = categories.map(c => c.trim().toLowerCase());

            return [...new Set(editedCategories)]
        },
        getSubcategories(state) {
            const tempRecipes = state.recipes.filter(r => r.subcategories);
            const subcategories = [...new Set(
                tempRecipes.reduce(
                    (previous, current) => [...previous, ...current.subcategories],
                    []
                )
            )
            ].sort();
            const editedSubcategories = subcategories.map(c => c.trim().toLowerCase());

            return [...new Set(editedSubcategories)]
        },
        getCuisines(state) {
            const tempRecipes = state.recipes.filter(r => r.cuisines);
            const cuisines = [...new Set(
                tempRecipes.reduce(
                    (previous, current) => [...previous, ...current.cuisines],
                    []
                )
            )
            ].sort();
            const editedCuisines = cuisines.map(c => c.trim().toLowerCase());

            return [...new Set(editedCuisines)]
        },
        getRecipeById: (state) => (id) => {
            return (Object.values(state.recipes)).find(recipe => recipe.id === id);
        },
        getOwnRecipes: (state) => (email) => {
            return (Object.values(state.recipes)).filter(recipe => recipe.creatorEmail === email);
        }
    },
    actions: {
        "recipes/getRecipes": async function (context, payload) {
            let recipes = await RecipeService.getRecipes(payload.onlyPublished)
            this.state.recipes = recipes;
            return recipes
        },
        "recipes/getRecipe": async function (context, id) {
            return RecipeService.getRecipe(id)
        },
        "recipes/getCategories": async function () {
            let recipes = await RecipeService.getRecipes();
            let categories = recipes.map(r => r.category);
            let editedCategories = categories.map(c => c.trim().toLowerCase());
            return [...new Set(editedCategories)]
        },
        "recipes/getSubcategories": async function () {
            let recipes = await RecipeService.getRecipes();
            let subcategories = recipes.map(r => r.subcategories);
            let editedSubcategories = subcategories.map(c => c.trim().toLowerCase());
            return [...new Set(editedSubcategories)]
        },
        "recipes/getCuisines": async function () {
            let recipes = await RecipeService.getRecipes();
            let cuisines = recipes.map(r => r.cuisines);
            let editedCuisines = cuisines.map(c => c.trim().toLowerCase());
            return [...new Set(editedCuisines)]
        },
        "spices/getSpices": async function (context, payload) {
            let spices = SpicesService.getSpices(payload.publishedOnly);
            return spices
        },
        "spices/createSpice": async function (context, payload) {
            let spice = await SpicesService.addSpice(payload)
            return spice
        },
        "spices/updateSpice": async function (context, payload) {
            let spice = await SpicesService.updateSpice(payload)
            return spice
        },
        "spices/getSpice": async function (context, payload) {
            let spice = await SpicesService.getSpiceById(payload)
            return spice
        },
        "collection/createCollection": async function (context, payload) {
            let collection = await CollectionsService.addCollection(payload)
            return collection
        },
        "collection/getCollections": async function (context, payload) {
            let collections = await CollectionsService.getCollections(payload)
            return collections
        },
        "collection/getCollection": async function (context, payload) {
            let collection = await CollectionsService.getCollectionById(payload)
            return collection
        },
        "collection/updateCollection": async function (context, payload) {
            let collection = await CollectionsService.updateCollection(payload)
            return collection
        },
        "collection/deleteCollection": async function (context, payload) {
            let collection = await CollectionsService.deleteCollection(payload)
            return collection
        }

    },
    modules: {}
})
