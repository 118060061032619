<template>
  <b-card
    class="base-card "
    text-variant="white"
    no-body
    :style="{'background-image': `url(${cardBackground})`}"
  >
    <template #header>
      <slot name="header"/>
    </template>

    <slot />
  </b-card>
</template>

<script>
import cardBackground from "@/assets/img/wood-bg.jpg";

export default {
  name: 'BaseCard',
  data() {
    return {
      cardBackground: cardBackground,
    };
  },
}
</script>

<style lang="scss" scoped>
$radius: 33px;

.base-card {
  text-align: center;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  //border: 4px solid #ffffff;
  border-radius: $radius !important;
  box-sizing: border-box;
  transition: all .3s ease-in-out;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  &:hover {
    transform: scale(1.015);
    box-shadow: 0 20px 35px 0 rgba(0,0,0,.08);
  }
}

.card-header {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #ffffff;
  border: 0;
  background: rgba(0,0,0,0.25);

  h4 {
    color: #fff3d3;
    display: block;
    font-family: "Caveat", cursive;
    font-size: 1.6rem;
    font-weight: 100;
    background: rgba(0,0,0,0.2);
    border-radius: 50px;
    padding: 10px 21px;
    text-shadow: none;
  }

  &:first-child {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }
}

.card-body {
  color: #dbc8b1;
}

.card-subtitle {
  color: #dbc8b1 !important;
}

.card-img {
  img {
    width: 100%;
  }
}

.card-text {
  background: rgb(181, 128, 86, 0.15);
  padding: 1rem;
  border-radius: $radius;
  color: #f2f2f2;
}

.card-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom-left-radius: $radius !important;
  border-bottom-right-radius: $radius !important;
  margin: auto 0 0;

  .btn {
    display: block;
    margin: 0 auto;

    + .btn {
      margin-top: 1rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .card-header {
    h4 {
      font-size: 1.75rem;
    }
  }

}
</style>