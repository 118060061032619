<template>
  <div class="col-12 d-flex flex-column align-items-center">
    <nav aria-label="breadcrumb" class="col-12">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Gallery</li>
        <li class="breadcrumb-item active" aria-current="page">Add Image(s)</li>
      </ol>
    </nav>
    <div class="text-start m-auto border rounded p-4 col-8" onsubmit="return false">
      <div class="mb-3">
        <label for="description" class="form-label">Image(s)</label>
        <multi-image-upload id="description" data-set="images" :storage-path="storagePath"
                            @handleUpload="handleUpload"/>
      </div>
      <div class="mb-3">
        <base-input placeholder="Title" type="text" label="Title" v-model="title"/>
      </div>
      <div class="mb-3">
        <base-text-area label="Description" v-model="description"/>
      </div>
      <div class="mb-3">
        <multi-select data-set="tags" @handleInput="handleInput" label="Tags"/>
      </div>
      <button class="mb-3 btn btn-primary" @click="handleSave" :disabled="!saveEnabled">
        <i class="bi bi-save"></i> Save <strong>({{ this.images.length }})</strong>
      </button>
    </div>
  </div>

</template>
<script>
import GalleryService from "@/services/gallery/GalleryService";
import MultiSelect from "@/components/MultiSelect";
import MultiImageUpload from "@/components/MultiImageUpload";
import {getFromSessionStorage} from "@/assets/js/common";
import BaseTextArea from "@/components/bases/BaseTextArea.vue";
import BaseInput from "@/components/bases/BaseInput.vue";
import RecipeService from "@/services/recipe/RecipeService";

export default {
  name: "AddGallery",
  components: {
    BaseInput,
    BaseTextArea,
    MultiSelect,
    MultiImageUpload
  },
  data() {
    return {
      loader: {
        show: true,
        label: "Loading..."
      },
      creatorEmail: this.getCurrentUser(),
      storagePath: "gallery",
      images: [],
      tags: [],
      description: "",
      title: "",
      recipes: [],
      associatedRecipes: [],
    }
  },
  mounted() {
    this.setRecipes();
  },
  computed: {
    saveEnabled: function () {
      return (this.images.length > 0);
    }
  },
  methods: {
    async setRecipes() {
      let recipes = await RecipeService.getRecipes()
      this.recipes = recipes.map(recipe => {
        return {
          value: recipe.id,
          label: recipe.title
        }
      })
    },
    getCurrentUser() {
      return getFromSessionStorage("user_details").email;
    },
    toggleLoader(status) {
      this.loader.show = status;
    },
    async handleSave() {
      const noImagesUploaded = (this.images.length === 0);

      if (noImagesUploaded) {
        return this.$toast.error('No image(s) uploaded!');
      }

      if (!noImagesUploaded) {
        this.toggleLoader(true);

        this.images.map(async (image) => {
          const item = {
            title: this.title || image.imagePath.split('\\').pop().split('/').pop(),
            ref: image.imagePath,
            url: image.imageUrl,
            tags: this.tags,
            description: this.description,
            associatedRecipes: [],
            creatorEmail: this.creatorEmail
          }

          await GalleryService.addItem(item);
          await this.$toast.success(`Image: ${item.title} has been saved`);
        });
        this.toggleLoader(false);
        await this.$router.push({name: "Gallery"});
      }
    },
    handleInput(input) {
      this[input.key] = input.values;
    },
    handleUpload(upload) {
      this[upload.key] = upload.values;
    }
  }
}
</script>
<style scoped>
</style>
