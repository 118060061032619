const config = {
  accessSubscriptions: {
    story: ["Bronze Membership", "Silver Membership", "Gold Membership"],
    resource: ["Bronze Membership", "Silver Membership", "Gold Membership"],
    book: ["Silver Membership", "Gold Membership"],
    journal: ["Gold Membership"],
    podcast: ["Gold Membership"]
  },
};

module.exports = { ...config };
