<template>
  <div class="video">
    <vue-vimeo-player
      ref="player"
      :video-id="videoId"
      @ready="onReady"
      :player-width="size.width"
      :player-height="size.height"
      :video-url="propVideoUrl"
    />
  </div>
</template>

<script>

import {vueVimeoPlayer} from 'vue-vimeo-player'

export default {
  name: "VimeoPlayer",
  components: {
    vueVimeoPlayer
  },
  props: {
    propVideoUrl: {
      type: String,
      default: "v"
    },
  },
  data() {
    return {
      videoUrl: "",
      videoId: "",
    }
  },
  computed: {
    size() {
      if (this.$mq === 'sm') {
        return {
          width: 300,
          height: 150,
        }
      }
      if (this.$mq === 'md') {
        return {
          width: 640,
          height: 320,
        }
      }
      return {
        width: 800,
        height: 400,
      }
    }
  },
  watch: {
    propVideoUrl: function (newVal) {
      this.videoUrl = newVal;
      this.videoId = newVal.split("/").pop();
    },
  },
}

</script>

<style lang="scss" scoped>

.video {
  position: relative;

  ::v-deep {
    iframe {
      width: 100%;
      height: 100%;

      @media screen and (min-width: 768px) {
        height: 400px;
      }
    }
  }

}

</style>